import httpV2 from '@/utils/httpV2';

const state = {

};
const getters = {};

const mutations = {};

const actions = {
    //分页获取自定义列表
    getCustomParameterPage({ state, commit }, params) {
        let promise = httpV2.get2("/b2c/v1.0/custom/parameter/page/search", params, this);
        return promise;
    },
     // 删除
    deleteCustomParameter({ state, commit }, params) {
        let promise = httpV2.deleteList("/b2c/v1.0/custom/parameter" + `/${params}` , params, this);
        return promise;
    },

    saveCustomParameter({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/custom/parameter", params, this);
        return promise;
    },

    updateCustomParameter({ state, commit }, params) {
        let promise = httpV2.put("/b2c/v1.0/custom/parameter" + `/${params.selectCustomParameterId}`, params, this);
        return promise;
    },

    bindCustomParameter({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/custom/parameter" + `/${params.customParameterId}` + "/bind", params.goodsIdList, this);
        return promise;
    },

    unBindCustomParameter({ state, commit }, params) {
        let promise = httpV2.post("/b2c/v1.0/custom/parameter" + `/${params.customParameterId}` + "/unBind", params.goodsIdList, this);
        return promise;
    },

    // 导入文件
    importCustomParameter({ state, commit }, params) {
        return httpV2.post('/b2c/v1.0/custom/parameter/import', params, this);
    },

    downloadTemplate({ state, commit }, params) {
        return httpV2.getFile('/b2c/v1.0/custom/parameter/template', params, this);
    },


};

export default {
    getters,
    state,
    mutations,
    actions
};
