import enLocale from 'element-ui/lib/locale/lang/en';
const en = {
    ...enLocale,
    common: {
        datastatistics: 'Statistics',
        Transactionstatistics: 'Transaction statistics',
        LabelOptions: 'Label Options',
        Orderdatastatistics: 'Order statistics',
        Hourlystatistics: 'by hour',
        Countbyday: 'by day',
        Spain: 'Spain',
        Italy: 'Italy',
        Germany: 'Germany',
        France: 'France',
        poland: 'poland',
        Netherlands: 'Netherlands',
        Britain: 'Britain',
        UnitedStates: 'the USA',
        Portugal: 'Portugal',
        SalesCountry: 'Country of sale',
        query: 'Enquiry',
        Placinganorder: 'order quantity',
        Orderamount: 'Order amount',
        Numberofusersplacingorders: 'Number of users placing orders',
        Ordertime: 'Time of order',
        time: 'Time',
        usermanagement: 'User Management',
        UserID_loginemail_registrationinviterID_phonenumber: 'User ID / login email / registered invitee ID / mobile phone number',
        country: 'Country',
        Whethertosubscribe: 'Subscribe or not',
        Loginstatus: 'Login status',
        normal: 'Normal',
        Disabled: 'Disable',
        Createdon: 'creation date',
        Lastlogintime: 'Last login time',
        Reset: 'Reset',
        export: 'Export',
        UserID: 'User ID',
        Registeredcountry: 'Country of registration',
        mailbox: 'Email',
        Mobilephonenumber: 'Mobile phone number',
        Patrioticcoin: 'Aigo Coins',
        RegistrationInviterID: 'Registered Inviter ID',
        brand: 'Brand',
        Creationtime: 'Creation time',
        operation: 'Operation',
        check: 'View',
        orderform: 'Orders',
        consultingservice: 'Enquiry',
        address: 'Address',
        LoginLog: 'Login Log',
        Rechargepatrioticcoins: 'Recharge Aigo Coins',
        Returnsandexchanges: 'Returns and Exchanges',
        evaluate: 'Rate',
        Shippingaddress: 'Receive Address',
        shoppingcart: 'Cart',
        collection: 'collection',
        invoice: 'Invoice',
        nickname: 'Nickname',
        nothing: 'None',
        close: 'Close',
        source: 'Source',
        Subscriptiontime: 'Subscription time',
        Thirdpartylogin: 'Third party login',
        Bound: 'Bound',
        Unbound: 'Unbound',
        Invitationcode: 'Invitation Code',
        Inviteduser: 'Invited users',
        Invitetoreceivepatrioticcoins: 'Invite to get Aigo coins',
        Isthefirstorderfreeofshipping: 'Free shipping or not on your first order',
        shopping: 'Shopping',
        Collectproducts: 'Favorite Items',
        ShoppingCartItems: 'Shopping Cart Items',
        PaymentOrder: 'Paid Orders',
        Ordercumulativeamount: 'Total order amount',
        Lastordertime: 'Last Order time',
        Returnandexchangeorders: 'Return and exchange orders',
        Consultationfrequency: 'enquiry frequency',
        Numberofevaluations: 'the number of reviews',
        Numberofapprovedevaluations: 'the number of approved reviews',
        ShowCountry: 'Show country',
        name1: 'Name',
        TaxID: 'Tax code',
        province: 'Province',
        city: 'City',
        Detailedaddress: 'detailed address',
        Housenumber: 'house number',
        AddressAdditionalContent: 'Address additional content',
        RecipientCompany: 'Recipient Company',
        Defaultshippingaddress: 'Default shipping address',
        Defaultinvoiceaddress: 'Default Invoice address',
        ProductID1: 'Product ID',
        Productname1: 'Product Name',
        CollectionPrice: 'Collection Price',
        Currentprice: 'Current price',
        Collectiontime1: 'Collection time',
        Additionalpurchaseprice: 'the price of the items adding to the wishlist',
        Additionalpurchasetime: 'the time of  items adding to the wishlilst',
        Listingstatus: 'shelf status',
        Listed: 'put on shelf',
        Notlisted: 'not on the shelf',
        Numberofpatrioticcoins: 'Number of Aigo coins',
        Enteraquantitybetween0and1000: 'Enter a quantity between 0 and 1000',
        Remarks: 'Remarks',
        cancel: 'Cancel',
        UserDetails: 'User details',
        Areyousuretoexportthefile: 'Do you want to confirm the export of the file, do you want to continue?',
        prompt: 'Prompt',
        Exportsuccessful: 'Export successful',
        today: 'today',
        Lastweek: 'Latest week',
        Thismonth: 'This month',
        Lastmonth1: 'Last month',
        thisyear: 'This year',
        Inputcannotbeempty: 'Input cannot be empty',
        Pleaseenterapositiveinteger: 'Please enter a positive integer',
        Pleaseenterthequantityofpatrioticcoins: 'Please enter the number of Aigo coins',
        Pleaseenteracomment: 'Please enter a remark',
        Pleaseselectacountry: 'Please select a country',
        Successfullysubmitted: 'Submitted successfully',
        Nopatrioticcoinrulesconfigured: 'No Aigo coin rules configured',
        Rechargefailed: 'fail to recharge',
        Savefailed: 'save failed',
        ordermanagement: 'Order Management',
        OrderList: 'Order List',
        Ordernumber: 'Order number',
        Paymentcode: 'Payment Code',
        barcode: 'Barcode',
        Pleaseselectasource: 'Please select the source',
        OrderStatus: 'Order Status',
        Unpaid: 'unpaid',
        Paid: 'paid',
        Shippable: 'Available for delivery',
        Shipped: 'Shipped',
        complete: 'Complete',
        Pleaseselectpaymentmethod: 'Please select the way of payment',
        Shipmentstatus: 'Shipping Status',
        Notshipped: 'Not shipped',
        Partialshipment: 'Partially shipped',
        Returned: 'Returned',
        Remittanceconfirmation: 'Remittance confirmed',
        Notremitted: 'No remittance',
        Unconfirmed: 'unconfirmed',
        adopt: 'Pass',
        Notpassed: 'not pass',
        Whethertomanuallyship: 'Manual delivery or not',
        yes: 'Yes',
        no: 'No',
        Originalordertype: 'Original order type',
        Presaleorder: 'Pre-order',
        Officialorder: 'Official order',
        Preorderstatus: 'pre- order status',
        Deposittobepaid: 'Deposit to be paid',
        Depositpaid: 'Deposit paid',
        Finalpaymenttobemade: 'balance to be paid',
        Finalpaymentpaid: 'balance paid',
        Presalepaymentmethod: 'the way of payment for pre-sale',
        Finaldeposit: 'Final payment of deposit',
        Fullpayment: 'Full payment',
        Whetherthepresaleorderisconvertedtoformal: 'Pre-order to official or not',
        Combinedorders: 'Combined order',
        to: 'To',
        Startdate: 'Starting Date',
        Enddate: 'Ending date',
        Paymenttime: 'payment time',
        Pleaseenterthesource: 'Please enter the source',
        platform: 'Platform',
        Positiveconversionsingle: 'Transfer to official order',
        Numberofproductmodels: 'Number of items',
        activity: 'Activity',
        money: 'Amount',
        Paymentmethod: 'the way of payment',
        Deliverytime: 'Shipping time',
        Whethertotransfertoformal: 'transfer to official or not',
        Tobereplied_consulted: 'to be replied/ consulted',
        detailed: 'Details',
        Manualshipping: 'Manual delivery',
        Manuallogisticsshipment: 'Manual logistics delivery',
        logisticscompany: 'Logistics company',
        Pleaseselectalogisticscompany: 'Please select a logistics company',
        Logisticstrackingnumber: 'Logistics tracking number',
        SelectDateTime: 'Select date and time',
        Shippingremarks: 'Shipping Remarks',
        Pleasenote: 'Please note',
        confirm1: 'Confirm',
        state: 'Status',
        Pleaseselect: 'Please select',
        Pleaseselectastatus: 'Please select status',
        Homepagerecommendation: 'Home page recommendation',
        Pleaseenterthelogisticstrackingnumber: 'Please enter the tracking number',
        Pleaseselecttheshippingtime: 'Please select a shipping time',
        edit: 'Edit',
        Successfullyshippedmanually: 'Manual delivery successfully',
        Remittanceconfirmationsuccessful: 'Remittance confirmation successful',
        confirmdeletion: 'Confirm to delete',
        Successfullydeleted: 'Delete successfully',
        Pleaseselectapresaleorder: 'Please select pre-sale order',
        Confirmtoconverttheorderintoaformalorder: 'Confirmation of order conversion to official order',
        success: 'Success',
        OrderData: 'Order data',
        Areyousuretoexportthefileandcontinue: 'confirm to export file or not, continue or not',
        Basicinformationoftheorder: 'Basic order information',
        Useremail: 'User email',
        Documentremarks: 'Notes on the order',
        combination: 'Combination',
        PostalCode: 'Postcode',
        RecipientCompanyAddressAdditionalContent: 'Recipient company, address additional content',
        Invoiceaddress: 'Invoice address',
        InvoiceStatus: 'Invoice Status',
        Paymentinformation: 'Payment Information',
        Paymentstatus: 'Payment Status',
        coupon: 'Coupon',
        freight: 'freight',
        CouponName: 'Coupon Name',
        Couponcode: 'Coupon Code',
        Paymentamount: 'Payment Amount',
        Trackingnumber: 'Tracking Order Number',
        Manualshippingoperator: 'Manual Shipping Operator',
        Manualshippingoperationtime: 'Manual Delivery Operation Time',
        Bankremittance: 'Bank remmittance',
        Remittancestatus: 'Remittance Status',
        Remittanceconfirmationoperator: 'Remittance Confirmation Operator',
        Remittanceconfirmationoperationtime: 'Remittance confirmation time',
        Preorderinformation: 'Pre-sale order information',
        Converttoformaldocument: 'transfer to official order or not',
        Positiveconversionsinglemode: 'the way of transfering to the official order',
        automatic: 'automatic',
        Manual: 'mannual',
        Positiveconversionsingletime: 'the time of transfering to the official order',
        category1: 'category',
        Subordernumber: 'sub-order number',
        Paymentdeadline: 'payment deadline',
        Addremarks: 'add remarks',
        ProductInformation: 'product information',
        Serialnumber: 'serial number',
        name: 'name',
        VariantInformation: 'Variant information',
        Pricing_unitprice: 'Pricing (unit price)',
        Sellingprice_unitprice: 'Selling price (unit price)',
        quantity: 'Quantity',
        Totalprice: 'Total price',
        MerchandiseSubtotal: 'Subtotal goods',
        total: 'Total',
        CancelOrder: 'cancel the order',
        Areyousuretocancelthisorder: 'Are you sure to cancel the order?',
        reason: 'Reason',
        Pleaseselectabrand: 'Please select a brand',
        Pleaseselectacategory: 'Please select a category',
        Successfullyadded1: 'Add successfully',
        Addfailed: 'Fail to add',
        Whethertosubmitdata: 'Submit data or not',
        Canceledsuccessfully: 'Cancel successfully',
        Onlypendingorderscanbeshipped: 'Only pending orders can be shipped',
        Existinglogisticstrackingnumber: 'The order has a logistics tracking number and cannot be submitted',
        Thereareonlytworemittancestatuses: 'The status of the remittance can only be approved or not approved',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Only remitted orders can be confirmed',
        Orderdoesnotexist: 'The order does not exist',
        Thisordercannotbecancelled: 'The order cannot be cancelled',
        Norulesconfigured: 'The current tenant has no Aigo coins rules configured',
        Parametererror: 'parameters error',
        Countrycodecannotbeempty1: 'Country code cannot be empty',
        Thecurrentcountrypricingisnotconfigured: 'Current country pricing is not configured',
        Thepriceconfigurationdoesnotexist: 'Price configuration does not exist, please configure price',
        Reasonforcancellationoforderisrequired: 'Reason for order cancellation required',
        Exportorderinformation: 'export order information',
        OrderIDmustbepassed: 'Order id must be passed',
        Youcanonlybatchprocessupto100itemsatatime: 'Batch processing of up to 100 orders at a time',
        Notapresaleorder: 'Order number 101 is a non-pre-order, please deselect it first',
        Transferredtoofficial: 'Order number 101 has been transferred to an official order, please deselect first',
        Ordernotpaid: 'Order number 101 is not a paid order, please cancel first',
        Nonpresaleorderscannotbeoperated: 'Non-pre-sale orders are not available',
        Transferredtoformalformandcannotbeoperated: 'Transferred orders are not available',
        Nonpaidorderscannotbeoperated: 'Not available for non-paid orders',
        Orderremarksarerequired: 'Order notes are required',
        Upto500wordscanbeentered: 'Maximum 500 characters',
        common: 'in total',
        strip: 'records',
        goto: 'Go to',
        page: 'Page',
        Returnandexchangeapplication: 'Return request',
        type: 'Type',
        Return: 'Return',
        Exchange: 'Exchange',
        Auditstatus: 'Review Status',
        Approved: 'Approved',
        Auditfailed: 'Failed',
        Inprogress: 'be in progress',
        Auditended: 'End of audit',
        Processingstatus: 'Processing Status',
        Completed: 'Completed',
        Applicationtime: 'Application Time',
        Aftersalestype: 'After Sales Type',
        ApplicationDescription: 'Application description',
        Numberofexplanatoryimages: 'Description of the number of pictures',
        Illustrativeimage: 'Description of pictures',
        Returnlogisticstrackingcode: 'Return logistics tracking code',
        CanIcontinuetoapply: 'Whether to continue to apply',
        Auditing: 'Audit',
        details: 'Details',
        handle: 'Process',
        Add1: 'Add',
        ApplicationDetails: 'Request Details',
        Exchangeitem: 'Exchange goods',
        Returnedgoods: 'Return goods',
        Audittime: 'Review Time',
        Reviewedby: 'Reviewed by',
        result: 'Result',
        Failed: 'Not approved',
        Thereplycontentwillbedisplayedtotheuser: 'The response will be displayed to the users',
        Submit: 'Submit',
        Replycontent: 'the content of reply',
        Operator: 'Operators',
        Pleaseenterthereason: 'Please enter a reason',
        Pleasechoosewhethertocontinueapplying: 'Please select if you can continue to apply',
        Pleaseselectaresult: 'Please select the result',
        Successfullymodified: 'Modification successful',
        Verificationfailed: 'Verification failed',
        Datadoesnotexist: 'Data does not exist',
        OrderNumber_UserID: 'Order number/user ID',
        ReplyStatus: 'Reply Status',
        Replied: 'Replied',
        Noresponse: 'No reply',
        MyOrder: 'My order',
        refund: 'Refund',
        Canwedeliverontheagreeddate: 'Can you deliver on the agreed date?',
        Modifyshippingaddress: 'Change shipping address',
        Otherissuesrelatedtotransportation: 'Other questions about shipping',
        Receivedincorrectproduct: 'Receive the wrong product',
        Receiveddamagedproductsandproductswithqualityissues: 'Receive damaged products and products with quality problems',
        RegulationsonReturnsandRefunds: 'Regulations on returns and refunds',
        Applyforinvoice: 'Request an invoice',
        productdetails1: 'Product details',
        Thankyouletter: 'letter of thanks',
        Otherissues: 'Other questions',
        Respondent: 'Reply by',
        Consultationdate: 'Date of Enquiry',
        content: 'Content',
        explain: 'Description',
        Consultationtime: 'Time of consultation',
        Replytime: 'Reply time',
        reply: 'Reply',
        OrderInformation: 'Order information',
        Consultationcontent: 'Enquiry content',
        Consultationinstructions: 'Enquiry Instructions',
        EnterReply: 'Enter a reply',
        Consultationrecords: 'Enquiry history',
        Replycontentisrequired: 'Reply content is required',
        Replysuccessful: 'Reply successfully',
        Successfullyedited: 'Edit successfully',
        Ordernumber_UserID_Taxnumber: 'Order number/user ID/tax code',
        Unaudited: 'Unaudited',
        Passed: 'Approved',
        Notuploaded: 'Not uploaded',
        Uploaded: 'Uploaded',
        AuditDescription: 'Review Instructions',
        download: 'Download',
        Applying: 'Application in progress',
        Enterthereason: 'Enter the reason, you must fill in the reason if the application is not approved',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Reasons for not passing will be shown to the user',
        Pleaseenterareply: 'Please enter a reply',
        Auditsuccessful: 'Successful review',
        ProductManagement: 'Product Management',
        Categorymanagement: 'Category Management',
        Categoryname1: 'Category Name',
        Isthehomepagedisplayed: 'Home page display or not',
        Enableornot: 'Enable or not',
        search: 'Search',
        Directoryhierarchy: 'Catalogue Level',
        Category: 'Category',
        sort: 'Sort by',
        picture: 'Picture',
        Updatetime: 'Update time',
        Multilingualconfiguration: 'Multi-language configuration',
        Relatedimportantparameters: 'Related important parameters',
        Associatesearchparameters: 'Associated search parameters',
        delete: 'Delete',
        SelectAll: 'Select all',
        Imagesize: 'Image size',
        icon: 'Icons',
        intotal25: '25 items in total',
        Gotopage1: 'Go to page 1',
        Chinese: 'Chinese',
        English: 'English',
        German: 'German',
        Polishlanguage: 'Polish',
        Italian: 'Italian',
        French: 'French',
        Dutchlanguage: 'Dutch',
        Spanish: 'Spanish',
        Pleaseenteraname: 'Please enter a name',
        Areyousureyouwanttodeleteit: 'Do you want to delete?',
        Categoryassociation: 'Category association',
        PrimaryKeyID: 'Primary Key ID',
        CatalogID: 'Catalogue ID',
        Addtime: 'Add time',
        Relatedcategories: 'Associated category',
        Selectmaincategory: 'Select main category',
        addassociations: 'Add association',
        Maincategory: 'Main Category',
        ProductCatalogID: 'Product Catalog ID',
        RelatedCategoryName: 'Associated Category Name',
        AddAssociatedCategory: 'Add a associated category',
        Pleaseselectacategoryfirst1: 'Please select a category first',
        Productvariants: 'Product Variant',
        ID: 'ID',
        Add: 'Add',
        Productquantity: 'Number of products',
        Selectvariant: 'Select a variant',
        Selectparameters: 'Select parameters',
        commodity: 'Product',
        Selectproduct: 'Select product',
        Colortemperature: 'Colour temperature',
        frequency: 'Frequency',
        power: 'Power',
        style: 'Style',
        MoveDown: 'Shift down',
        MoveUp: 'Move up',
        Selectvariantparameters: 'Select variant parameters',
        SKU_EAN_Encoding: 'SKU/EAN/code',
        Pleaseselectparameters: 'Please select parameters',
        Countrieslisted: 'countries where products are put on shelf',
        ProductID: 'Product ID',
        Productimage1: 'Product image',
        ProductName: 'Product name',
        VariantManagement: 'Variant Management',
        Class: 'Category',
        Selected: 'Selected',
        Thereiscurrentlynodataavailable: 'No data available',
        Variantparameters: 'Variant Parameters',
        VariantName: 'Variant name',
        CustomName: 'Custom name',
        Batchlisting: 'Batch putting on shelf',
        Pleaseselectaproduct: 'Please select products',
        Batchdelisting: 'Bulk delisting',
        Batchsearch: 'Batch Search',
        Enterproductbarcodeandsubmitforaddition: 'Enter product barcode to submit additions, multiple barcodes should be entered in a new line',
        Enterproductbarcode: 'Enter product barcodes, multiple barcodes should be input in a newline  up to 500',
        Pleaseenterbarcode: 'Please enter barcode',
        Editlistingcountry: 'edit countries where products are put on shelf',
        Pleasecompletetheproductinformationbeforelistingit: 'Please complete the product information before putting on shelf',
        ProductTitle: 'Product Title',
        ProductLargePicture: 'Product image',
        commodityprice: 'Product Price',
        Confirmtheexecutionofbatchdelisting: 'Confirm to perform bulk delisting',
        EditCategory: 'Edit category',
        SynchronizeSKU: "Sync SKU's",
        ConfirmsynchronizationofSKU: 'Confirm SKU sync',
        SynchronizationrequiresdockingwithEBP: 'As synchronisation needs to be done with EBP, to avoid errors, batch synchronisation can be done once every 30 minutes. Please be patient as synchronisation will be performed asynchronously after confirmation and SKUs will be displayed as soon as they are available.',
        Synccompleted: 'Synchronisation complete',
        Setlabel: 'Set up tags',
        label: 'Tags',
        SynchronizeEBPproductinformation: 'Synchronising EBP product information',
        Afterturningonsynchronization: 'When synchronisation is enabled, the following information for EBP products is synchronised by product SKU',
        Synchronizeproducttitlesbylanguage: 'Product title: synchronise the title of the corresponding language by language',
        Synchronizeproductimagesbylanguage: 'Product image: synchronise the image in the corresponding language by language, read the EBP image sorting, the first EBP image is automatically set as the main image',
        Synchronizeproductimagesandtextbylanguage: 'Image details: synchronise by language for the corresponding language',
        Productkeywords: 'Product keywords',
        Automaticallysynchronizeornot: 'Whether to synchronise automatically',
        Doyouwanttoforcesynchronization: 'Forced sync or not',
        tenant: 'Tenants',
        Noinventorydisplay: 'No inventory display',
        Synchronizetoothertenants: 'Sync to other tenants',
        UpdateRecords: 'Update records',
        Importtime: 'Import time',
        number: 'Number',
        file: 'File',
        UpdateResults: 'Update Result',
        Downloadfiles: 'Download file',
        Isthereafixedprice: 'Is there a fixed price',
        Whethertopromoteornot: 'On sale or not',
        Inpromotion: 'On Sale',
        whole: 'All',
        Fixedprice1: 'Fixed price',
        Lowestprice: 'Lowest price',
        fixedprice: 'Fixed Price',
        Promotionprice_unitprice: 'Promotional price (unit price)',
        price1: 'Selling Price',
        inventory: 'inventory',
        Inventorybottomline: 'inventory bottom line',
        AutomaticallysynchronizeEBP: 'Whether to automatically synchronise EBP',
        ForcesynchronizationofEBP: 'Whether to force EBP synchronisation',
        Presaleornot: 'Pre-sale or not',
        Evaluation_Shown_total: 'Reviews (displayed/total)',
        coding: 'Code',
        ProductDetails: 'Product Details',
        EditProduct: 'Edit product',
        Basicinformation: 'Basic Information',
        price: 'Price',
        Pleaseentertheproducttitle: 'Please enter product title',
        Sizeinformation: 'Size Information',
        weight: 'Weight',
        volume: 'Volume',
        Salesmultiples: 'Sales multiplier',
        Numberofitems: 'Number of items',
        EditFixedPrice: 'Edit Fixed Price',
        Largeimage: 'Large image',
        Uploadimages: 'Upload a picture',
        UploadVideo: 'Upload a video',
        Batchdisplay: 'Batch display',
        BatchCloseDisplay: 'Batch close display',
        Batchdeletion: 'Batch delete',
        Synchronizetootherlanguages: 'Sync to other languages',
        Selectfrompicturelibrary: 'Select from the image gallery',
        Pleaseenterthemaintext: 'Please enter body text',
        Hideemptyparameters: 'Hide empty parameters',
        Detailedcontentofgraphicsandtext: 'Please enter the graphic details in English first',
        parameter: 'Parameter',
        Parametervalue: 'Parameter value',
        ParameterProperties: 'Parameter properties',
        Pleaseenteraparametername: 'Please enter the parameter name',
        Pagekeywords: 'Page keyword',
        PageDescription: 'Page Description',
        Pleaseentercontent: 'Please enter content',
        PictureLanguage: 'Image language',
        Pictures_Videos: 'Image/Video',
        ImageID: 'Image ID',
        language: 'Language',
        size: 'Size',
        Selectapictureorvideo: 'Please select the image or video in the gallery first!',
        Selectionsuccessful: 'Select successfully',
        Isitthemainimage: 'Main image or not',
        Displayornot: 'Show or not',
        Whethertodisplayall: 'Show all or not',
        Areallclosed: 'Whether to close all',
        Confirmdeletionoftheselectedpicture_video: 'Confirm to delete selected image/video',
        ProductReview: 'Product Comments',
        PromotionName_ProductID_Barcode: 'Promotion Name/Product ID/Barcode',
        PromotionCountry: 'Promotion Country',
        Activitystatus: 'Event Status',
        begininaminute: 'Starting soon',
        Ended: 'Ended',
        Isitaflashkill: 'Is it sec-killing',
        PromotionName: 'Promotion Name',
        CommentID: 'Comment ID',
        RatingStarRating: 'Rating Star',
        star0: 'star',
        star1: '1 star',
        stars2: '2 stars',
        stars3: '3 Stars',
        stars4: '4 Stars',
        stars5: 'Promotionprice',
        Commentstartdate: 'starting date of the comments',
        CommentEndDate: 'ending date of the comments',
        Batchreview: 'Batch Review',
        Productimage: 'Product Images',
        Evaluationcontent: 'comment content',
        video: 'Video',
        ReviewerId: 'Reviewer Id',
        user: 'User',
        Pendingreview: 'To be reviewed',
        Evaluationtime: 'comment time',
        ReviewerName: "Reviewer's name",
        Numberofselectedcomments: 'the number of selected reviews',
        Picturepreview: 'Image Preview',
        VideoPreview: 'Video Preview',
        Lastmonth: 'Last month',
        LastThreeMonths: 'Last three months',
        Areyousuretodelete: 'confirm to delete or not',
        Pleaseselectacomment: 'Please select a review',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'aigostar brand network',
        marketingmanagement: 'Marketing Management',
        Productrecommendations: 'Product recommendation',
        Recommendedlocation: 'Recommend Location',
        Categoryrecommendation: 'Category recommendation',
        Recommendationmodule: 'Recommend Module',
        NewProductZone: 'New Arrivals',
        HotSellingZone: 'Hot Products',
        Recommendedforyou1: 'Recommended for you',
        Numberofproducts: 'Number of products',
        Addproduct: 'Add product',
        DirectoryName: 'Catalogue name',
        title: 'Title',
        ContentTitle: 'Content Title',
        EnablePlatform: 'Enable Platform',
        DisplayPlatform: 'display platform',
        catalogue: 'Catalogue',
        JumpAddress: 'Jump to address',
        PageTitle: 'Page Title',
        CategoryName: 'Category name',
        Cancelleddeletion: 'cancelled deletion',
        Pleaseselectacategoryfirst: 'Please select a category first',
        TheEnglishcategorynamecannotbeempty: 'English category name cannot be empty',
        Successfullysaved: 'Saved successfully',
        DeleteError: 'Deletion error',
        Apieceofcontentalreadyexists: 'A content already exists',
        Pleaseselecttherecommendedlocation: 'Please select a recommended location',
        Pleaseselecttherecommendedmodule: 'Please select a recommendation module',
        remove: 'Remove',
        Successfullyadded2: 'Added successfully',
        Areyousuretoremovethisproduct: 'Are you sure to remove this product?',
        Successfullyremoved: 'Removed successfully',
        Dataalreadyexists: 'Data already exists',
        PortfolioManagement: 'Portfolio Management',
        Combinationname: 'Combination name',
        Remainingquantity_combinedquantity: 'remaining quantity /remaining combined quantity',
        starttime: 'Starting time',
        Endtime: 'Ending time',
        Creator: 'Created by',
        NationalSDPDictionary: 'national  sdp dictionary',
        Combinationtime: 'Combination time',
        Combinationquantity: 'combinations quantity',
        CanIusecoupons: 'coupons can be used or not',
        Mainimage: 'Main image',
        Setcombinationdiscount: 'Set combination discount',
        Combinationprice: 'Combination price',
        Combinedtotalprice: 'Total price of the combination',
        Isitontheshelf: 'Is it on the shelf',
        Selectupto5: 'Select up to 5',
        Promotionprice1: 'Promotional price',
        Pleaseenterthecombinationquantity: 'Please enter the number of combinations',
        Pleaseenteracombinationname: 'Please enter a combination name',
        Upto100wordscanbeentered: 'Enter up to 100 characters',
        Pleaseselectadaterange: 'Please select a date range',
        Pleaseselectastartdate: 'Please select a starting date',
        Pleaseselecttheenddate: 'Please select an ending date',
        Pleasechoosewhethertousecoupons: 'Please select whether to use the coupon',
        Pleaseselect25items: 'Please select 2-5 items',
        Pleaseselectaproductthathasbeenlisted: 'Please select an item that has been put on shelves',
        Inventoryofselecteditems: 'The stock/sales multiplier of the selected item cannot be less than the activity  quantity',
        Combinationpricecannotbeempty: 'The combination price cannot be empty',
        Caninput0andpositiveintegers: '0 and positive integers can be entered, symbols can only be entered as % and . ,Up to two decimal places',
        Presaleproductscannotbeadded: 'This is a pre-sale item and cannot be added as a combo',
        Pleaseselectaproductfirst: 'Please select an item first',
        Freeshippingonfirstorder: 'Free shipping on your first order',
        Firstfreeshippingitem: 'free shipping goods on your first order',
        SelectedProduct: 'Selected product',
        Between1and50charactersinlength: 'Length between 1 and 50 characters',
        Thisoperationwillremovethefile: 'This operation will remove the file, do you want to continue?',
        Brandandcountrycannotbeempty: 'Brand and country cannot be empty',
        Pleaseselectatime: 'Please select a time',
        Editedsuccessfully: 'Edit successfully!',
        Shoppingrebate: 'Shopping Rebates',
        Rebateratio: 'Rebate percentage',
        Rebatestarttime: 'starting time of rebate',
        Rebateendtime: 'ending time of Rebate',
        Rebatetime: 'Rebate time',
        doyouwanttodelete2: 'To delete or not to delete',
        ActivityTopic: 'Event Topic',
        ActivityName: 'Event name',
        Whethertodisplayonthehomepage: 'Whether to display on the front page',
        QuerySearch: 'search',
        TopicName: 'Topic name',
        Activitytime: 'Event time',
        Richtextornot: 'Rich text or not',
        ActivityDescription: 'Event description',
        SpecialSEO: 'Thematic SEO',
        LogisticsPricing: 'Logistics Pricing',
        Basicfreight: 'Basic Shipping Rates',
        Freeshippingprice: 'Free Shipping Prices',
        Deliverytimeprompt: 'Shipping time Tips',
        Receivingtimeprompt: 'Receiving time prompt',
        PricingSettings: 'Pricing Settings',
        Freightstandard: 'Shipping standard',
        Freightallocation: 'Shipping configuration',
        FreightType: 'Shipping Type',
        Freeshipping: 'Free Shipping',
        Configureshippingcosts: 'Configure shipping costs',
        Fullreductionoffreight: 'cash back of freight',
        Orderamountfull: 'Order amount reaches to',
        Receivingandshippingreminder: 'receiving  and shipping reminder',
        link: 'Link',
        confirm: 'Confirm',
        Cannotbe0: 'Cannot be 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Only up to two decimal places can be entered',
        Pleaseinputthenumbercorrectly: 'Please enter the correct number',
        Pleaseenterthecountry: 'Please enter country',
        Pleaseselectshippingcost: 'Please select shipping cost',
        Pleasecompletetheshippingcostconfiguration: 'Please complete the shipping configuration',
        Pleaseenteradeliveryprompt: 'Please enter a receipt reminder',
        Pleaseenteralink: 'Please enter a link',
        Addlogisticsconfiguration: 'Add logistics configuration',
        Editlogisticsconfiguration: 'Edit Logistics Configuration',
        Pleaseenterthelogisticscompany: 'Please enter a logistics company',
        Editingfailed: 'Edit failed',
        Addlogisticscompany: 'Add logistics company',
        Pleaseuploadtheicon: 'Please upload an icon',
        Pleaseenterthelinkcorrectly: 'Please enter the link correctly',
        Successfullyadded: 'Added successfully',
        Editlogisticscompany: 'Edit Logistics Company',
        Thisisalreadythefirstitemmoveupfailed: 'First item already, failed to move up',
        Thisisalreadythelastitemmovedownfailed: 'Already last item, move down failed',
        Costprice: 'Cost price',
        taxrate: 'Tax rate',
        commission: 'Commissions',
        Grossmargin: 'Gross margin',
        doyouwanttodelete: 'the number filled is too big',
        Pleaseselectapricetype: 'Please select a price type',
        Pleasecompletethetaxrateconfiguration: 'Please complete the tax rate configuration',
        Pleasecompletethecommissionconfiguration: 'Please complete the commission configuration',
        PleasecompleteGrossmarginconfiguration: 'Please complete the gross rate configuration',
        Weightrange_start_kg: 'Weight range (start) kg',
        Weightrange_end_kg: 'Weight range (end) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'A maximum of four decimal places can be entered',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'End value must be greater than or equal to the start value',
        Pleasecompletetheweightrangeconfiguration: 'Please complete the weight range configuration',
        Pleaseentertheshippingcost: 'Please enter the shipping cost',
        Addshippingstandard: 'Add shipping standard',
        EditFreightStandard: 'Edit shipping standard',
        Allocationofpatrioticcoins: 'Aigo Coins Configuration',
        RuleConfiguration: 'Rules configuration',
        ObtainingPatrioticCoinObject: 'objects of getting Aigo coins',
        Rewardconditionsobtained: 'Conditions for obtaining rewards',
        Obtainpatrioticcoinallocation: 'Get Aigo coins Configuration',
        Inputquantity: 'Enter quantity',
        EnterName: 'Enter name',
        Registrationsuccessfulnot: 'Successful registration (not use invitation code )',
        Inviteduserplacesanorder: 'Invited user placing order',
        Registrationsuccessful: 'Registration successful (use invitation code)',
        Registeredaccount: 'Registered account (registered country)',
        Registrationsuccessfulnot1: 'Successful account registration without invitation code',
        Numberoflocalcurrencies: 'The quantities configured below are in the national currency of the corresponding country',
        Inviteraccount: 'Inviter account ( provide account of invitation code, country of invitation code)',
        Provideinvitationcode: 'Provide the invitation code to other users, other users use the invitation code to register and then successfully complete an order (each invited user can only get 1 bonus)',
        Usinganinvitationcode: 'register account successfully by using the invitation code',
        ProductPromotion: 'Product Promotions',
        discount: 'Discounts',
        Freeshippingornot: 'Free shipping or not',
        Secondkillsorting: 'sec-killing sorting',
        surplus: 'Remaining',
        Totalnumber: 'Total',
        ImportFile: 'Import files',
        Selectupto50: 'Select up to 50',
        Setuppromotions: 'Set promotion',
        Nocountrieslisted: 'No countries where products are put on shelf',
        Promotiontime: 'Promotion time',
        Displaypromotionalprices: 'Whether to display the promotional price',
        PromotionPrice: 'Promotional price',
        Promotionquantity: 'Promotional quantity',
        Doyousupporttheuseofcoupons: 'Whether to support the use of coupons',
        Purchaserestrictionsforthesameuser: 'purchase restrictions for the same user',
        Singleorderlimit: 'Limited to one single order',
        CurrentactivitypricesinSpain: 'The gross margin on the current activity price in {countryCode} is',
        SubmitConfirmation: 'Submit confirmation',
        Productdistribution: 'Product Distribution',
        DistributionName: 'Distribution name',
        DistributionCountry: 'Country of distribution',
        Distributionprice1: 'Distribution price',
        Distributiontime: 'Distribution time',
        DistributionPrice: 'Distribution price',
        Distributionquantity: 'Quantity of distribution',
        CopyConnection: 'Copy link',
        Successfullycopied: 'Copied successfully',
        Countryofuse: 'Country of use',
        Discounttype: 'Offer type',
        Fullreduction: 'cash back',
        Isthereafixedcode: 'Is there a fixed codes',
        Effective: 'Valid',
        Comingsoon1: 'Valid soon',
        Expired: 'Expired',
        Overlayornot: 'stack or not',
        Istheexchangecodefixed: 'whether to fix the redemption code',
        Received: 'received',
        Used: 'Used',
        Availableamount: 'available amount',
        Maximumdeductionamount: 'Maximum deduction amount',
        Canitbestacked: 'Stackable or not',
        Redemptioncode: 'Redemption Code',
        Fullreductionamount: 'cash back amount',
        Enterredemptioncode: 'Enter redemption code',
        Numberofredemptioncodes: 'Number of codes',
        Numberofusesbythesameuser: 'Number of uses for the same user',
        Effectivetime: 'Valid time',
        Isitpossibletooverlaydistribution: 'Can be stacked with or without distribution',
        Canpromotionsbestacked: 'Can be stacked with or without promotion',
        Entercoupondescription: 'Enter voucher instructions',
        Pleaseenteradescription: 'Please enter a description',
        Pleaseselectadate: 'Please select a date',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Please enter the number of uses for the same user',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Please enter the available amount of 0 to indicate unlimited',
        Pleaseentertheavailableamount: 'Please enter the available amount',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'The number of redemption codes can only be more than the original',
        Pleaseenterthenumberofredemptioncodes: 'Please enter the number of redemption codes',
        Applicableclassification: 'Applicable categories',
        Applicableproducts: 'Applicable products',
        Useordernumber: 'Use order number',
        Claimstatus: 'Collection Status',
        Notclaimed: 'Uncollected',
        notused: 'Not used',
        Usagestatus: 'Use Status',
        Collectiontime: 'Collection time',
        Usagetime: 'Time of use',
        UsingUsers: 'using users',
        BannerManagement: 'Banner Management',
        position: 'Location',
        JumpType: 'Jump type',
        Nojump: 'No Jump',
        Homepagerecommendationposition: 'Home page featured',
        Homebanner: 'Home banner',
        Displaytime: 'Display time',
        JumpLink: 'Jump link',
        Suggestedsize: 'Suggested size',
        Enterjumplink: 'Enter jump link',
        Uploadingimagesonlysupports: 'Upload images in jpg, png, gif, jpeg only',
        Topwithlargenumbers: 'Default is 0, the larger the number is, the higher the ranking is',
        Pleaseselectatleastonecountry: 'Please select at least one country',
        Boutiquerecommendationspace: 'Boutique recommendation space',
        Fullproductrecommendation: 'Full products recommendation',
        popupnotification: 'Pop-up window',
        Addbanner: 'Add banner',
        subscriptionmanagement: 'Subscription Management',
        Emailaddress: 'Email Address',
        Subscribing: 'Subscription in progress',
        Canceled: 'Canceled',
        SubscriptionSource: 'Subscrition source',
        Unsubscribe: 'cancel subscription',
        Resubscribe: 'Resubscribe',
        Pleaseselectatimerange: 'Please select a time range',
        WebsiteManagement: 'Site Management',
        informationservice: 'Information Services',
        New: 'Create',
        Isitdisplayedatthebottomofthewebsite: 'To display or not at the bottom of the website',
        Pleaseselectaninformationservice: 'Please select the information service',
        deleteconfirmation: 'Whether to confirm the deletion of the node and all information under the node',
        Pleaseenteracolumnname: 'Please enter a colum name',
        Thelengthcannotexceed50characters: 'Length cannot exceed 50 characters',
        ThelengthcannotexceedXcharacters: 'Length cannot exceed {num} characters',
        Pleaseentersorting: 'Please enter the order',
        Selectedcountrycontentisrequired: 'The selected country content is required',
        TitleName: 'Title name',
        Chinesetitleisrequired: 'Chinese title is required',
        Selectedcountrytitleisrequired: 'Title of selected country is required',
        EditingInformationServices: 'Edit Information Service',
        ColumnName: 'Column Name',
        Pleaseenterthecreationtime: 'Please enter the creation time',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Please enter if it is displayed at the bottom of the website',
        ViewInformationServices: 'View Information Service',
        Modificationtime: 'Modification time',
        Countrycannotbeempty: 'Country cannot be empty',
        Titlecannotbeempty: 'Title cannot be empty',
        Richtextcontentonthepagecannotbeempty: 'Page rich text content cannot be empty',
        Nationalconfiguration: 'Country configuration',
        currency: 'Currency',
        allocation: 'Configuration',
        Statuseditedsuccessfully: 'Status edited successfully',
        Areyousuretocontinuedeletingdata: 'Are you sureto continue deleting data',
        WebsiteAddress: 'Website address',
        Contactinformation: 'Contact information',
        Customerserviceemail: 'Customer service email',
        Socialmedia: 'Social Media',
        Socialmediainformation: 'Social media information',
        Pleasechooseapaymentmethod: 'Please select a payment method',
        Lengthgreaterthan20characters: 'Length greater than 20 characters',
        Lengthgreaterthan100characters: 'Length greater than 100 characters',
        Successfullydeletedinformation: 'Delete message successfully',
        Iconmustbeuploaded: 'Icon must be uploaded',
        Successfullyaddedinformation: 'Add message successfully',
        Successfullyeditedinformation: 'Edit message successfully',
        Pleaseentertheemailcorrectly: 'Please enter your email correctly',
        Thesizeoftheuploadedimagecannotexceed: 'The size of the uploaded image must not exceed',
        Countryname: 'Country name',
        Pleaseuploadpictures: 'Please upload a picture',
        NewCountryInformation: 'Create new country information',
        EditCountryInformation: 'Edit country information',
        Picturesmustbetransmitted: 'Images must be uploaded',
        NewCountryInformationTableImageAddress: 'Newly create country information sheet， image and address',
        Operationsuccessful: 'Successful operation',
        Euro: 'Euro',
        pound: 'Pound',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'Brand cannot be empty',
        Thewebsiteaddresscannotbeempty: 'Website address cannot be empty',
        Contactinformationcannotbeempty: 'Contact information cannot be empty',
        Thecustomerserviceemailaddresscannotbeempty: 'Customer service email address must not be empty',
        Medianamecannotbeempty: 'Media name cannot be empty',
        Themediaiconcannotbeempty: 'Media icon cannot be empty',
        Medialinkcannotbeempty: 'Media link cannot be empty',
        Thecountryiconcannotbeempty: 'Country icon cannot be empty',
        Languagecannotbeempty: 'Language cannot be empty',
        Currencycannotbeempty: 'Currency cannot be empty',
        Pagedescriptioncannotbeempty: 'Page description cannot be empty',
        Statusenable_disablecannotbeempty: 'Status Enable/Disable cannot be empty',
        SEOManagement: 'SEO Management',
        Import: 'Import',
        DownloadImportTemplate: 'Download import template',
        PageURL: 'Page URL',
        SEOdata: 'SEO Data',
        SEOConfigurationTemplate: 'SEO configuration template',
        Downloadsuccessful: 'Download successfully',
        SEOManagementImport: 'SEO Management Import',
        DragtheExcelfilehere_orclickonupload: 'Drag and drop the excel file here or click Upload',
        Fileimportedsuccessfully: 'File imported successfully',
        Fileimportfailed_pleasecheckthefilecontent: 'File import failed, please check file content',
        Pleaseenterthelanguage: 'Please enter the language',
        Thelengthcannotexceed2characters: 'Length cannot exceed 2 characters',
        PleaseenterthepageURL: 'Please enter the page URL',
        Thelengthcannotexceed100characters: 'Length must not exceed 100 characters',
        Thelengthcannotexceed20characters: 'Length must not exceed 20 characters',
        No_Illegalrowdata: 'The ? line of data is illegal',
        Failedtouploadfile_Pleasecheckthefilecontent: 'File upload failed, please check the content of the file',
        Namecannotbeempty: 'Name must not be empty',
        PageURLcannotbeempty: 'Page URL must not be empty',
        Thepagetitlecannotbeempty: 'Page title must not be empty',
        SEOManagementImportTemplate: 'SEO management import template',
        Donotmodify: 'Please do not modify the first three lines of the template title',
        Required: 'Fields marked with ' * ' are required',
        Brandandlanguagemustbefullycapitalized: 'Brand and language must be in all capital letters',
        SEOManagementExport: 'SEO Management Export',
        Pagekeywordscannotbeempty: 'Page keywords cannot be empty',
        Prohibitedsalesarea: 'Prohibited sales areas',
        Pleaseenterthepostalcodecorrectly: 'Please enter your postcode correctly',
        Pleasefillinthepostalcode: 'Please fill in your postcode',
        Countrycodecannotbeempty: 'Country code must not be empty',
        Postalcodecannotbeempty: 'Postcode must not be empty',
        Postalcodealreadyexists: 'Postcode already exists',
        Homepagearticle: 'Homepage articles',
        BrandStory: 'Brand Stories',
        SciencePopularizationZone: 'Science popularization zone',
        Lightstripzone: 'Strip Light zone',
        Pictureandtext: 'picture and text',
        Comingsoon: 'release soon',
        Publishinginprogress: 'releasing  in progress',
        column: 'Column',
        Releasetime: 'Release Date',
        Pleaseenteratitle: 'Please enter a title',
        Pleaseselectacolumn: 'Please select a column',
        Pleaseselectatype: 'Please select type',
        Patrioticcoinusagelog: 'Aigo Coins Usage Log',
        income: 'Income',
        expenditure: 'Expenditure',
        mode: 'Method',
        Orderexpenses: 'Order Spending',
        Orderrewards: 'Order Rewards',
        OrderCloseReturn: 'Order closure and return',
        other: 'Other',
        Backendrecharge: 'background recharge',
        Orderrebate: 'Order Rebate',
        Numberofchangesinpatrioticcoins: 'Number of Aigo coins changed',
        Numberofpatrioticcoinsafterchanges: 'Number of Aigo coins after change',
        CountryCode: 'Country code',
        Changetime: 'Time of change',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: "Please select user's Aigo coins consumption history",
        WebsiteNavigation: 'Site Navigation',
        HomepageConfiguration: 'Home page configuration',
        Navigationcontent: 'Navigation content',
        Recommendedforyou: 'Recommended for you',
        SelectNavigation: 'Select navigation',
        Pleasecheckfirst: 'Please tick first',
        Logmanagement: 'Log management',
        UserID_IP_LoginDevice: 'User id/ip/login device',
        logintime: 'Login time',
        Loginmethod: 'Login method',
        IPaddress: 'ip address',
        Logindevice: 'Login device',

        //11-22
        AssociatedOrderNumber: 'Associated order number',
        recordId: 'ID do registo',

        UserAccess: 'User Access',
        PleaseSelectTheStatisticalType: 'Please select the statistical type',
        StatisticalByDate: 'Statistical by date',
        StatisticalBySource: 'Statistical by source',
        Date: 'Date',
        Visits: 'Visits',
        NewVisitors: 'New visitors',
        OrderStatistics: 'Order statistics',
        Sales: 'Sales',
        TotalOrders: 'Total orders',
        AverageOrderAmount: 'Average order amount',
        SynchronizeEBP: 'Synchronize EBP',
        ParameterName: 'Parameter name',
        OnTheShelf: 'On the shelf',
        OfflineShoppingCart: 'Offline shopping cart',
        AddToCart: 'Add to cart',
        EnterCheckout: 'Enter checkout',
        UserLogin: 'User login',
        UserRegistration: 'User registration',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Different IP numbers for adding items to the shopping cart',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Different IP numbers for entering the order confirmation page',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Different IP numbers for who completed the login process after being redirected from the offline shopping cart to the login page.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Different IP numbers for who completed the registration process after being redirected from the offline shopping cart to the register page.',
        UserBehavior: 'User behavior',
        Display: 'Display',
        DoNotDisplay: 'Do not display',
        AddToPresale: 'Add to pre-sale',
        CancelPresale: 'Cancel pre-sale',
        PresaleConfiguration: 'Pre-sale configuration',
        OffTheShelf: 'Off the shelf',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Please complete the product information before setting up the pre-sale',
        SynchronizedEBPPrompts: 'Due to the need for synchronization with EBP, batch synchronization can only be performed once within X minute to avoid errors. After confirmation, synchronization is performed asynchronously, and the SKU is displayed immediately after obtaining it. Please be patient.',
        PleaseWait: 'Please wait',
        PleaseSelectATag: 'Please select a tag',
        Updating: 'Updating',
        Abortive: 'Failed',
        DefaultRule: 'Default rule',
        BottomInventory: 'Bottom inventory',
        SynchronisedAlertsForGraphicDetails: "Graphic details: Synchronize the corresponding language's [product keywords] according to the language",
        SynchronisedAlertsForLargeProductImages: "Product image: Synchronize the corresponding language's image according to the language, read the EBP image sorting, and automatically set the first EBP image as the main image",
        Account: 'Account',
        Account1: 'Account',
        EnterUpToNumItems: 'Enter up to {num} items',
        SynchronizedSuccessfully: 'Synchronized successfully',
        SuccessfullyListed: 'Successfully listed',
        SearchSuccessful: 'Search successful',
        EnterNewPassword: 'Enter new password',
        OnlyNumbersAndLettersAreAllowed: 'Only numbers and letters are allowed',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Please complete the pre-sale configuration before listing the pre-sale item',
        ProductWeight: 'Product weight',
        ConfirmExecution: 'Confirm execution?',
        ConfirmBatchListing: 'Confirm batch listing?',
        Template: 'Template',
        PleaseSelectTheProductsToBeSynchronized: 'Please select the products to be synchronized',
        ForcedSynchronisationDescription: 'Forced synchronization will delete the modified data and replace it with synchronized data. Ordinary synchronization will not synchronize the modified product data.',
        ConfirmSynchronizationExecution: 'Confirm synchronization execution',
        SynchronisationHasBeenSentPleaseWait: 'The synchronization task message has been sent, please wait patiently for the data synchronization to complete',
        ProhibitLogin: 'Prohibit login',
        IsTheUserLoggedIn: 'Is the user logged in?',
        Prohibit: 'Prohibit',
        ProhibitedSuccessfully: 'Prohibited successfully',
        EnableLogin: 'Enable login',
        EnabledSuccessfully: 'Enabled successfully',
        OperationCanceled: 'Operation canceled',
        UserData: 'UserData',
        GraphicDetails: 'Graphic details',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'This product has been set as a combination product and cannot be set for pre-sale. Please remove the product from the combination first?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'This product is a promotional product and cannot be set for pre-sale. Please cancel the promotion first.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'This product is a distribution product and cannot be set for pre-sale. Please cancel the distribution first.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'This product already has a full reduction activity and cannot be added',
        ESNoPriceNotAvailableForPresale: 'This product does not have a price in {Any} and cannot add pre-sale. Please first remove corresponding country or synchronize the price of the corresponding country before adding pre-sale products!',
        ConfirmSettingThisProductAsAPresaleItem: 'Confirm setting this product as a pre-sale item?',
        PresaleAddedSuccessfully: 'Pre-sale added successfully',
        ConfirmSettingThisProductAsANonpresaleItem: 'Confirm setting this product as a non-pre-sale item?',
        CancelPresaleSuccessful: 'Cancel pre-sale successful',
        Preview: 'Preview',
        TheInventoryOfCurrentProducts0Continue: 'The inventory of current product {stock} is 0, continue?',
        TheInputContentCannotExceedCharacters: 'The input content cannot exceed {x} characters',
        PleaseSelectTheActivity: 'Please select the activity',
        OrderDetails: 'Order details',
        LogisticsInformation: 'Logistics information',
        CancelRemark: 'Cancel remark',
        DepositAmount: 'Deposit amount',
        FinalPaymentAmount: 'Final payment amount',
        FullReductionDiscount: 'Full reduction discount',
        YourBrowserDoesNotSupportVideoPlayback: 'Your browser does not support video playback',
        EditBanner: 'Edit banner',
        AddBanner: 'Add banner',
        UpdateTime: 'Update time',
        HomepageRecommendationPosition: 'Homepage recommendation position',
        ActivityID: 'Activity ID',
        PleaseSelectThePosition: 'Please select the position',
        PleaseSelectTheJumpType: 'Please select the jump type',
        PleaseEnterTheAssociatedID: 'Please enter the associated ID',
        TheUploadedImageSizeCannotExceed5MB: 'The uploaded image size cannot exceed {num}MB!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Only mp4 format is supported for video upload',
        TheUploadedVideoSizeCannotExceed500MB: 'The uploaded video size cannot exceed {num}MB!',
        PleaseUploadTheVideo: 'Please upload the video',
        PleaseEnterTheJumpLink: 'Please enter the jump link',
        PleaseEnterTheCorrectLink: 'Please enter the correct link',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Verification failed, please enter the required fields first',
        AddFailed: 'Add failed',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'It is already the first one, and the upward movement failed',
        PleaseEnterTheRebateRatio: 'Please enter the rebate ratio',
        EditCoupon: 'Edit coupon',
        AddCoupon: 'Add coupon',
        IDCouponName: 'ID/coupon name/Redemption Code',
        Random: 'Random',
        Fixed: 'Fixed',
        AboutToTakeeffect: 'About to take effect',
        NotStackable: 'Not stackable',
        Stackable: 'Stackable',
        Frozen: 'Frozen',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Please enter the maximum deduction amount, 0 means unlimited',
        UseUserID: 'Use user ID',
        UseOrderNumberExchangeCodeUserID: 'Use order number/exchange code/user ID',
        PleaseEnterTheDiscount: 'Please enter the discount',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Only numbers from 0 to 100 can be entered, up to 1 decimal place',
        PleaseEnterTheFullReductionAmount: 'Please enter the full reduction amount',
        PleaseEnterTheExchangeCode: 'Please enter the exchange code',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Only numbers/letters can be entered, at least 6 digits, up to 20 digits',
        PleaseSelectAFixedExchangeCode: 'Please select a fixed exchange code',
        PleaseEnterTheMaximumDeductionAmount: 'Please enter the maximum deduction amount',
        PleaseSelectWhetherItCanbeStacked: 'Please select whether it can be stacked',
        WarehousingTime: 'Warehousing time',
        EnterActivityDescription: 'Enter activity description',
        ActivityTitleCannotBeEmpty: 'Activity title cannot be empty',
        TheTitleCannotExceed200Characters: 'The title cannot exceed {num} characters',
        PleaseEnterTheActivityDescription: 'Please enter the activity description',
        ActivityTimeCannotBeEmpty: 'Activity time cannot be empty',
        ActivityDescriptionLimitOf10000Words: 'Activity description limit of {num} words',
        ActivityRecommendation: 'Activity recommendation',
        Promotion: 'Promotion',
        EveryFull: 'Every {num} full',
        EnterAmount: 'Enter amount',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Only positive numbers can be entered, up to 2 decimal places, cannot start with 0',
        PleaseEnterTheCorrectAmount: 'Please enter the correct amount',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Please select applicable categories or applicable products',
        Rules: 'Rules',
        RemainingTotal: 'Remaining/total',
        CopyLink: 'Copy link',
        DistributionNameProductIDBarcode: 'Distribution name/product ID/barcode',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'This sub function is not available in this country for the time being, and will be available later',
        PleaseEnterThePrice: 'Please enter the price',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Automatically calculate after entering the discount',
        PleaseEnterTheQuantity: 'Please enter the quantity',
        OrderRemarkLabel: 'Order remark label',
        OrdersWorkWithInfluencers: 'Orders work with influencers',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'The current gross profit margin of the current activity price is',
        ThePriceCannotBeLowerThanTheLowestPrice: 'The price cannot be lower than the lowest price:',
        Distribution: 'Distribution',
        IDName: 'ID/name',
        ProductSelected: 'Product selected',
        ProductHasNoPricing: 'Product has no pricing',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'The current gross profit margin of the current activity price is {rate}%, are you sure to submit?',
        SubscriptionStatus: 'Subscription status',
        URLClickToCopyAddress: 'URL (click to copy address)',
        URLSourceManagement: 'URL Source management',
        PleaseEnterTheURL: 'Please enter the URL',
        PleaseEnterEnglishOrNumbers: 'Please enter English or numbers',
        LengthCannotExceed1000Characters: 'Length cannot exceed 1000 characters',
        URLFixedAddressIsEmpty: 'URL fixed address is empty',
        cover: 'Cover',
        PleaseEnterOrPasteTheVideoScript: 'Please enter or paste the video script',
        VideoScript: 'Video script',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Please upload a video file or fill in the video script',
        PleaseEnterTheBrand: 'Please enter the brand',
        Portuguese: 'Portuguese',
        Register: 'Register',
        InviteUsers: 'Invite users',
        OrderPayment: 'Order payment',
        SupportsMP4Forma500MB: 'Supports mp4 format, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'No product is currently selected, whether to close it?',
        ExitFullScreen: 'Exit full screen',
        FullScreen: 'Full screen',
        Logout: 'Logout',
        OnlySupportsFilesInFormat: 'Only supports files in {xxx} format',
        PleaseSetAllPromotionalProducts: 'Please set all promotional products',
        PleaseEnterTheWeight: 'Please enter the weight',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Fixed price cannot be lower than the lowest price',
        CloseOthers: 'Close others',
        CloseAll: 'Close all',
        ImportSuccessful: 'Import successful',
        BatchRecharge: 'Batch recharge',
        RechargeEmail: 'Recharge email',
        RechargeCountry: 'Recharge country',
        RechargeSuccessful: 'Recharge successful',
        RechargeInformation: 'Recharge information',
        PleaseDownloadTheRechargeInformationFirst: 'Please download the recharge information first',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'The batch recharge data of the AIGO coin cannot be empty',
        TheBatchRechargeDataCannotExceed1000Records: 'The batch recharge data cannot exceed 1000 records',
        ConfirmSubmission: 'Confirm submission',
        ExportFailed: 'Export failed',
        PackingQuantity: 'Packing quantity',
        Activity: 'Activity',
        PleaseSelectWhetherToEnable: 'Please select whether to enable',
        OnlyOneProductCanBeSynchronized: 'Only one product can be synchronized',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU with a quantity of {num} does not exist',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU with a quantity of {num} does not meet the sales multiple',
        SalesMultipleDoesNotMatchCannotBeListed: 'Sales multiple does not match, cannot be listed',
        SalesMultipleIsEmptyInSpain: 'Sales multiple is empty in Spain',
        SynchronizationOfPackageQuantityIsAbnormal: 'Synchronization of package quantity is abnormal',
        CannotContinueToMoveUp: 'Cannot continue to move up',
        CannotContinueToMoveDown: 'Cannot continue to move down',
        SynchronizeThePackageQuantity: 'Synchronize the package quantity',
        ProductAssociationIDCannotBeEmpty: 'Product association ID cannot be empty',
        PackageQuantityCannotBeEmpty: 'Package quantity cannot be empty',
        PleaseEnterTheCorrectQuantityFormat: 'Please enter the correct quantity format',
        PleaseEnterSeparatedBy: 'Please enter (separated by #)',
        PackageQuantity: 'Package quantity',
        LengthBetween1And140Characters: 'Length between 1 and 140 characters',
        PleaseSelectTheVariantParametersFirst: 'Please select the variant parameters first',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'The parameter is associated with the variant, please be careful',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Please select data of the same category, please reselect',
        UpTo5VariantsCanBeSelected: 'Up to 5 variants can be selected',
        NoVariant: 'No variant',
        VerificationFailedPleaseSelectTheVariant: 'Verification failed, please select the variant',
        VerificationFailedPleaseSelectTheProduct: 'Verification failed, please select the product',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verification failed, the product parameter value is empty',
        SetSuccessfully: 'Set successfully',
        UploadingVideo: 'Uploading video',
        SearchParameters: 'Search parameters',
        ImportantParameters: 'Important parameters',
        Select: 'Select',
        ConfirmSettingThisImageAsTheMainImage: 'Confirm setting this image as the main image',
        ConfirmDisplayDoNotDisplay: 'Confirm display/do not display',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'The current limit is to select {num1} files, this time you have selected {num2} files, a total of {num3} files have been selected',
        ConfirmToRemoveAny: 'Confirm to remove {Any}?',
        TheTestEnvironmentCannotUploadFiles: 'The test environment cannot upload files',
        PleaseEnterTheEnglishTitle: 'Please enter the English title',
        CancelSettingAsTheMainImage: 'Cancel setting as the main image?',
        SetAsTheMainImage: 'Set as the main image?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Cannot submit images or videos that already exist',
        SynchronizeAnyImagesToOtherLanguages: 'Synchronize {Any} images to other languages?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Please select the product to be synchronized first',
        PleaseSelectTheProductFirst: 'Please select the product first',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Confirm whether to delete the selected image/video?',
        TheMainPictureCannotBeTurnedOff: 'The main picture cannot be turned off',
        CancelDisplay: 'Cancel display?',
        ConfirmWhetherToDelete: 'Confirm whether to delete?',
        PleaseUploadTheEnglishImage: 'Please upload the English image',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Failed, please enter the product title for all sales countries',
        PresaleTime: 'Pre-sale time',
        PresalePrice: 'Pre-sale price',
        PleaseEnter: 'Please enter',
        PresaleQuantity: 'Pre-sale quantity',
        DepositRatio: 'Deposit ratio',
        AssociatedAGBSOnthewayOrders: 'Associated AGBS on-the-way orders',
        EstimatedDeliveryTime: 'Estimated delivery time',
        FinalPaymentDeadline: 'Final payment deadline',
        SelectTime: 'Select time',
        PreSalePrice: 'Pre-sale price',
        FixedPrice: 'Fixed price',
        ByProportion: 'By proportion',
        ByFullPayment: 'By full payment',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Only numbers greater than 0 can be entered, up to two decimal places',
        PresaleTimeCannotBeEmpty: 'Pre-sale time cannot be empty',
        PresalePriceTypeCannotBeEmpty: 'Pre-sale price type cannot be empty',
        PresaleQuantityCannotBeEmpty: 'Pre-sale quantity cannot be empty',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Only positive integers can be entered, cannot start with 0',
        DepositRatioTypeCannotBeEmpty: 'Deposit ratio type cannot be empty',
        DepositRatioCannotBeEmpty: 'Deposit ratio cannot be empty',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Only integers between 0 and 100 can be entered, excluding 0 and 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'Estimated delivery time cannot be empty',
        FinalPaymentDeadlineCannotBeEmpty: 'Final payment deadline cannot be empty ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Final payment should be done before 5 hours before the end of presale promotion',
        TheRemainingAmount: 'The remaining amount',
        PresaleStatus: 'Pre-sale status',
        PresaleStartTime: 'Pre-sale start time',
        PresaleEndTime: 'Pre-sale end time',
        OnPresale: 'On pre-sale',
        DirectVisit: 'Direct visit',
        BackendManagementSystem: 'Backend management system',
        Login: 'Login',
        TipsFillInTheUsernameAndPasswordCasually: 'Tips: Fill in the username and password casually.',
        PleaseEnterUserName: 'Please enter user name',
        PleaseEnterPassword: 'Please enter password',
        LoginSuccessful: 'Login successful',
        PleaseEnterYourAccountNumberAndPassword: 'Please enter your account number and password',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'The same product can only be returned or exchanged',
        B2CBrandWebsite: 'B2C Brand Website',
        CommentManagement: 'Comment management',
        AIGOCoins: 'AIGO coins',
        PleaseEnterThePackageQuantity: 'Please enter the package quantity',
        PresalePriceCannotBeEmpty: 'Pre-sale price cannot be empty',
        Product: 'Product',
        HomePageColumn: 'Home page column',
        ColumnDescription: 'Column description',
        FlashSaleArea: 'Flash sale area',

        //1.2.8之后
        SoldOutOrNot: 'Sold out or not',
        VirtualQuantity: 'Virtual quantity',
        VirtualQuantityPackageQuantity: 'Virtual quantity/package quantity',
        CombinationIcon: 'Combination icon',
        UploadFailedFileSizeShouldBe3MB: 'Upload failed, file size should be <3MB',
        PromotionQuantityPackageQuantity: 'Promotion quantity/package quantity',
        RecommendedSize100: 'Recommended size: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Total - Remaining/Total',
        Verification: 'Verification',
        VirtualSalesVolume: 'Virtual sales volume',
        VirtualSalesVolumePackageQuantity: 'Virtual sales volume/package quantity',
        DeleteOrNot: 'Delete or not?',
        EnabledOrNot: 'Enabled or not',
        Cancel: 'Cancel',
        Code: 'Code',
        Confirm: 'Confirm',
        RebateDimension: 'Rebate dimension',
        InProgress: 'In progress',
        ActivityStatus: 'Activity status',
        RebateRatioManagement: 'Rebate ratio management',
        StartTime: 'Start time',
        EndTime: 'End time',
        RebateAmount: 'Rebate amount',
        NewUser: 'New user',
        RebateObjectNumber: 'Rebate object number',
        NumberOfCompletedOrders: 'Number of completed orders',
        RebateObject: 'Rebate object',
        Operation: 'Operation',
        RecentOperator: 'Recent operator',
        Country: 'Country',
        Prompt: 'Prompt',
        RebateProducts: 'Rebate products',
        NewUserPromotion: 'New user promotion',
        RegularUserPromotion: 'Regular user promotion',
        Upcoming: 'Upcoming',
        RebateRatio: 'Rebate ratio',
        RebateOrNot: 'Rebate or not',
        Rebate: 'Rebate',
        PleaseSelectARebateDimension: 'Please select a rebate dimension',
        PleaseSelectARebateObject: 'Please select a rebate object',
        PleaseSelectACountry: 'Please select a country',
        PleaseSelectARebateRatio: 'Please select a rebate ratio',
        RecentOperationTime: 'Recent operation time',
        PromotionObjectType: 'Promotion object type',
        ProductPromotionType: 'Product promotion type',
        UserType: 'User type',
        ApplicableUserType: 'Applicable user type',
        RegularUser: 'Regular user',
        UseAigoCoinsOrNot: 'use Aigo coins or not',
        EndDateOfMembership: 'end date of membership',
        StartDateOfMembership: 'start date of membership',
        memberOrNot: 'member or not',
        No: 'No',
        Yes: 'Yes',
        LowInventory: 'Low inventory',
        RechargeEmailbox: 'Recharge E-mailbox',
        SubmittedSuccessfully: 'Submitted successfully',
        Importing: 'importing',
        InformationOfTheRechargeFailed: 'information of the recharge failed',
        RechargeFailed: 'Recharge failed',
        RechargeInBatch: 'Recharge in batch',
        RechargeSuccessfully: 'Recharge successfully',
        RechargeAigoCoins: 'Recharge Aigo coins',
        PDFFormat: 'PDF format',
        FileSize3MB: 'File size <3MB',
        UploadFailedPDFFormatRequired: 'Upload failed, PDF format required',
        InvoiceAttachment: 'Invoice Attachment',
        Upload: 'Upload',
        UploadFailed: 'Upload failed',

        HaveAnAccount: "Don't have an account? Click here to register",
        SoldOut: 'Sold out',
        HaveAnInvitationCode: "Optional, leave blank if you don't have an invitation code.",
        RestockedItems: 'Restocked items',
        NotMeetPleaseEnterAgain: 'Nickname does not meet the requirements, please enter again.',
        ThisCouponIsOnlyAvailableForNewUsers: 'This coupon is only available for new users.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'This coupon is only available for existing users.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Only returns or exchanges of the same product are allowed.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',
        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',
        GoToView: 'Go to view',
        DoNotRegisterRepeatedly: 'Do not register repeatedly',
        ValidityPeriod: 'Validity period',
        RegisterSuccessfully: 'Register successfully',
        MemberRegistration: 'Member registration',
        RegistrationFailed: 'Registration failed',
        OneYear: 'One year',
        AddToShoppingCart: 'Add to shopping cart',
        AddANewAddress: 'Add a new address',
        ZIPCode: 'ZIP Code*',
        EditYourAddress: 'Edit your address',
        SetAsDefault: 'Set as Default',
        FailedChangeDefaultAddress: 'Failed to change default address, please try again later',
        Fullname: 'Full name(First and Last name)*',
        PhoneNumber: 'Phone number',
        DefaultAddressHasBeenChanged: 'default address has been changed',
        ViewComments: 'View comments',
        ClickToCheckLogisticsInfo: 'Click to check logistics info.',
        RestockingOfOutOfStockItems: 'Restocking of out-of-stock items',
        ThisWeekRestockedItems: "This week's restocked items",
        ProductListInTheShoppingCart: 'product list in the shopping cart',
        WarmReminderVideoResources: 'warm reminder: Video resources are only available for single download.',
        PleaseLeaveYourMessage: 'Please leave your message',
        InvoiceManagement: 'Invoice management',

        ReadmeFile: 'Readme file',
        HelpCenter: 'Help Center',
        FileUpload: 'File upload',
        Globalization: 'Globalization',
        SalespersonProfile: 'Salesperson profile',
        BatchSyncPage: 'Batch sync page',
        CountryConfigurationPage: 'Country configuration page',
        UserConsultation: 'User consultation',
        PreSaleItems: 'Pre-sale items',
        EventManagement: 'Event management',
        BlankPage: 'Blank page',
        ThereAreCurrentlyNoProductPromotions: 'There are currently no product promotions',
        EditInventoryBottomLine: 'Edit inventory bottom line',
        SetAFixedPrice: 'Set a fixed price',
        EnterUpTo500Entries: 'Enter up to 500 entries',
        CantRelateToYourself: "Can't relate to yourself",
        PleaseSelectTheApplicableUserType: 'Please select the applicable user type',
        TheActivityDescriptionLimitIs10000Words: 'The activity description limit is 10,000 words',
        EnabledStatus: 'Enabled status',
        Enable: 'Enable',
        OperatingTime: 'Operating time',
        NewUserPromotionEnabled: 'New user promotion enabled',
        NewUserPromotionDisabled: 'New user promotion disabled',
        OldUserPromotionEnabled: 'Old user promotion enabled',
        OldUserPromotionDisabled: 'Old user promotion disabled',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'The promotional price cannot be lower than the lowest price',
        AutomaticallyCalculateAfterEnteringPrice: 'Automatically calculate after entering price',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'The promotional price for new users is greater than the promotional price for old users. Do you want to continue?',
        PleaseSelectPromotionCountry: 'Please select promotion country',
        PromotionDiscount: 'Promotion discount',
        PleaseEnterThePromotionalQuantity: 'Please enter the promotional quantity',
        PleaseEnterVirtualQuantity: 'Please enter virtual quantity',
        NumberOfUserPurchases: 'Number of user purchases',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Please enter the purchase limit for the same user',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Please enter the purchase limit for a single order',
        QuantityGreaterThan0: 'Quantity greater than 0',
        GrossProfitMarginCalculationResults: 'Gross profit margin calculation results',
        FixedPriceGrossMargin: 'Fixed price gross margin',
        SellingPriceGrossProfitMargin: 'Selling price gross profit margin',
        PromotionalPriceGrossProfitMargin: 'Promotional price gross profit margin',
        PromotionStartTime: 'Promotion start time',
        PromotionEndTime: 'Promotion end time',
        PromotionType: 'Promotion type',
        PleaseSetTheSKUToPromotionalProducts: 'Please set the SKU to ${xxx} promotional products',
        DistributionOffers: 'Distribution offers',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'After entering the quantity{Any} , it will be automatically calculated based on the quantity of the product.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'The number of user purchases must be greater than 0',
        PleaseSelectCountry: 'Please select ${typeName} country',

        RequiredField: 'Required',
        AuditFailedPleaseUploadInvoiceAttachment: 'The review failed, please upload the invoice attachment.',
        PleaseUploadInvoiceAttachment: 'Please upload the invoice attachment',
        PleaseSelectAtLeastTheApplicableUserType: 'Please select at least the applicable user type',
        PleaseEnterTheRedeemCode: 'Please enter the redeem code',
        ApplicableUserRange: 'Applicable user range',
        NoProductIsSelectedWhetherToClose: 'No product is selected, whether to close？',
        DeliveryCountry: 'Delivery country',
        MemberSettings: 'Member settings',
        PleaseEnterTheAccountName: 'Please enter the account name',
        MembershipEndDateCannotBeLessThanMembershipStartDate: 'Membership end date cannot be less than membership start date',
        CharactersLimited: '1 to 30 characters limited',
        NumberOfDifferentIPsToAccessTheURL: 'Number of different IPs to access the URL',
        TheNumberOfDifferentIPAddressesForTheFirstVisitToTheWebsite: 'The number of different IP addresses for the first visit to the website',
        TheTotalOrderAmountPaidAvailableForShipmentShippedCompleted: 'The total order amount paid (available for shipment, shipped, completed)',
        QuantityOfPaidOrderAmountAvailableForShipmentShippedCompleted: 'Quantity of paid order amount  (available for shipment, shipped, completed)',
        BatchRemoval: 'Batch removal',
        ConfirmBatchDeletionOfSelectedProducts: 'Confirm batch deletion of selected products',
        RegisterOrNot: 'Register or not',
        FromSubscriptionTime: 'From subscription time',
        EndOfSubscriptionPeriod: 'End of subscription period',
        SubscriptionManagementExport: 'Subscription management export',
        FromTheEventStartTime: 'From the event start time',
        UntilTheEventStartTime: 'Until the event start time',
        EventEndTime: 'Event end time',
        EventStartTime: 'Event start time',

        SignUpForSubscription: 'Sign up for subscription',
        EnterYourEmailAddressToSubscribe: 'Enter your email address to subscribe',
        DownloadTheLatestProductInformationSheet: 'Download the latest product information sheet',

        Full: 'Full',
        Reduce: 'Reduce',
        Superimposable: 'Superimposable',
        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'After entering the promotional quantity, it will be automatically calculated based on the quantity of goods.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Please cancel the association in [Rebate Product Pool] first',
        EditBottomLineInventory: 'Edit bottom line inventory',
        PleaseConfigureFixedPrice: 'Please configure fixed price',
        BalancePayment: 'Balance payment',
        Deposit: 'Deposit',

        EndofValidity: 'Please enter the expiry date',
        Year: 'Year',
        Month: 'Month',
        Day: 'Day',
        UnitOfValidity: 'Unit of validity',
        AIGOCoinDetails: 'AIGO Coin Details',
        AIGOCoinsAreCloseToExpiring: 'AIGO coins are close to expiring(Day)',
        CountryOfShipmentAddress: 'Country of shipment address',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Estimated time of delivery',
        DataCorrespondingToTheSource: 'Data corresponding to the source',
        AccountStatus: 'Account status',
        OrderID: 'Order ID',
        RecipientName: 'Recipient name',
        RecipientLastName: 'Recipient last name',
        MailsOfUser: 'Mails of user',
        StateProvince: 'State/Province',
        CityTown: 'City/Town',
        County: 'County',
        ReceivingCompany: 'Receiving company',
        BatchInput: 'Batch input',
        InputManually: 'Input manually',
        ProductCommentsInputTemplate: 'Product comments input template',
        UserName: 'User name',
        Comments: 'Comments',
        StarRatingOfTheComment: 'Star rating of the comment',
        CommentTime: 'Comment time',
        UploadAttachment: 'Upload attachment',
        NumberOfAttachments: 'Number of attachments',
        FileName: 'File name',
        Appendix: 'Appendix',
        BatchInputOfProductComments: 'Batch input of product comments',
        SoldOut1: 'Sold out',
        PreSalePromotionID: 'Pre-sale promotion ID',
        PromotionAbnormal: 'Promotion abnormal',
        Updater: 'Updater',
        AddPreSalePromotion: 'Add pre-sale promotion',
        EditPreSalePromotion: 'Edit pre-sale promotion',
        Barcode: 'Barcode',
        PreSaleCountries: 'Pre-sale countries',
        PreSalePrice1: 'Pre-sale price',
        PreSaleDeposit: 'Pre-sale deposit',
        PleaseEnterTheDocumentCode: 'Please enter the document code',
        BalancePaymentDeadline: 'Balance payment deadline',
        FixedShippingFee: 'Fixed shipping fee',
        PreSaleShippingFee: 'Pre-sale shipping fee',
        FreeShippingSubjectToConditions: 'Free shipping subject to conditions',
        OrderPrice: 'Order price',
        PleaseEnterPriceConditions: 'Please enter price conditions',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'The end time needs to be later than the start time',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'There are already partially overlapping promotion in this country, please modify',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'The pre-sale quantity can only be a positive integer greater than 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'The estimated delivery time needs to be later than the event end time',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'The balance payment deadline needs to be earlier than the estimated delivery time',
        PromotionID: 'Promotion ID',
        DistributionPromotionID: 'Distribution promotion ID',
        CouponID: 'Coupon ID',
        PromotionManagementID: 'Promotion management ID',
        PortfolioManagementID: 'Portfolio management ID',
        ExportForFiltering: 'Export for filtering',
        ListDisplay: 'List display',
        InventoryItems: 'Inventory items',
        InStock: 'In stock',
        OutOfStock: 'Out of stock',
        FieldName: 'Field name',
        PleaseSelectInventory: 'Please select inventory',
        PreSaleEvent: 'Pre-sale event',
        PerPage: 'Each page shows',
        // V1.3.3 end

        // V1.3.4 start
        PleaseProvideTheNameOfTheLandingPageActivity: "Please provide the name of the landing page activity",
        PleaseIndicateTheNumberOfActivities: "Please indicate the number of activities.",
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: "The quantity of activity should exceed the amount given away.",
        PleaseChooseATimeForTheEvent: "Please choose a time for the event.",
        ActivityNameExceedsLength: "Activity name exceeds length.",
        ThisItemIsRestrictedToAMaximumOf10PerOrder: "This item is restricted to a maximum of 10 per order.",
        PleaseChooseTheGiveawayAgain: "Please choose the giveaway again.",
        AnalysisOfGiveawayActivities: "Analysis of giveaway activities",
        StatisticsOfGiveawayActivities: "Statistics of giveaway activities",
        PleaseEnterTheNameOfTheEvent: "Please enter the name of the event.",
        PleaseSelectTheActivityType: "Please select the activity type.",
        PleaseCompleteTheActivityRating: "Please complete the activity rating.",
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: "Please set up the {Any} pre-sale campaign before setting up the country where the pre-sale items will be displayed.",
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: "The order amount and number of freebies cannot be lower than the previous tier.",
        SelectImage: "Select image",
        GiftInformation: "Gift information",
        Gifts: "Gifts",
        GiftsOfEndOfEvent: "Gifts of end of event",
        CheckOutTheGifts: "Check out the gifts",
        GiveawayEvent: "Giveaway event",
        SelectAGift: "Select a gift",
        YouHaveNotSelectedAGift: "You have not selected a gift",
        NoNeedForGifts: "No need for gifts",
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: "Some gifts are out of stock, please reselect the gifts",
        ReplaceAGift: "Replace a gift",
        ThereAreCurrently3FreeGiftsAvailable: "There are currently 3 free gifts available",
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: "Currently 3 free gifts are available, and 4 more gifts are available for buying other 99 €",
        GiftList: "Gift list",
        GiftID: "Gift ID",
        CurrentInventory: "Current inventory",
        NotEnabled: "Not enabled",
        GoodsForFree: "Goods for free",
        ConfirmToDeleteThisGiftInformation: "Confirm to delete this gift information?",
        GiftsSortingManagement: "Gifts sorting management",
        GiftsTopicManagement: "Gifts topic management",
        GiveawayEventID: "Giveaway event ID",
        GiveawayEventName: "Giveaway event name",
        ProductCategory: "Product category",
        ActivityMode: "Activity mode",
        TotalNumberOfActivities: "Total number of activities",
        GiftsGivenQuantity: "Gifts given quantity ",
        ThemeConfiguration: "Theme configuration",
        GiftSorting: "Gift sorting",
        GlobalConfiguration: "Global configuration",
        ActiveCountry: "Active country",
        PromotionalPpicture: "Promotional picture",
        RecommendedSizeX: "Recommended size: ${XXX*XXX}",
        FileSize1MB: "File size: ≤1MB",
        ActivitySorting: "Activity sorting",
        DragToSort: "Drag to sort",
        ActivityCode: "Activity code",
        GiftsForOrderAmount: "Gifts for order amount",
        ActivityRating: "Activity rating",
        GiftsQuantity: "Gifts quantity",
        EventGiftsSorting: "Event gifts sorting",
        DefaultSelectionOfUsers: "Default selection of users",
        LandingPageDisplay: "Landing page display",
        LandingPageActivityName: "Landing page activity name",
        ConfirmDeleteThisGiftEvent: "Confirm delete this gift event?",
        IsThereAGiftEvent: "Is there a gift event?",
        OrderQuantity: "Order quantity",
        NumberOfGiftsIssued: "Number of gifts issued",
        CustomerPrice: "Customer price",
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: "The virtual event quantity function can only be set if the pre-sale activity time is at least more than 2 days and the number of events is greater than 50",
        PreSaleSwitch: "Pre-sale switch",
        PreSaleActivity: "Pre-sale activity",
        GiftManagement: "Gift management",


        // V1.3.4 end

        //V1.3.5 start 
        OrderSource: "Order source",
        NumberOfNewUserRegistrations: "Number of new user registrations",
        RegisteredUsersWhoVsitTheWebsite: "Registered users who visit the website",
        SoldDistributionQuantity: "Sold distribution quantity",
        DistributionQuantityLock: "Distribution quantity lock",
        InsufficientDstributionQuantityRemaining: "Insufficient distribution quantity remaining",
        Previous: "Previous",
        Next: "Next",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Recipe management",
        RecipeDisplayImage: "Recipe display image",
        NewRecipeDisplayImage: "Edit/create new recipe display image",
        CoverPicture: "Cover picture",
        PicturesVideos: "Up to 10 pictures + videos can be uploaded, each picture should be ≤5MB, and the video in.mp4 format is supported",
        AddPicturesVideos: "Add pictures/videos",
        CommenOnPicturesVideos: "Comment on pictures/videos",
        FileSize: "File size",
        BarcodeAssociated: "Barcode associated",
        FirstUploadTime: "First upload time",
        EditTime: "Edit time",
        TagType: "Tag type",
        FileName1: "File name",
        NameLengthISTooLong: "Name length is too long",
        ProductDoesNoExist: "Product does not exist",
        PleaseUploadImages: "Please upload jpg/png/jpeg images under 5MB",
        PleaseUploadTheVideoMP4: "Please upload the video in mp4 format",
        PictureOrVideo: "Picture or video cannot be empty",
        allowed: "Allow all",
        cookie1: "Cookies management",
        collectsordinances: "Aigostar uses cookies to improve visitors' browsing experience and for marketing purposes. View and manage your cookie settings below. To learn more about how we use cookies, please review our Privacy Policy.",
        RequiredCookies: "Necessary Cookies",
        cookie2: "These cookies are required for core functionality such as logging in, using the shopping cart, and making payments. Necessary cookies cannot be turned off. But don’t worry, these cookies don’t save any of your information.",
        AnalyticsCookies: "Marketing and analytics cookies",
        cookie3: "These cookies collect information such as the number of visits to the website, or which pages are popular, to help us improve our customer experience. You can choose to turn these cookies off, but then we will not be able to collect the information we need to improve your experience on our website.",
        StoragePreferences: "Save preferences",
        BuyItNow: "Buy it now",
        PictureVideoSize: "Picture or video size or format is not correct",
        RecipeConfiguration: "Recipe Configuration",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Confirm synchronization of sub-barcodes?",
        SynchronizationEbpEanSUB: "Due to the need to interface with EBP, batch synchronization is expected to be completed within 3 minutes. After confirmation, synchronization will proceed asynchronously, and the sub-barcodes will be displayed immediately upon receipt. Please be patient.",
        Keywords: "Keywords",
        SystemAutoUpdate: "System auto-update",
        IPAddress: "IP address",
        SearchKeywords: "Search keywords",
        LoginEmail: "Login email",
        SearchTime: "Search time",
        NumbeOfTimes: "Number of times",
        MyBrowsing: "My browsing",
        LoggingOuOfYouAccount: "Logging out of your account will be considered as your voluntary waiver and you will no longer be able to continue using this account.",
        RequestLogout: "Request Logout",
        ProductsYet: "You have not browsed any products yet",
        clearall: "Clear history",
        CancellTip1: "Shopping cart lists, order purchase records, and favorites records will be permanently deleted and cannot be recovered.",
        CancellTip2: "All account information, service records, consumption data, etc., on the platform will be permanently deleted and cannot be recovered.",
        CancellTip3: "You will be unable to log in or use this AigoSmart account.",
        CancellTip4: "Personal information and historical messages (including avatar, nickname, my Aiguo currency, platform notifications, my comments, etc.) associated with your AigoSmart account will not be retrievable.",
        CancellTip5: "You must handle account funds (points, membership levels, qualifications, orders, coupons, props, and other valuable services) properly before logging out, as account cancellation will be considered as your voluntary waiver and cannot be reversed. You understand and agree that AigoSmart cannot assist you in restoring the aforementioned services.",
        SynchronizeSubBarcodes: "Synchronize sub-barcodes",
        PleaseEnterKeywords: "Please enter keywords",
        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Hourly Statistics",
        NumbeOfUsers: "Number of users who placed orders",
        NumberOfCanceled: "Number of canceled orders",
        RefundAmount: "Refund amount",
        UsersWhoHavePaid: "Users who have paid (orders that can be shipped, shipped, completed)",
        TotalNumber: "Total number of orders canceled by the platform",
        AmountOfCanceled: "Amount of canceled orders that have been paid but not shipped, amount of returned orders that have been paid (in transit, delivered)",
        RedemptionCode: "ID/Coupon Name/Redemption Code",
        DeleteAll: "Delete",
        AccountIsUnderReview: "The current account is under deactivation review. Continuing to log in will cancel the deactivation process. Do you want to continue using it?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Time cannot be empty",
        ProductImporTemplate: "Product Import Template",
        NormalOrdersExceed: "Normal orders exceed:",
        OrderSautomaticallyClosed: "Unpaid orders will be automatically closed",
        OrdeIsShipped: "After the order is shipped:",
        AutomaticallyComplete: "Automatically complete the order",
        Minutes: "Minutes",
        Days: "Days",
        OrderSettings: "Order Settings",
        ClearCache: "Clear Cache",
        PleaseEnterNavigationContent: "Please enter navigation content",
        WebsiteNavigationConfiguration: "Website Navigation Configuration",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Popup delay time",
        SubscriptionPopup: "Subscription popup",
        MeansNoPopupDelay: "Please enter the pop-up window delay prompt time",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC Zone",

        //V1.5.0 end

        //V1.6.0 start
        SynchronizeEbpPrice: "Synchronize EBP price",
        ConfirmToSynchronizeEbp: "Confirm to synchronize EBP price",
        AsynchronousEbp: "After confirmation, the synchronization is asynchronous. The price will be displayed immediately after it is obtained. Please wait patiently.",
        CopywritingDisplay: "Copywriting display",
        HomepageCopywriting: "Homepage copywriting",
        EditHomepageCopywriting: "Edit homepage copywriting",
        SuperimposedEvents: "Whether to superimposed events?",
        GiftsEvents: "(The gifts for this event cannot be collected together with other events)",
        SmallGiftEvent: "Small gift event",
        SpecialProductEvent: "Special product event",
        SpecialGiftEvent: "Free gift of small gift event and special event cannot be collected together",
        ProductsWithSKU: "Please select products with a SKU",
        LimitWereSelected: "The current limit is {limitNum} files. This time, {selectNum} files were selected.",
        FileUploadFailed: "File upload failed!",
        CompleteMultiLanguage: "Please complete the multi-language configuration",
        FirstSynchronizeSKU: "Please synchronize the SKU for this product first.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Whether to make coupon public",
        RedemptionCode1: "Redemption code",
        NowOnlyNeed: "Now only need €10",
        WebLogReporting: "Web log reporting",
        PageAddress: "Page address",
        ErrorLogs: "Error logs",
        CustomParameter: "Custom Parameter",
        ParameterID: "Parameter ID",
        // ParameterName: "Parameter Name",
        SearchParameter: "Search Parameter",
        EnterParameterName: "Please enter parameter name",
        ParameterType: "Parameter Type",
        AssociatedProductCount: "Associated Product Count",
        MandatoryPortugueseContent: "Mandatory Portuguese Conten",
        MandatoryEnglishContent: "Mandatory English Content",
        MandatoryDutchContent: "Mandatory Dutch Content",
        MandatoryPolishContent: "Mandatory Polish Content",
        MandatoryFrenchContent: "Mandatory French Content",
        MandatoryGermanContent: "Mandatory German Content",
        MandatoryItalianContent: "Mandatory Italian Content",
        MandatorySpanishContent: "Mandatory Spanish Content",
        MandatoryChineseContent: "Mandatory Chinese Content",
        ImportTemplate: "Custom Parameter Product Import Template",
        ConfirmImportFile: "Confirm Import File, Continue?",
        SelectDataRemove: "Select Data to Remove",
        ImportTemplateDownload: "Import Template Download",
        AssociatedProducts: "Associated Products",

        //V1.7.0 end


    }
};
export default en;