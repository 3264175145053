import itLocal from 'element-ui/lib/locale/lang/it';
const it = {
    ...itLocal,
    common: {
        datastatistics: 'Statistiche',
        Transactionstatistics: 'Statistiche sulle transazioni',
        LabelOptions: 'Opzioni di etichetta',
        Orderdatastatistics: 'Statistiche sugli ordini',
        Hourlystatistics: 'Ora',
        Countbyday: 'Giorno',
        Spain: 'Spagna',
        Italy: 'Italia',
        Germany: 'Germania',
        France: 'Francia',
        poland: 'Polonia',
        Netherlands: 'Paesi Bassi',
        Britain: 'Regno Unito',
        UnitedStates: 'Stati Uniti',
        Portugal: 'Portogallo',
        SalesCountry: 'Paese di vendita',
        query: 'Indagine',
        Placinganorder: "Quantità dell'ordine",
        Orderamount: "Importo dell'ordine",
        Numberofusersplacingorders: 'Numero di utenti che effettuano ordini',
        Ordertime: "Tempo dell'ordine",
        time: 'Tempo',
        usermanagement: 'Gestione degli utenti',
        UserID_loginemail_registrationinviterID_phonenumber: 'ID utente/E-mail di accesso/ID invitato registrato/Numero di cellulare',
        country: 'Paese',
        Whethertosubscribe: 'Vuoi iscriverti?',
        Loginstatus: 'Stato di accesso',
        normal: 'Normale',
        Disabled: 'Disattivato',
        Createdon: 'Data di creazione',
        Lastlogintime: "Ora dell'ultimo accesso",
        Reset: 'Ripristina',
        export: 'Esporta',
        UserID: 'ID utente',
        Registeredcountry: 'Paese di registrazione',
        mailbox: 'E-mail',
        Mobilephonenumber: 'Numero di cellulare',
        Patrioticcoin: 'Monete Aigo',
        RegistrationInviterID: 'ID invitato registrato',
        brand: 'Marca',
        Creationtime: 'Tempo di creazione',
        operation: 'Operazione',
        check: 'Visualizzazione',
        orderform: 'Ordini',
        consultingservice: 'Indagine',
        address: 'Indirizzo',
        LoginLog: 'Registro degli accessi',
        Rechargepatrioticcoins: 'Ricarica le monete Aigo',
        Returnsandexchanges: 'Resi e cambi',
        evaluate: 'Valutazione',
        Shippingaddress: 'Indirizzo di consegna',
        shoppingcart: 'Carrello',
        collection: 'Collezione',
        invoice: 'Fattura',
        nickname: 'Soprannome',
        nothing: 'Nessuno',
        close: 'Vicino',
        source: 'Fonte',
        Subscriptiontime: 'Tempo di iscrizione',
        Thirdpartylogin: 'Accesso di terze parti',
        Bound: 'Limite',
        Unbound: 'Non vincolato',
        Invitationcode: 'Codice invito',
        Inviteduser: 'Utenti invitati',
        Invitetoreceivepatrioticcoins: 'Invita per ottenere monete Aigo',
        Isthefirstorderfreeofshipping: 'Spedizione gratuita o meno sul tuo primo ordine',
        shopping: 'Shopping',
        Collectproducts: 'Oggetti preferiti',
        ShoppingCartItems: 'Articoli nel carrello',
        PaymentOrder: 'Ordini pagati',
        Ordercumulativeamount: "Importo totale dell'ordine",
        Lastordertime: "Ora dell'ultimo ordine",
        Returnandexchangeorders: 'Ordini di reso e cambio',
        Consultationfrequency: 'Frequenza di interrogazione',
        Numberofevaluations: 'Numero di recensioni',
        Numberofapprovedevaluations: 'Numero di recensioni approvate',
        ShowCountry: 'Mostra paese',
        name1: 'Nome',
        TaxID: 'Codice fiscale',
        province: 'Provincia',
        city: 'Città',
        Detailedaddress: 'Indirizzo dettagliato',
        Housenumber: 'Numero civico',
        AddressAdditionalContent: "Contenuto aggiuntivo dell'indirizzo",
        RecipientCompany: 'Azienda beneficiaria',
        Defaultshippingaddress: 'Indirizzo di spedizione predefinito',
        Defaultinvoiceaddress: 'Indirizzo di fatturazione predefinito',
        ProductID1: 'Codice prodotto',
        Productname1: 'Nome del prodotto',
        CollectionPrice: 'Prezzo di raccolta',
        Currentprice: 'Prezzo attuale',
        Collectiontime1: 'Tempo di raccolta',
        Additionalpurchaseprice: 'Prezzo degli articoli aggiunti alla lista dei desideri',
        Additionalpurchasetime: 'Tempo degli articoli aggiunti alla lista dei desideri',
        Listingstatus: 'Stato dello scaffale',
        Listed: 'Mettere sullo scaffale',
        Notlisted: 'Non sullo scaffale',
        Numberofpatrioticcoins: 'Numero di monete Aigo',
        Enteraquantitybetween0and1000: 'Inserisci una quantità compresa tra 0 e 1000',
        cancel: 'Annulla',
        UserDetails: 'Dettagli utente',
        Areyousuretoexportthefile: "Desideri confermare l'esportazione del file? Vuoi continuare?",
        prompt: 'Richiesta',
        Exportsuccessful: 'Esportazione completata',
        today: 'Oggi',
        Lastweek: 'Ultima settimana',
        Thismonth: 'Questo mese',
        Lastmonth1: 'Mese scorso',
        thisyear: "Quest'anno",
        Inputcannotbeempty: 'Il campo di input non può essere vuoto',
        Pleaseenterapositiveinteger: 'Inserisci un numero intero positivo',
        Pleaseenterthequantityofpatrioticcoins: 'Inserisci il numero di monete Aigo',
        Pleaseenteracomment: 'Inserisci un commento',
        Pleaseselectacountry: 'Seleziona un paese, per favore',
        Successfullysubmitted: 'Inviato con successo',
        Nopatrioticcoinrulesconfigured: 'Nessuna regola impostata per le monete Aigo',
        Rechargefailed: 'Impossibile effettuare la ricarica',
        Savefailed: 'Salvataggio fallito',
        ordermanagement: 'Gestione ordini',
        OrderList: 'Elenco ordini',
        Ordernumber: 'Numero ordine',
        Paymentcode: 'Codice pagamento',
        barcode: 'Codice a barre',
        Pleaseselectasource: 'Seleziona la fonte, per favore',
        OrderStatus: 'Stato ordine',
        Unpaid: 'Non pagato',
        Paid: 'Pagato',
        Shippable: 'Disponibile per la consegna',
        Shipped: 'Spedito',
        complete: 'Completato',
        Pleaseselectpaymentmethod: 'Seleziona il metodo di pagamento, per favore',
        Shipmentstatus: 'Stato spedizione',
        Notshipped: 'Non spedito',
        Partialshipment: 'Parzialmente spedito',
        Returned: 'Reso',
        Remittanceconfirmation: 'Bonifico confermato',
        Notremitted: 'Nessun bonifico',
        Unconfirmed: 'Non confermato',
        adopt: 'Passaggio',
        Notpassed: 'Non superato',
        Whethertomanuallyship: 'Spedire manualmente?',
        yes: 'SÌ',
        no: 'NO',
        Originalordertype: 'Tipo ordine originale',
        Presaleorder: 'Ordine preimpostato',
        Officialorder: 'Ordine ufficiale',
        Preorderstatus: 'Stato preordine',
        Deposittobepaid: 'Acconto da versare',
        Depositpaid: 'Acconto pagato',
        Finalpaymenttobemade: 'Saldo da versare',
        Finalpaymentpaid: 'Saldo pagato',
        Presalepaymentmethod: 'Modalità di pagamento per il preordine',
        Finaldeposit: "Pagamento finale dell'acconto",
        Fullpayment: 'Pagamento completo',
        Whetherthepresaleorderisconvertedtoformal: 'Preordine ufficiale?',
        Combinedorders: 'Ordine combinato',
        to: 'A',
        Startdate: 'Data inizio',
        Enddate: 'Data fine',
        Paymenttime: 'Tempo pagamento',
        Pleaseenterthesource: 'Inserisci la fonte, per favore',
        platform: 'Piattaforma',
        Positiveconversionsingle: "Trasferisci all'ordine ufficiale",
        Numberofproductmodels: 'Numero articoli',
        activity: 'Attività',
        money: 'Quantità',
        Paymentmethod: 'Modalità di pagamento',
        Deliverytime: 'Tempi spedizione',
        Whethertotransfertoformal: 'Trasferimento a funzionario o no',
        Tobereplied_consulted: 'Da rispondere/consultare',
        detailed: 'Dettagli',
        Manualshipping: 'Consegna manuale',
        Manuallogisticsshipment: 'Consegna logistica manuale',
        logisticscompany: 'Azienda logistica',
        Pleaseselectalogisticscompany: 'Seleziona una società di logistica',
        Logisticstrackingnumber: 'Numero tracciamento logistico',
        SelectDateTime: 'Seleziona data e ora',
        Shippingremarks: 'Note spedizione',
        Pleasenote: 'Si prega di notare che',
        confirm1: 'Conferma',
        state: 'Stato',
        Pleaseselect: 'Seleziona',
        Pleaseselectastatus: 'Seleziona stato',
        Homepagerecommendation: 'Raccomandazione homepage',
        Pleaseenterthelogisticstrackingnumber: 'Si prega di inserire il numero di tracciamento',
        Pleaseselecttheshippingtime: 'Seleziona tempo spedizione',
        edit: 'Modifica',
        Successfullyshippedmanually: 'Consegna manuale completata',
        Remittanceconfirmationsuccessful: 'Conferma pagamento riuscita',
        confirmdeletion: 'Conferma eliminazione',
        Successfullydeleted: 'Eliminazione riuscita',
        Pleaseselectapresaleorder: 'Seleziona ordine preordine',
        Confirmtoconverttheorderintoaformalorder: "Conferma della conversione dell'ordine in ordine ufficiale",
        success: 'Successo',
        OrderData: 'Dati ordine',
        Areyousuretoexportthefileandcontinue: "Vuoi confermare l'esportazione del file? Vuoi continuare?",
        Basicinformationoftheorder: 'Informazioni di base ordine',
        Useremail: 'E-mail utente',
        Documentremarks: "Note sull'ordine",
        combination: 'Combinazione',
        PostalCode: 'Codice postale',
        RecipientCompanyAddressAdditionalContent: 'Destinatario, indirizzo dettagliato',
        Invoiceaddress: 'Indirizzo di fatturazione',
        InvoiceStatus: 'Stato fattura',
        Paymentinformation: 'Informazioni di pagamento',
        Paymentstatus: 'Stato pagamento',
        coupon: 'Buono',
        freight: 'Spedizione',
        CouponName: 'Nome coupon',
        Couponcode: 'Codice coupon',
        Paymentamount: 'Importo pagamento',
        Trackingnumber: 'Numero di tracciamento ordine',
        Manualshippingoperator: 'Operatore spedizione manuale',
        Manualshippingoperationtime: 'Tempo operazione consegna manuale',
        Bankremittance: 'Bonifico bancario',
        Remittancestatus: 'Stato rimessa',
        Remittanceconfirmationoperator: 'Operatore conferma rimessa',
        Remittanceconfirmationoperationtime: 'Tempo conferma bonifico',
        Preorderinformation: 'Informazioni ordine prevendita',
        Converttoformaldocument: 'Trasferimento a ordine ufficiale?',
        Positiveconversionsinglemode: 'Modalità di trasferimento a ordine ufficiale',
        automatic: 'Automatico',
        Manual: 'Manuale',
        Positiveconversionsingletime: 'Tempo trasferimento a ordine ufficiale',
        category1: 'Categoria',
        Subordernumber: 'Numero sottoordine',
        Paymentdeadline: 'Scadenza pagamento',
        Addremarks: 'Aggiungi note',
        ProductInformation: 'Informazioni prodotto',
        Serialnumber: 'Numero di serie',
        name: 'Nome',
        VariantInformation: 'Informazioni variante',
        Pricing_unitprice: 'Prezzi (prezzo unitario)',
        Sellingprice_unitprice: 'Prezzo vendita (prezzo unitario)',
        quantity: 'Quantità',
        Totalprice: 'Prezzo totale',
        MerchandiseSubtotal: 'Subtotale articoli',
        total: 'Totale',
        CancelOrder: 'Annulla ordine',
        Areyousuretocancelthisorder: "Sei sicuro di voler annullare l'ordine?",
        reason: 'Motivo',
        Pleaseselectabrand: 'Seleziona un marchio',
        Pleaseselectacategory: 'Seleziona una categoria',
        Successfullyadded1: 'Aggiunto con successo',
        Addfailed: 'Impossibile aggiungere',
        Whethertosubmitdata: 'Invia dati o meno',
        Canceledsuccessfully: 'Annullato con successo',
        Onlypendingorderscanbeshipped: 'Solo gli ordini in sospeso possono essere spediti',
        Existinglogisticstrackingnumber: "L'ordine ha un numero di tracciamento logistico e non può essere spedito",
        Thereareonlytworemittancestatuses: 'Lo stato di rimessa può essere solo approvato o non approvato',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Solo gli ordini rimessi possono essere confermati',
        Orderdoesnotexist: "L'ordine non esiste",
        Thisordercannotbecancelled: "L'ordine non può essere annullato",
        Norulesconfigured: "L'attuale tenant non ha regole configurate per le monete Aigo",
        Parametererror: 'Errore parametri',
        Countrycodecannotbeempty1: 'Il codice paese non può essere vuoto',
        Thecurrentcountrypricingisnotconfigured: 'I prezzi attuali per il paese non sono configurati',
        Thepriceconfigurationdoesnotexist: 'La configurazione del prezzo non esiste, si prega di configurare il prezzo',
        Reasonforcancellationoforderisrequired: 'Motivo annullamento ordine richiesto',
        Exportorderinformation: 'Esporta informazioni ordine',
        OrderIDmustbepassed: "L'ID ordine deve essere fornito",
        Youcanonlybatchprocessupto100itemsatatime: 'Elaborazione batch fino a 100 ordini per volta',
        Notapresaleorder: "L'ordine numero 101 non è un preordine, deselezionalo prima",
        Transferredtoofficial: "L'ordine numero 101 è stato trasferito a un ordine ufficiale, si prega di deselezionare",
        Ordernotpaid: "L'ordine numero 101 non è un ordine pagato, si prega di annullare la selezione",
        Nonpresaleorderscannotbeoperated: 'Gli ordini non in prevendita non sono disponibili',
        Transferredtoformalformandcannotbeoperated: 'Gli ordini trasferiti non sono disponibili',
        Nonpaidorderscannotbeoperated: 'Non disponibile per ordini non pagati',
        Orderremarksarerequired: "Le note dell'ordine sono obbligatorie",
        Upto500wordscanbeentered: 'Massimo 500 caratteri',
        common: 'Totale',
        strip: 'Record',
        goto: 'Vai a',
        page: 'Pagina',
        Returnandexchangeapplication: 'Richiesta reso',
        type: 'Tipo',
        Return: 'Reso',
        Exchange: 'Scambio',
        Auditstatus: 'Verifica stato',
        Approved: 'Approvato',
        Auditfailed: 'Fallito',
        Inprogress: 'In corso',
        Auditended: 'Fine audit',
        Processingstatus: 'Stato elaborazione',
        Completed: 'Completato',
        Applicationtime: 'Tempo applicazione',
        Aftersalestype: 'Tipo post vendita',
        ApplicationDescription: 'Descrizione richiesta',
        Numberofexplanatoryimages: 'Descrizione numero immagini',
        Illustrativeimage: 'Descrizione immagini',
        Returnlogisticstrackingcode: 'Codice di monitoraggio della logistica di reso',
        CanIcontinuetoapply: "Continuare l'applicazione?",
        Auditing: 'Verifica',
        details: 'Dettagli',
        handle: 'Processi',
        Add1: 'Aggiungi',
        ApplicationDetails: 'Dettagli richiesta',
        Exchangeitem: 'Scambia merce',
        Returnedgoods: 'Restituzione merce',
        Audittime: 'Tempo revisione',
        Reviewedby: 'Recensito da',
        result: 'Risultato',
        Failed: 'Non approvato',
        Thereplycontentwillbedisplayedtotheuser: 'La risposta sarà mostrata agli utenti',
        Submit: 'Invia',
        Replycontent: 'Contenuto risposta',
        Operator: 'Operatori',
        Pleaseenterthereason: 'Inserisci motivo',
        Pleasechoosewhethertocontinueapplying: 'Seleziona se puoi continuare ad applicare',
        Pleaseselectaresult: 'Si prega di selezionare il risultato',
        Successfullymodified: 'Modifica effettuata con successo',
        Verificationfailed: 'Verifica fallita',
        Datadoesnotexist: 'Dati non disponibili',
        OrderNumber_UserID: 'Numero ordine/ID utente',
        ReplyStatus: 'Stato risposta',
        Replied: 'Risposto',
        Noresponse: 'Nessuna risposta',
        MyOrder: 'Mio ordine',
        refund: 'Rimborso',
        Canwedeliverontheagreeddate: 'È possibile consegnare alla data concordata?',
        Modifyshippingaddress: 'Modifica indirizzo spedizione',
        Otherissuesrelatedtotransportation: 'Altre domande sulla spedizione',
        Receivedincorrectproduct: 'Ricevuto prodotto errato',
        Receiveddamagedproductsandproductswithqualityissues: 'Ricevuto prodotti danneggiati o con problemi di qualità',
        RegulationsonReturnsandRefunds: 'Politica resi e rimborsi',
        Applyforinvoice: 'Richiedi fattura',
        productdetails1: 'Dettagli prodotto',
        Thankyouletter: 'Lettera di ringraziamento',
        Otherissues: 'Altre domande',
        Respondent: 'Risposta da',
        Consultationdate: 'Data richiesta',
        content: 'Contenuto',
        explain: 'Descrizione',
        Consultationtime: 'Tempo di consultazione',
        Replytime: 'Tempo di risposta',
        reply: 'Rispondi',
        OrderInformation: 'Informazioni ordine',
        Consultationcontent: 'Contenuto richiesta',
        Consultationinstructions: 'Istruzioni richiesta',
        EnterReply: 'Inserisci risposta',
        Consultationrecords: 'Cronologia indagini',
        Replycontentisrequired: 'Contenuto risposta obbligatorio',
        Replysuccessful: 'Risposta inviata con successo',
        Successfullyedited: 'Modifica effettuata con successo',
        Ordernumber_UserID_Taxnumber: 'Numero ordine/ID utente/Codice fiscale',
        Unaudited: 'Non verificato',
        Passed: 'Approvato',
        Notuploaded: 'Non caricato',
        Uploaded: 'Caricato',
        AuditDescription: 'Istruzioni revisione',
        download: 'Scaricamento',
        Applying: 'Applicazione in corso',
        Enterthereason: 'Inserisci la causale, devi inserire la causale se la domanda non viene accolta',
        Thereasonfornotpassingwillbedisplayedtotheuser: "I motivi del mancato superamento verranno mostrati all'utente",
        Pleaseenterareply: 'Inserisci una risposta',
        Auditsuccessful: 'Recensione effettuata con successo',
        ProductManagement: 'Gestione prodotto',
        Categorymanagement: 'Gestione categorie',
        Categoryname1: 'Nome categoria',
        Isthehomepagedisplayed: 'Visualizzazione homepage',
        Enableornot: 'Abilitare?',
        search: 'Ricerca',
        Directoryhierarchy: 'Livello catalogo',
        Category: 'Categoria',
        sort: 'Ordina per',
        picture: 'Immagine',
        Updatetime: 'Tempo aggiornamento',
        Multilingualconfiguration: 'Configurazione multilingue',
        Relatedimportantparameters: 'Parametri importanti correlati',
        Associatesearchparameters: 'Parametri ricerca associati',
        delete: 'Elimina',
        SelectAll: 'Seleziona tutto',
        Imagesize: 'Dimensione immagine',
        icon: 'Icone',
        intotal25: '25 articoli in totale',
        Gotopage1: 'Vai a pagina 1',
        Chinese: 'Cinese',
        English: 'Inglese',
        German: 'Tedesco',
        Polishlanguage: 'Polacco',
        Italian: 'Italiano',
        French: 'Francese',
        Dutchlanguage: 'Olandese',
        Spanish: 'Spagnolo',
        Pleaseenteraname: 'Inserisci nome',
        Areyousureyouwanttodeleteit: 'Vuoi eliminare?',
        Categoryassociation: 'Associazione categoria',
        PrimaryKeyID: 'ID chiave primaria',
        CatalogID: 'ID catalogo',
        Addtime: 'Aggiungi tempo',
        Relatedcategories: 'Categoria associata',
        Selectmaincategory: 'Seleziona categoria principale',
        addassociations: 'Aggiungi associazione',
        Maincategory: 'Categoria Principale',
        ProductCatalogID: 'ID catalogo prodotti',
        RelatedCategoryName: 'Nome categoria associata',
        AddAssociatedCategory: 'Aggiungi categoria associata',
        Pleaseselectacategoryfirst1: 'Seleziona prima una categoria',
        Productvariants: 'Variante prodotto',
        ID: 'ID',
        Add: 'Aggiungi',
        Productquantity: 'Numero prodotti',
        Selectvariant: 'Seleziona variante',
        Selectparameters: 'Seleziona parametri',
        commodity: 'Prodotto',
        Selectproduct: 'Seleziona prodotto',
        Colortemperature: 'Temperatura colore',
        frequency: 'Frequenza',
        power: 'Energia',
        style: 'Stile',
        MoveDown: 'Scorri verso il basso',
        MoveUp: 'Continua',
        Selectvariantparameters: 'Seleziona parametri variante',
        SKU_EAN_Encoding: 'SKU/EAN/codice',
        Pleaseselectparameters: 'Seleziona parametri',
        Countrieslisted: 'Paesi in cui i prodotti viene messo a scaffale',
        ProductID: 'Codice prodotto',
        Productimage1: 'Immagine prodotto',
        ProductName: 'Nome prodotto',
        VariantManagement: 'Gestione varianti',
        Class: 'Categoria',
        Selected: 'Selezionato',
        Thereiscurrentlynodataavailable: 'Nessun dato disponibile',
        Variantparameters: 'Parametri variante',
        VariantName: 'Nome variante',
        CustomName: 'Nome personalizzato',
        Batchlisting: 'Lotto messo sullo scaffale',
        Pleaseselectaproduct: 'Seleziona prodotti',
        Batchdelisting: 'Disattiva in blocco',
        Batchsearch: 'Ricerca in blocco',
        Enterproductbarcodeandsubmitforaddition: 'Inserisci il codice a barre del prodotto per aggiunte, più codici a barre devono essere inseriti su nuove righe',
        Enterproductbarcode: 'Inserisci i codici a barre del prodotto, più codici a barre devono essere inseriti su nuove righe fino a 500',
        Pleaseenterbarcode: 'Inserisci il codice a barre',
        Editlistingcountry: 'Modifica paesi in cui il prodotto viene messo sullo scaffale',
        Pleasecompletetheproductinformationbeforelistingit: 'Completa le informazioni sul prodotto prima di metterlo sullo scaffale',
        ProductTitle: 'Titolo del prodotto',
        ProductLargePicture: 'Immagine del prodotto',
        commodityprice: 'Prezzo del prodotto',
        Confirmtheexecutionofbatchdelisting: 'Confermare per eseguire la disattivazione in blocco',
        EditCategory: 'Modifica categoria',
        SynchronizeSKU: 'Sincronizza SKU',
        ConfirmsynchronizationofSKU: 'Conferma sincronizzazione SKU',
        SynchronizationrequiresdockingwithEBP: 'La sincronizzazione con EBP richiede tempo e viene eseguita in modo asincrono ogni 30 minuti. La sincronizzazione avverrà non appena i dati saranno disponibili.',
        Synccompleted: 'Sincronizzazione completata',
        Setlabel: 'Imposta tag',
        label: 'Tag',
        SynchronizeEBPproductinformation: 'Sincronizzazione informazioni prodotto EBP',
        Afterturningonsynchronization: 'La sincronizzazione abilita le seguenti informazioni per i prodotti EBP in base allo SKU',
        Synchronizeproducttitlesbylanguage: 'Titolo prodotto: sincronizza il titolo nella lingua corrispondente',
        Synchronizeproductimagesbylanguage: "Immagine prodotto: sincronizza l'immagine nella lingua corrispondente, la prima immagine viene impostata come immagine principale",
        Synchronizeproductimagesandtextbylanguage: 'Dettagli immagine: sincronizza i dettagli nella lingua corrispondente',
        Productkeywords: 'Parole chiave prodotto',
        Automaticallysynchronizeornot: 'Sincronizza automaticamente',
        Doyouwanttoforcesynchronization: 'Forza sincronizzazione',
        tenant: 'Inquilini',
        Noinventorydisplay: 'Inventario non visualizzabile',
        Synchronizetoothertenants: 'Sincronizza con altri inquilini',
        UpdateRecords: 'Aggiorna record',
        Importtime: 'Tempo importazione',
        number: 'Numero',
        file: 'File',
        UpdateResults: 'Aggiorna risultato',
        Downloadfiles: 'Scarica file',
        Isthereafixedprice: 'Prezzo fisso?',
        Whethertopromoteornot: 'In vendita o meno',
        Inpromotion: 'In vendita',
        whole: 'Tutto',
        Fixedprice1: 'Prezzo fisso',
        Lowestprice: 'Prezzo più basso',
        fixedprice: 'Prezzo fisso',
        Promotionprice_unitprice: 'Prezzo promozionale (prezzo unitario)',
        price1: 'Prezzo di vendita',
        inventory: 'Inventario',
        Inventorybottomline: 'Quantità disponibile',
        AutomaticallysynchronizeEBP: 'Sincronizza automaticamente EBP',
        ForcesynchronizationofEBP: 'Forza sincronizzazione EBP',
        Presaleornot: 'Preordine o meno',
        Evaluation_Shown_total: 'Recensioni (visualizzate/totali)',
        coding: 'Codice',
        ProductDetails: 'Dettagli prodotto',
        EditProduct: 'Modifica prodotto',
        Basicinformation: 'Informazioni base',
        price: 'Prezzo',
        Pleaseentertheproducttitle: 'Inserisci titolo prodotto',
        Sizeinformation: 'Informazioni dimensioni',
        weight: 'Peso',
        volume: 'Volume',
        Salesmultiples: 'Moltiplicatore vendite',
        Numberofitems: 'Numero articoli',
        EditFixedPrice: 'Modifica prezzo fisso',
        Largeimage: 'Immagine grande',
        Uploadimages: 'Carica foto',
        UploadVideo: 'Carica video',
        Batchdisplay: 'Visualizzazione lotti',
        BatchCloseDisplay: 'Chiusura batch',
        Batchdeletion: 'Elimina in blocco',
        Synchronizetootherlanguages: 'Sincronizza con altre lingue',
        Selectfrompicturelibrary: 'Seleziona dalla galleria di immagini',
        Pleaseenterthemaintext: 'Inserisci il corpo del testo',
        Hideemptyparameters: 'Nascondi i parametri vuoti',
        Detailedcontentofgraphicsandtext: 'Inserisci prima i dettagli grafici in inglese',
        parameter: 'Parametro',
        Parametervalue: 'Valore parametro',
        ParameterProperties: 'Proprietà parametro',
        Pleaseenteraparametername: 'Inserisci nome parametro',
        Pagekeywords: 'Parola chiave pagina',
        PageDescription: 'Descrizione pagina',
        Pleaseentercontent: 'Inserisci contenuto',
        PictureLanguage: 'Lingua immagini',
        Pictures_Videos: 'Immagine/Video',
        ImageID: 'ID immagine',
        language: 'Lingua',
        size: 'Unità di misura',
        Selectapictureorvideo: 'Seleziona prima immagine o video dalla galleria!',
        Selectionsuccessful: 'Selezione effettuata con successo',
        Isitthemainimage: 'Immagine principale o meno',
        Displayornot: 'Mostra?',
        Whethertodisplayall: 'Mostra tutto?',
        Areallclosed: 'Chiudi tutto?',
        Confirmdeletionoftheselectedpicture_video: 'Conferma eliminazione immagine/video selezionato',
        ProductReview: 'Commenti prodotto',
        PromotionName_ProductID_Barcode: 'Nome promozione/ID prodotto/codice a barre',
        PromotionCountry: 'Paese promozione',
        Activitystatus: 'Stato evento',
        begininaminute: 'Sta per cominciare',
        Ended: 'Concluso',
        Isitaflashkill: 'Prodotto in promo flash?',
        PromotionName: 'Nome promozione',
        CommentID: 'ID commento',
        RatingStarRating: 'Valutazione stelle',
        star0: 'stella',
        star1: '1 stella',
        stars2: '2 stelle',
        stars3: '3 stelle',
        stars4: '4 stelle',
        stars5: '5 stelle',
        Commentstartdate: 'Data inizio commenti',
        CommentEndDate: 'Data fine commenti',
        Batchreview: 'Revisione lotto',
        Productimage: 'Immagini prodotto',
        Evaluationcontent: 'Contenuto commento',
        video: 'Video',
        ReviewerId: 'ID revisore',
        user: 'Utente',
        Pendingreview: 'Da rivedere',
        Evaluationtime: 'Orario del commento',
        ReviewerName: 'Nome del revisore',
        Numberofselectedcomments: 'Numero recensioni selezionate',
        Picturepreview: 'Anteprima immagine',
        VideoPreview: 'Anteprima video',
        Lastmonth: 'Scorso mese',
        LastThreeMonths: 'Ultimi tre mesi',
        Areyousuretodelete: "Confermare l'eliminazione?",
        Pleaseselectacomment: 'Seleziona recensione',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'Rete di marca Aigostar',
        marketingmanagement: 'Gestione marketing',
        Productrecommendations: 'Raccomandazione prodotto',
        Recommendedlocation: 'Posizione raccomandata',
        Categoryrecommendation: 'Raccomandazione categoria',
        Recommendationmodule: 'Modulo consigliato',
        NewProductZone: 'Nuovi arrivi',
        HotSellingZone: 'Prodotti caldi',
        Recommendedforyou1: 'Raccomandato per te',
        Numberofproducts: 'Numero prodotti',
        Addproduct: 'Aggiungi prodotto',
        DirectoryName: 'Nome catalogo',
        title: 'Titolo',
        ContentTitle: 'Titolo contenuto',
        EnablePlatform: 'Abilita piattaforma',
        DisplayPlatform: 'Piattaforma espositiva',
        catalogue: 'Catalogo',
        JumpAddress: "Vai all'indirizzo",
        PageTitle: 'Titolo della pagina',
        CategoryName: 'Nome della categoria',
        Cancelleddeletion: 'Cancellazione annullata',
        Pleaseselectacategoryfirst: 'Seleziona prima una categoria',
        TheEnglishcategorynamecannotbeempty: 'Il nome della categoria inglese non può essere vuoto',
        Successfullysaved: 'Salvataggio effettuato con successo',
        DeleteError: 'Errore cancellazione',
        Apieceofcontentalreadyexists: 'Contenuto già esistente',
        Pleaseselecttherecommendedlocation: 'Seleziona posizione raccomandata',
        Pleaseselecttherecommendedmodule: 'Seleziona modulo raccomandazione',
        remove: 'Rimuovi',
        Successfullyadded2: 'Aggiunto con successo',
        Areyousuretoremovethisproduct: 'Sei sicuro di rimuovere questo prodotto?',
        Successfullyremoved: 'Rimosso con successo',
        Dataalreadyexists: 'Dati già esistenti',
        PortfolioManagement: 'Gestione portafoglio',
        Combinationname: 'Nome combinazione',
        Remainingquantity_combinedquantity: 'Quantità rimanente/Quantità combinata rimanente',
        starttime: 'Tempo inizio',
        Endtime: 'Ora fine',
        Creator: 'Creato da',
        NationalSDPDictionary: 'Dizionario nazionale SDP',
        Combinationtime: 'Tempo combinazione',
        Combinationquantity: 'Quantità combinazioni',
        CanIusecoupons: 'I Coupon possono essere utilizzati?',
        Mainimage: 'Immagine principale',
        Setcombinationdiscount: 'Imposta lo sconto combinato',
        Combinationprice: 'Prezzo combinato',
        Combinedtotalprice: 'Prezzo totale combinazione',
        Isitontheshelf: 'È sullo scaffale',
        Selectupto5: 'Seleziona fino a 5',
        Promotionprice1: 'Prezzo promozionale',
        Pleaseenterthecombinationquantity: 'Inserisci il numero di combinazioni',
        Pleaseenteracombinationname: 'Inserisci un nome di combinazione',
        Upto100wordscanbeentered: 'Inserisci fino a 100 caratteri',
        Pleaseselectadaterange: 'Seleziona intervallo date',
        Pleaseselectastartdate: 'Seleziona una data di inizio',
        Pleaseselecttheenddate: 'Seleziona una data di fine',
        Pleasechoosewhethertousecoupons: 'Seleziona se utilizzare il coupon',
        Pleaseselect25items: 'Si prega di selezionare 2-5 elementi',
        Pleaseselectaproductthathasbeenlisted: 'Seleziona un articolo che è stato messo sugli scaffali',
        Inventoryofselecteditems: 'Moltiplicatore stock/vendite articolo selezionato non può essere inferiore quantità attività',
        Combinationpricecannotbeempty: 'Il prezzo della combinazione non può essere vuoto',
        Caninput0andpositiveintegers: 'È possibile inserire 0 e numeri interi positivi, i simboli possono essere inseriti solo come % e . ,Fino a due cifre decimali',
        Presaleproductscannotbeadded: 'Articolo in prevendita, non può essere aggiunto come combo',
        Pleaseselectaproductfirst: 'Seleziona prima un articolo',
        Freeshippingonfirstorder: 'Spedizione gratuita sul tuo primo ordine',
        Firstfreeshippingitem: 'Spedizione gratuita sul primo ordine',
        SelectedProduct: 'Prodotto selezionato',
        Between1and50charactersinlength: 'Lunghezza compresa tra 1 e 50 caratteri',
        Thisoperationwillremovethefile: 'Questa operazione rimuoverà il file, vuoi continuare?',
        Brandandcountrycannotbeempty: 'Marca e paese non possono essere vuoti',
        Pleaseselectatime: 'Si prega di selezionare un orario',
        Editedsuccessfully: 'Modifica riuscita!',
        Shoppingrebate: 'Sconti sugli acquisti',
        Rebateratio: 'Percentuale di sconto',
        Rebatestarttime: 'ora di decorrenza del rimborso',
        Rebateendtime: 'ora di fine del rimborso',
        Rebatetime: 'Tempo sconto',
        doyouwanttodelete2: 'Cancellare?',
        ActivityTopic: "Argomento dell'evento",
        ActivityName: "Nome dell'evento",
        Whethertodisplayonthehomepage: 'Visualizzare in prima pagina?',
        QuerySearch: 'Ricerca',
        TopicName: "Nome dell'argomento",
        Activitytime: "Orario dell'evento",
        Richtextornot: 'Testo ricco?',
        ActivityDescription: "Descrizione dell'evento",
        SpecialSEO: 'Tematica SEO',
        LogisticsPricing: 'Prezzi logistica',
        Basicfreight: 'Tariffe spedizione base',
        Freeshippingprice: 'Spedizione gratuita',
        Deliverytimeprompt: 'Tempo di spedizione Suggerimenti',
        Receivingtimeprompt: 'Richiesta di tempo di ricezione',
        PricingSettings: 'Configurazione prezzi',
        Freightstandard: 'Norma spedizione',
        Freightallocation: 'Configurazione spedizione',
        FreightType: 'Tipo spedizione',
        Freeshipping: 'Spedizione gratuita',
        Configureshippingcosts: 'Configura costi spedizione',
        Fullreductionoffreight: 'Rimborso carico',
        Orderamountfull: 'Importo ordine raggiunge',
        Receivingandshippingreminder: 'Promemoria di ricezione e spedizione',
        link: 'Collegamento',
        confirm: 'Conferma',
        Cannotbe0: 'Non può essere 0',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Fino due cifre decimali',
        Pleaseinputthenumbercorrectly: 'Inserisci numero corretto',
        Pleaseenterthecountry: 'Inserisci paese',
        Pleaseselectshippingcost: 'Si prega di selezionare il costo di spedizione',
        Pleasecompletetheshippingcostconfiguration: 'Si prega di completare la configurazione della spedizione',
        Pleaseenteradeliveryprompt: 'Inserisci promemoria ricevuta',
        Pleaseenteralink: 'Inserisci collegamento',
        Addlogisticsconfiguration: 'Aggiungi configurazione logistica',
        Editlogisticsconfiguration: 'Modifica configurazione logistica',
        Pleaseenterthelogisticscompany: 'Inserisci società logistica',
        Editingfailed: 'Modifica fallita',
        Addlogisticscompany: 'Aggiungi società logistica',
        Pleaseuploadtheicon: 'Carica icona',
        Pleaseenterthelinkcorrectly: 'Inserisci correttamente collegamento',
        Successfullyadded: 'Aggiunto con successo',
        Editlogisticscompany: 'Modifica società logistica',
        Thisisalreadythefirstitemmoveupfailed: "Primo elemento, spostamento verso l'alto non riuscito",
        Thisisalreadythelastitemmovedownfailed: 'Ultimo elemento, spostamento verso il basso non riuscito',
        Costprice: 'Prezzo costo',
        taxrate: 'Aliquota fiscale',
        commission: 'Commissioni',
        Grossmargin: 'Margine lordo',
        doyouwanttodelete: 'Numero troppo grande',
        Pleaseselectapricetype: 'Seleziona tipo prezzo',
        Pleasecompletethetaxrateconfiguration: 'Completa configurazione aliquota fiscale',
        Pleasecompletethecommissionconfiguration: 'Completa configurazione commissioni',
        PleasecompleteGrossmarginconfiguration: 'Completa configurazione tariffa lorda',
        Weightrange_start_kg: 'Gamma peso (inizio) kg',
        Weightrange_end_kg: 'Gamma peso (fine) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'È possibile inserire un massimo di quattro cifre decimali',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'Valore finale deve essere maggiore o uguale valore iniziale',
        Pleasecompletetheweightrangeconfiguration: 'Completa configurazione intervallo peso',
        Pleaseentertheshippingcost: 'Si prega di inserire il costo di spedizione',
        Addshippingstandard: 'Aggiungi standard spedizione',
        EditFreightStandard: 'Modifica lo standard di spedizione',
        Allocationofpatrioticcoins: 'Configurazione monete Aigo',
        RuleConfiguration: 'Configurazione regole',
        ObtainingPatrioticCoinObject: 'Oggetti per ottenere monete Aigo',
        Rewardconditionsobtained: 'Condizioni per ottenere premi',
        Obtainpatrioticcoinallocation: 'Ottieni la configurazione delle monete Aigo',
        Inputquantity: 'Inserisci la quantità',
        EnterName: 'Inserisci il nome',
        Registrationsuccessfulnot: 'Registrazione effettuata con successo (senza codice di invito)',
        Inviteduserplacesanorder: 'Utente invitato che effettua un ordine',
        Registrationsuccessful: 'Registrazione avvenuta con successo (con codice di invito)',
        Registeredaccount: 'Account registrato (paese registrato)',
        Registrationsuccessfulnot1: "Registrazione dell'account riuscita senza codice di invito",
        Numberoflocalcurrencies: 'Le quantità configurate di seguito sono nella valuta nazionale del paese corrispondente',
        Inviteraccount: "Account dell'invitante (fornisci l'account del codice di invito, paese con codice di invito)",
        Provideinvitationcode: 'Fornisci il codice di invito ad altri utenti, gli altri utenti utilizzano il codice di invito per registrarsi e completare un ordine (ogni utente invitato può ottenere solo 1 bonus)',
        Usinganinvitationcode: "Registrazione dell'account con successo utilizzando il codice di invito",
        ProductPromotion: 'Promozioni sui prodotti',
        discount: 'Sconti',
        Freeshippingornot: 'Spedizione gratuita o meno',
        Secondkillsorting: 'Ordinamento sec-killing',
        surplus: 'Residuo',
        Totalnumber: 'Totale',
        ImportFile: 'Importa file',
        Selectupto50: 'Seleziona fino a 50',
        Setuppromotions: 'Imposta promozione',
        Nocountrieslisted: 'Nessun paese in cui i prodotti vengono messi a scaffale',
        Promotiontime: 'Tempo promozione',
        Displaypromotionalprices: 'Visualizzare prezzo promozionale?',
        PromotionPrice: 'Prezzo promozionale',
        Promotionquantity: 'Quantità promozionale',
        Doyousupporttheuseofcoupons: "Supporta l'uso di coupon",
        Purchaserestrictionsforthesameuser: 'Restrizioni di acquisto per lo stesso utente',
        Singleorderlimit: 'Limitato a un solo ordine',
        CurrentactivitypricesinSpain: "Margine lordo sul prezzo corrente dell'attività in {countryCode}:",
        SubmitConfirmation: 'Invia conferma',
        Productdistribution: 'Distribuzione prodotto',
        DistributionName: 'Nome della distribuzione',
        DistributionCountry: 'Paese di distribuzione',
        Distributionprice1: 'Prezzo di distribuzione',
        Distributiontime: 'Tempo di distribuzione',
        DistributionPrice: 'Prezzo di distribuzione',
        Distributionquantity: 'Quantità di distribuzione',
        CopyConnection: 'Copia link',
        Successfullycopied: 'Copia riuscita',
        Countryofuse: 'Paese utilizzo',
        Discounttype: 'Tipo offerta',
        Fullreduction: 'Rimborso',
        Isthereafixedcode: 'Codice fisso presente?',
        Effective: 'Valido',
        Comingsoon1: 'Valido a breve',
        Expired: 'Scaduto',
        Overlayornot: 'Impilabile o meno',
        Istheexchangecodefixed: 'Correggere codice di riscatto?',
        Received: 'Ricevuto',
        Used: 'Utilizzato',
        Availableamount: 'Importo disponibile',
        Maximumdeductionamount: 'Importo massimo detrazione',
        Canitbestacked: 'Impilabile o meno',
        Redemptioncode: 'Codice riscatto',
        Fullreductionamount: 'Importo rimborso',
        Enterredemptioncode: 'Inserisci codice riscatto',
        Numberofredemptioncodes: 'Numero codici',
        Numberofusesbythesameuser: 'Numero utilizzi per stesso utente',
        Effectivetime: 'Tempo valido',
        Isitpossibletooverlaydistribution: 'Può essere impilato con o senza distribuzione',
        Canpromotionsbestacked: 'Può essere impilato con o senza promozione',
        Entercoupondescription: 'Inserisci istruzioni per il buono',
        Pleaseenteradescription: 'Inserisci una descrizione',
        Pleaseselectadate: 'Si prega di selezionare una data',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Inserisci il numero di utilizzi per lo stesso utente',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Inserisci importo disponibile come 0 per indicare illimitato',
        Pleaseentertheavailableamount: 'Inserisci importo disponibile',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Numero codici di riscatto può essere solo maggiore di quello originale',
        Pleaseenterthenumberofredemptioncodes: 'Inserisci numero codici di riscatto',
        Applicableclassification: 'Categorie applicabili',
        Applicableproducts: 'Prodotti applicabili',
        Useordernumber: "Usa numero d'ordine",
        Claimstatus: 'Stato raccolta',
        Notclaimed: 'Non raccolto',
        notused: 'Non utilizzato',
        Usagestatus: "Stato d'uso",
        Collectiontime: 'Tempo di raccolta',
        Usagetime: 'Tempo di utilizzo',
        UsingUsers: 'Utenti utilizzatori',
        BannerManagement: 'Gestione dei banner',
        position: 'Posizione',
        JumpType: 'Tipo di salto',
        Nojump: 'Nessun salto',
        Homepagerecommendationposition: 'Home page in primo piano',
        Homebanner: 'Banner domestico',
        Displaytime: "Mostra l'ora",
        JumpLink: 'Collegamento',
        Suggestedsize: 'Dimensione suggerita',
        Enterjumplink: 'Inserisci il link di collegamento',
        Uploadingimagesonlysupports: 'Carica solo immagini in formato jpg, png, gif, jpeg',
        Topwithlargenumbers: 'Il valore predefinito è 0, maggiore il numero, maggiore la classifica',
        Pleaseselectatleastonecountry: 'Seleziona almeno un paese',
        Boutiquerecommendationspace: 'Spazio raccomandazione boutique',
        Fullproductrecommendation: 'Raccomandazione completa prodotti',
        popupnotification: 'Finestra pop-up',
        Addbanner: 'Aggiungi banner',
        subscriptionmanagement: 'Gestione abbonamenti',
        Emailaddress: 'Indirizzo email',
        Subscribing: 'Iscrizione in corso',
        Canceled: 'Annullato',
        SubscriptionSource: "Origine dell'iscrizione",
        Unsubscribe: "Annulla l'iscrizione",
        Resubscribe: 'Iscriviti di nuovo',
        Pleaseselectatimerange: 'Seleziona un intervallo di tempo',
        WebsiteManagement: 'Amministrazione del sito',
        informationservice: 'Servizi informativi',
        New: 'Crea',
        Isitdisplayedatthebottomofthewebsite: 'Mostra nella parte inferiore del sito web',
        Pleaseselectaninformationservice: 'Si prega di selezionare il servizio informativo',
        deleteconfirmation: "Conferma l'eliminazione del nodo e di tutte le informazioni correlate",
        Pleaseenteracolumnname: 'Inserisci un nome per la colonna',
        Thelengthcannotexceed50characters: 'La lunghezza non può superare i 50 caratteri',
        ThelengthcannotexceedXcharacters: 'La lunghezza non può superare i {num} caratteri',
        Pleaseentersorting: "Inserisci l'ordine",
        Selectedcountrycontentisrequired: 'Il contenuto del paese selezionato è obbligatorio',
        TitleName: 'Titolo della pagina',
        Chinesetitleisrequired: 'Il titolo in cinese è obbligatorio',
        Selectedcountrytitleisrequired: 'Il titolo del paese selezionato è obbligatorio',
        EditingInformationServices: 'Modifica servizio informativo',
        ColumnName: 'Nome della colonna',
        Pleaseenterthecreationtime: "Inserisci l'orario di creazione",
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Indica se viene visualizzato nella parte inferiore del sito web',
        ViewInformationServices: 'Visualizza il servizio informativo',
        Modificationtime: 'Orario di modifica',
        Countrycannotbeempty: 'Il campo Paese non può essere vuoto',
        Titlecannotbeempty: 'Il titolo non può essere vuoto',
        Richtextcontentonthepagecannotbeempty: 'Il contenuto RTF della pagina non può essere vuoto',
        Nationalconfiguration: 'Configurazione del paese',
        currency: 'Valuta',
        allocation: 'Configurazione',
        Statuseditedsuccessfully: 'Stato modificato con successo',
        Areyousuretocontinuedeletingdata: 'Sei sicuro di voler eliminare i dati?',
        WebsiteAddress: 'Indirizzo web',
        Contactinformation: 'Informazioni di contatto',
        Customerserviceemail: 'Indirizzo e-mail del servizio clienti',
        Socialmedia: 'Social media',
        Socialmediainformation: 'Informazioni sui social media',
        Pleasechooseapaymentmethod: 'Seleziona un metodo di pagamento',
        Lengthgreaterthan20characters: 'La lunghezza supera i 20 caratteri',
        Lengthgreaterthan100characters: 'La lunghezza supera i 100 caratteri',
        Successfullydeletedinformation: 'Messaggio eliminato con successo',
        Iconmustbeuploaded: "L'icona deve essere caricata",
        Successfullyaddedinformation: 'Messaggio aggiunto con successo',
        Successfullyeditedinformation: 'Messaggio modificato con successo',
        Pleaseentertheemailcorrectly: 'Inserisci correttamente la tua email',
        Thesizeoftheuploadedimagecannotexceed: "La dimensione dell'immagine caricata non deve superare",
        Countryname: 'Nome del paese',
        Pleaseuploadpictures: "Carica un'immagine",
        NewCountryInformation: 'Crea nuove informazioni sul paese',
        EditCountryInformation: 'Modifica informazioni sul paese',
        Picturesmustbetransmitted: 'Le immagini devono essere caricate',
        NewCountryInformationTableImageAddress: 'Crea nuova scheda informativa del paese, immagine e indirizzo',
        Operationsuccessful: 'Operazione riuscita',
        Euro: 'Euro',
        pound: 'Sterlina',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'Il marchio non può essere vuoto',
        Thewebsiteaddresscannotbeempty: "L'indirizzo del sito web non può essere vuoto",
        Contactinformationcannotbeempty: 'Le informazioni di contatto non possono essere vuote',
        Thecustomerserviceemailaddresscannotbeempty: "L'indirizzo e-mail del servizio clienti non può essere vuoto",
        Medianamecannotbeempty: 'Il nome del supporto non può essere vuoto',
        Themediaiconcannotbeempty: "L'icona multimediale non può essere vuota",
        Medialinkcannotbeempty: 'Il collegamento multimediale non può essere vuoto',
        Thecountryiconcannotbeempty: "L'icona del paese non può essere vuota",
        Languagecannotbeempty: 'La lingua non può essere vuota',
        Currencycannotbeempty: 'La valuta non può essere vuota',
        Pagedescriptioncannotbeempty: 'La descrizione della pagina non può essere vuota',
        Statusenable_disablecannotbeempty: 'Stato Abilita/Disabilita non può essere vuoto',
        SEOManagement: 'Gestione SEO',
        Import: 'Importa',
        DownloadImportTemplate: 'Scarica il modello di importazione',
        PageURL: 'URL della pagina',
        SEOdata: 'Dati SEO',
        SEOConfigurationTemplate: 'Modello di configurazione SEO',
        Downloadsuccessful: 'Download completato',
        SEOManagementImport: 'Gestione SEO importata',
        DragtheExcelfilehere_orclickonupload: 'Trascina e rilascia qui il file Excel o fai clic su Carica',
        Fileimportedsuccessfully: 'File importato con successo',
        Fileimportfailed_pleasecheckthefilecontent: 'Importazione del file non riuscita, controllare il contenuto del file',
        Pleaseenterthelanguage: 'Inserisci la lingua',
        Thelengthcannotexceed2characters: 'La lunghezza non può superare i 2 caratteri',
        PleaseenterthepageURL: "Inserisci l'URL della pagina",
        Thelengthcannotexceed100characters: 'La lunghezza non può superare i 100 caratteri',
        Thelengthcannotexceed20characters: 'La lunghezza non può superare i 20 caratteri',
        No_Illegalrowdata: 'La riga ? dei dati è illegale',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Caricamento del file non riuscito, controllare il contenuto del file',
        Namecannotbeempty: 'Il nome non può essere vuoto',
        PageURLcannotbeempty: "L'URL della pagina non può essere vuoto",
        Thepagetitlecannotbeempty: 'Il titolo della pagina non può essere vuoto',
        SEOManagementImportTemplate: 'Modello di importazione per la gestione SEO',
        Donotmodify: 'Non modificare le prime tre righe del titolo del modello',
        Required: 'I campi contrassegnati con * sono obbligatori',
        Brandandlanguagemustbefullycapitalized: 'Marchio e lingua devono essere scritti in maiuscolo',
        SEOManagementExport: 'Esportazione della gestione SEO',
        Pagekeywordscannotbeempty: 'Le parole chiave della pagina non possono essere vuote',
        Prohibitedsalesarea: 'Zone di vendita vietate',
        Pleaseenterthepostalcodecorrectly: 'Inserisci correttamente il tuo codice postale',
        Pleasefillinthepostalcode: 'Inserisci il tuo codice postale',
        Countrycodecannotbeempty: 'Il codice del paese non può essere vuoto',
        Postalcodecannotbeempty: 'Il codice postale non può essere vuoto',
        Postalcodealreadyexists: 'Il codice postale esiste già',
        Homepagearticle: 'Articoli nella homepage',
        BrandStory: 'Storie del marchio',
        SciencePopularizationZone: 'Zona di divulgazione scientifica',
        Lightstripzone: 'Striscia luminosa',
        Pictureandtext: 'Immagine e testo',
        Comingsoon: 'Prossima uscita',
        Publishinginprogress: 'Rilascio in corso',
        column: 'Colonna',
        Releasetime: 'Data di rilascio',
        Pleaseenteratitle: 'Inserisci un titolo',
        Pleaseselectacolumn: 'Seleziona una colonna',
        Pleaseselectatype: 'Seleziona il tipo',
        Patrioticcoinusagelog: 'Registro di utilizzo delle monete Aigo',
        income: 'Entrate',
        expenditure: 'Spese',
        mode: 'Metodo',
        Orderexpenses: "Spesa dell'ordine",
        Orderrewards: "Ricompense dell'ordine",
        OrderCloseReturn: "Chiusura dell'ordine e reso",
        other: 'Altro',
        Backendrecharge: 'Ricarica del saldo',
        Orderrebate: "Sconto dell'ordine",
        Numberofchangesinpatrioticcoins: 'Numero di monete Aigo cambiato',
        Numberofpatrioticcoinsafterchanges: 'Numero di monete Aigo dopo il cambio',
        CountryCode: 'Prefisso internazionale',
        Changetime: 'Tempo di cambio',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: "Seleziona la cronologia di consumo delle monete Aigo dell'utente",
        WebsiteNavigation: 'Navigazione del sito',
        HomepageConfiguration: 'Configurazione della pagina iniziale',
        Navigationcontent: 'Contenuti di navigazione',
        Recommendedforyou: 'Raccomandato per te',
        SelectNavigation: 'Seleziona la navigazione',
        Pleasecheckfirst: 'Seleziona prima la casella di spunta',
        Logmanagement: 'Gestione dei registri',
        UserID_IP_LoginDevice: 'ID utente/IP/dispositivo di accesso',
        logintime: 'Orario di accesso',
        Loginmethod: 'Metodo di accesso',
        IPaddress: 'Indirizzo IP',
        Logindevice: 'Dispositivo di accesso',

        //11-22
        AssociatedOrderNumber: "Numero d'ordine associato",
        recordId: 'Registro ID',
        UserAccess: 'Accesso utente',
        PleaseSelectTheStatisticalType: 'Seleziona il tipo di statistica',
        StatisticalByDate: 'Statistiche per data',
        StatisticalBySource: 'Statistiche per fonte',
        Date: 'Data',
        Visits: 'Visite',
        NewVisitors: 'Nuovi visitatori',
        OrderStatistics: 'Statistiche sugli ordini',
        Sales: 'Vendite',
        TotalOrders: 'Ordini totali',
        AverageOrderAmount: "Importo medio dell'ordine",
        SynchronizeEBP: 'Sincronizza EBP',
        ParameterName: 'Nome del parametro',
        OnTheShelf: 'Sullo scaffale',
        OfflineShoppingCart: 'Carrello offline',
        AddToCart: 'Aggiungi al carrello',
        EnterCheckout: 'Procedere al checkout',
        UserLogin: 'Login utente',
        UserRegistration: 'Registrazione utente',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: "Numeri IP diversi per l'accesso alla pagina di conferma dell'ordine",
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Numeri IP diversi per aggiungere articoli al carrello',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Numeri IP diversi per chi ha completato il processo di accesso dopo essere stato reindirizzato dal carrello offline alla pagina di accesso.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Numeri IP diversi per chi ha completato il processo di registrazione dopo essere stato reindirizzato dal carrello offline alla pagina di registrazione.',
        UserBehavior: "Comportamento dell'utente",
        Display: 'Mostra',
        DoNotDisplay: 'Nascondi',
        AddToPresale: 'Aggiungi alla prevendita',
        CancelPresale: 'Annulla la prevendita',
        PresaleConfiguration: 'Configura la prevendita',
        OffTheShelf: 'Sullo scaffale',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Si prega di compilare le informazioni sul prodotto prima di impostare la prevendita.',
        SynchronizedEBPPrompts: 'A causa della necessità di sincronizzazione con EBP, la sincronizzazione in batch può essere eseguita solo una volta entro X minuti per evitare errori. Dopo la conferma, la sincronizzazione viene eseguita in modo asincrono e il barcode viene visualizzato immediatamente dopo il completamento. Si prega di attendere.',
        PleaseWait: 'Attendere prego',
        PleaseSelectATag: 'Scegliere un tag',
        Updating: 'In aggiornamento',
        Abortive: 'Non riuscito',
        DefaultRule: 'Regola predefinita',
        BottomInventory: 'Inventario inferiore',
        SynchronisedAlertsForGraphicDetails: 'Dettagli grafici: sincronizza le parole chiave del prodotto nella lingua corrispondente',
        SynchronisedAlertsForLargeProductImages: "Immagine del prodotto: sincronizza l'immagine nella lingua corrispondente, seguendo l'ordinamento delle immagini EBP e imposta automaticamente la prima immagine EBP come immagine principale",
        Account: 'Account',
        Account1: 'Account',
        EnterUpToNumItems: 'Inserisci fino a {num} articoli',
        SynchronizedSuccessfully: 'Sincronizzato con successo',
        SuccessfullyListed: 'Elencato con successo',
        SearchSuccessful: 'Ricerca riuscita',
        EnterNewPassword: 'Inserisci una nuova password',
        OnlyNumbersAndLettersAreAllowed: 'Sono consentiti solo numeri e lettere',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: "Completa la configurazione della prevendita prima di elencare l'articolo in prevendita.",
        ProductWeight: 'Peso del prodotto',
        ConfirmExecution: "Confermare l'esecuzione?",
        ConfirmBatchListing: "Confermare l'elenco batch?",
        Template: 'Modello',
        PleaseSelectTheProductsToBeSynchronized: 'Selezionare i prodotti da sincronizzare',
        ForcedSynchronisationDescription: 'La sincronizzazione forzata cancellerà i dati modificati e li sostituirà con i dati sincronizzati. La sincronizzazione ordinaria non sincronizzerà i dati dei prodotti modificati.',
        ConfirmSynchronizationExecution: "Confermare l'esecuzione della sincronizzazione",
        SynchronisationHasBeenSentPleaseWait: "Il messaggio relativo all'attività di sincronizzazione è stato inviato. Attendere il completamento della sincronizzazione dei dati.",
        ProhibitLogin: "Vietare l'accesso",
        IsTheUserLoggedIn: "L'utente è loggato?",
        Prohibit: 'Vietare',
        ProhibitedSuccessfully: 'Vietato con successo',
        EnableLogin: "Abilitare l'accesso",
        EnabledSuccessfully: 'Abilitato con successo',
        OperationCanceled: 'Operazione annullata',
        UserData: 'Dati utente.xlsx',
        GraphicDetails: 'Dettagli grafici',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Questo prodotto è stato impostato come prodotto combinato e non può essere impostato per la prevendita. Rimuovere il prodotto dalla combinazione?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Questo prodotto è un prodotto promozionale e non può essere impostato per la prevendita. Annullare prima la promozione.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Questo prodotto è un prodotto distribuito e non può essere impostato per la prevendita. Annullare prima la distribuzione.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: "Questo prodotto ha già un'attività di sconto totale e non può essere aggiunto",
        ESNoPriceNotAvailableForPresale: 'Questo prodotto non ha un prezzo in {Any} e non può essere aggiunto alla prevendita. Rimuovere prima il paese corrispondente o sincronizzare il prezzo del paese corrispondente prima di aggiungere prodotti in prevendita!',
        ConfirmSettingThisProductAsAPresaleItem: "Confermare l'impostazione di questo prodotto come prodotto in prevendita?",
        PresaleAddedSuccessfully: 'Pre-vendita aggiunta con successo',
        ConfirmSettingThisProductAsANonpresaleItem: "Confermare l'impostazione di questo prodotto come prodotto non in prevendita?",
        CancelPresaleSuccessful: 'Annullamento della prevendita riuscito',
        Preview: 'Anteprima',
        TheInventoryOfCurrentProducts0Continue: "L'inventario dell'attuale prodotto {stock} è 0, continuare?",
        TheInputContentCannotExceedCharacters: 'Il contenuto inserito non può superare {x} caratteri',
        PleaseSelectTheActivity: "Si prega di selezionare l'attività",
        OrderDetails: "Dettagli dell'ordine",
        LogisticsInformation: 'Informazioni sulla logistica',
        CancelRemark: 'Annullare la nota',
        DepositAmount: "Importo dell'anticipo",
        FinalPaymentAmount: 'Importo del pagamento finale',
        FullReductionDiscount: 'Sconto totale',
        YourBrowserDoesNotSupportVideoPlayback: 'Il tuo browser non supporta la riproduzione video',
        EditBanner: 'Modifica banner',
        AddBanner: 'Aggiungi banner',
        UpdateTime: 'Ora di aggiornamento',
        HomepageRecommendationPosition: 'Posizione di raccomandazione in prima pagina',
        ActivityID: "ID dell'attività",
        PleaseSelectThePosition: 'Si prega di selezionare la posizione',
        PleaseSelectTheJumpType: 'Si prega di selezionare il tipo di salto',
        PleaseEnterTheAssociatedID: "Si prega di inserire l'ID associato",
        TheUploadedImageSizeCannotExceed5MB: "La dimensione dell'immagine caricata non può superare 5MB!",
        OnlyMp4FormatIsSupportedForVideoUpload: "Il formato mp4 è l'unico supportato per l'upload video",
        TheUploadedVideoSizeCannotExceed500MB: 'La dimensione del video caricato non può superare {num}MB!',
        PleaseUploadTheVideo: 'Si prega di caricare il video',
        PleaseEnterTheJumpLink: 'Si prega di inserire il link di salto',
        PleaseEnterTheCorrectLink: 'Per favore inserisci il link corretto',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Verifica fallita, inserisci prima i campi obbligatori',
        AddFailed: 'Aggiunta fallita',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: "È già il prodotto principale e non può essere spostato verso l'alto",
        PleaseEnterTheRebateRatio: 'Inserisci il tasso di sconto',
        EditCoupon: 'Modifica coupon',
        AddCoupon: 'Aggiungi coupon',
        IDCouponName: 'ID/nome coupon/Codice riscatto',
        Random: 'Casuale',
        Fixed: 'Fisso',
        AboutToTakeeffect: 'In procinto di entrare in vigore',
        NotStackable: 'Non sovrapponibile',
        Stackable: 'Sovrapponibile',
        Frozen: 'Congelato',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: "Inserisci l'importo massimo di deduzione, 0 significa illimitato",
        UseUserID: "Utilizza l'ID utente",
        UseOrderNumberExchangeCodeUserID: "Utilizza il numero d'ordine/codice di scambio/ID utente",
        PleaseEnterTheDiscount: 'Inserisci lo sconto',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Puoi inserire solo numeri da 0 a 100, con una cifra decimale al massimo',
        PleaseEnterTheFullReductionAmount: "Inserisci l'importo di sconto totale",
        PleaseEnterTheExchangeCode: 'Inserisci il codice di scambio',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Puoi inserire solo numeri/lettere, per un minimo di almeno 6 caratteri fino a un massimo di 20 caratteri',
        PleaseSelectAFixedExchangeCode: 'Seleziona un codice di scambio fisso',
        PleaseEnterTheMaximumDeductionAmount: "Inserisci l'importo massimo di sconto",
        PleaseSelectWhetherItCanbeStacked: 'Seleziona se può essere sovrapposto',
        WarehousingTime: 'Data di ingresso a magazzino',
        EnterActivityDescription: "Inserisci una descrizione dell'attività",
        ActivityTitleCannotBeEmpty: "Il titolo dell'attività non può essere vuoto",
        TheTitleCannotExceed200Characters: 'Il titolo non può superare i {num} caratteri',
        PleaseEnterTheActivityDescription: "Inserisci la descrizione dell'attività",
        ActivityTimeCannotBeEmpty: "La data dell'attività non può essere vuota",
        ActivityDescriptionLimitOf10000Words: "Limite di {num} parole per la descrizione dell'attività",
        ActivityRecommendation: "Raccomandazione dell'attività",
        Promotion: 'Promozione',
        EveryFull: 'Ogni {num} completi',
        EnterAmount: "Inserisci l'importo",
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Puoi inserire solo numeri positivi, con due cifre decimali al massimo, non può iniziare con 0',
        PleaseEnterTheCorrectAmount: "Inserisci l'importo corretto",
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Seleziona le categorie o i prodotti applicabili',
        Rules: 'Regole',
        CopyLink: 'Copia link',
        DistributionNameProductIDBarcode: 'Nome distribuzione/ID prodotto/codice a barre',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Questa sotto-funzione non è attualmente disponibile in questo paese, ma lo sarà in seguito',
        PleaseEnterThePrice: 'Inserisci il prezzo',
        AutomaticallyCalculateAfterEnteringTheDiscount: "Calcola automaticamente dopo l'inserimento dello sconto",
        PleaseEnterTheQuantity: 'Inserisci la quantità',
        OrderRemarkLabel: "Etichetta per la nota dell'ordine",
        OrdersWorkWithInfluencers: 'Ordini in collaborazione con influencer',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: "Il margine di profitto lordo attuale del prezzo dell'attività corrente è",
        ThePriceCannotBeLowerThanTheLowestPrice: 'Il prezzo non può essere inferiore al prezzo più basso:',
        Distribution: 'Distribuzione',
        IDName: 'ID/nome',
        ProductSelected: 'Prodotto selezionato',
        ProductHasNoPricing: 'Il prodotto non ha un prezzo',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: "Il margine di profitto lordo attuale del prezzo dell'attività corrente è del {rate} %, confermi l'invio?",
        SubscriptionStatus: 'Stato abbonamento',
        URLClickToCopyAddress: "URL (clicca per copiare l'indirizzo)",
        URLSourceManagement: 'Gestione fonte URL',
        PleaseEnterTheURL: "Inserisci l'URL",
        PleaseEnterEnglishOrNumbers: 'Inserisci testo in inglese o numeri',
        LengthCannotExceed1000Characters: 'La lunghezza non può superare i 1000 caratteri',
        URLFixedAddressIsEmpty: "L'indirizzo fisso dell'URL è vuoto",
        cover: 'Copertina',
        PleaseEnterOrPasteTheVideoScript: 'Inserisci o incolla lo script video',
        VideoScript: 'Script video',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Carica un file video o compila lo script video',
        PleaseEnterTheBrand: 'Inserisci il marchio',
        Portuguese: 'Portoghese',
        Register: 'Registra',
        InviteUsers: 'Invita utenti',
        OrderPayment: "Pagamento dell'ordine",
        SupportsMP4Forma500MB: 'Supporta il formato mp4, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Al momento non è selezionato alcun prodotto, desideri chiuderlo?',
        ExitFullScreen: 'Uscita schermo intero',
        FullScreen: 'Schermo intero',
        Logout: 'Esci',
        OnlySupportsFilesInFormat: 'Supporta solo file nel formato {xxx}',
        PleaseSetAllPromotionalProducts: 'Per favore configura tutti i prodotti promozionali',
        PleaseEnterTheWeight: 'Inserisci il peso',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Il prezzo fisso non può essere inferiore al prezzo più basso',
        CloseOthers: 'Chiudi altri',
        CloseAll: 'Chiudi tutto',
        ImportSuccessful: 'Importazione avvenuta con successo',
        BatchRecharge: 'Ricarica batch',
        RechargeEmail: 'Email di ricarica',
        RechargeCountry: 'Paese di ricarica',
        RechargeSuccessful: 'Ricarica avvenuta con successo',
        RechargeInformation: 'Informazioni di ricarica',
        PleaseDownloadTheRechargeInformationFirst: 'Scaricare prima le informazioni di ricarica',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'I dati di ricarica batch degli AIGO coin non possono essere vuoti',
        TheBatchRechargeDataCannotExceed1000Records: 'I dati di ricarica batch non possono superare le 1000 voci',
        ConfirmSubmission: "Conferma l'invio",
        ExportFailed: 'Esportazione fallita',
        PackingQuantity: 'Quantità di confezionamento',
        Activity: 'Attività',
        PleaseSelectWhetherToEnable: 'Seleziona se vuoi abilitare',
        OnlyOneProductCanBeSynchronized: 'È possibile sincronizzare solo un prodotto',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU con una quantità di {num} non esiste',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU con una quantità di {num}  non rispetta i multipli di vendita',
        SalesMultipleDoesNotMatchCannotBeListed: 'I multipli di vendita non corrispondono, non possono essere elencati',
        SalesMultipleIsEmptyInSpain: 'I multipli di vendita sono vuoti in Spagna',
        SynchronizationOfPackageQuantityIsAbnormal: 'La sincronizzazione della quantità di confezione è anomala',
        CannotContinueToMoveUp: "Impossibile continuare a muoversi verso l'alto",
        CannotContinueToMoveDown: 'Impossibile continuare a muoversi verso il basso',
        SynchronizeThePackageQuantity: 'Sincronizza la quantità di confezione',
        ProductAssociationIDCannotBeEmpty: "L'ID di associazione del prodotto non può essere vuoto",
        PackageQuantityCannotBeEmpty: 'La quantità di confezione non può essere vuota',
        PleaseEnterTheCorrectQuantityFormat: 'Inserisci il formato corretto per la quantità',
        PleaseEnterSeparatedBy: 'Inserisci (separato da #)',
        PackageQuantity: 'Quantità di confezione',
        LengthBetween1And140Characters: 'Lunghezza compresa tra 1 e 140 caratteri',
        PleaseSelectTheVariantParametersFirst: 'Seleziona prima i parametri della variante',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Il parametro è associato alla variante, fai attenzione',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Seleziona dati della stessa categoria, seleziona nuovamente',
        UpTo5VariantsCanBeSelected: 'Puoi selezionare fino a 5 varianti',
        NoVariant: 'Nessuna variante',
        VerificationFailedPleaseSelectTheVariant: 'Verifica fallita, seleziona la variante',
        VerificationFailedPleaseSelectTheProduct: 'Verifica fallita, seleziona il prodotto',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verifica fallita, il valore del parametro del prodotto è vuoto',
        SetSuccessfully: 'Impostato con successo',
        UploadingVideo: 'Caricamento del video in corso',
        SearchParameters: 'Parametri di ricerca',
        ImportantParameters: 'Parametri importanti',
        Select: 'Seleziona',
        ConfirmSettingThisImageAsTheMainImage: "Conferma l'impostazione di questa immagine come immagine principale",
        ConfirmDisplayDoNotDisplay: 'Conferma visualizza/non visualizza',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'Il limite attuale è di selezionare {num1} file, questa volta hai selezionato {num2} file, per un totale di {num3} file selezionati',
        ConfirmToRemoveAny: 'Conferma la rimozione di {Any}?',
        TheTestEnvironmentCannotUploadFiles: "L'ambiente di test non può caricare file",
        PleaseEnterTheEnglishTitle: 'Inserisci il titolo in inglese',
        CancelSettingAsTheMainImage: "Annullare l'impostazione come immagine principale?",
        SetAsTheMainImage: 'Impostare come immagine principale?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Non è possibile inviare immagini o video già esistenti',
        SynchronizeAnyImagesToOtherLanguages: 'Sincronizzare {Any} immagini in altre lingue?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Seleziona prima il prodotto da sincronizzare',
        PleaseSelectTheProductFirst: 'Seleziona prima il prodotto',
        ConfirmWhetherToDeleteTheSelectedImageVideo: "Confermi l'eliminazione dell'immagine/video selezionato?",
        TheMainPictureCannotBeTurnedOff: "L'immagine principale non può essere disattivata",
        CancelDisplay: 'Annullare la visualizzazione?',
        ConfirmWhetherToDelete: "Confermi l'eliminazione?",
        PleaseUploadTheEnglishImage: "Per favore carica l'immagine in inglese",
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Fallito, inserisci il titolo del prodotto per tutti i paesi di vendita',
        PresaleTime: 'Periodo di prevendita',
        PresalePrice: 'Prezzo di prevendita',
        PleaseEnter: 'Inserisci',
        PresaleQuantity: 'Quantità di prevendita',
        DepositRatio: "Rapporto dell'anticipo",
        AssociatedAGBSOnthewayOrders: 'Ordini AGBS associati in viaggio',
        EstimatedDeliveryTime: 'Tempo di consegna previsto',
        FinalPaymentDeadline: 'Scadenza pagamento finale',
        SelectTime: 'Seleziona orario',
        FixedPrice: 'Prezzo fisso',
        ByProportion: 'Per proporzione',
        ByFullPayment: 'Per pagamento completo',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Puoi inserire solo numeri maggiori di 0, fino a due decimali',
        PresaleTimeCannotBeEmpty: 'Il periodo di prevendita non può essere vuoto',
        PresalePriceTypeCannotBeEmpty: 'Il tipo di prezzo di prevendita non può essere vuoto',
        PresaleQuantityCannotBeEmpty: 'La quantità di prevendita non può essere vuota',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Puoi inserire solo numeri interi positivi, non possono iniziare con 0',
        DepositRatioTypeCannotBeEmpty: "Il tipo di rapporto dell'anticipo non può essere vuoto",
        DepositRatioCannotBeEmpty: "Il rapporto dell'anticipo non può essere vuoto",
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Puoi inserire solo numeri interi compresi tra 0 e 100, esclusi 0 e 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'Il tempo di consegna previsto non può essere vuoto',
        FinalPaymentDeadlineCannotBeEmpty: 'La scadenza del pagamento finale non può essere vuota',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Il pagamento finale deve essere effettuato entro 5 ore prima della fine della promozione di prevendita',
        TheRemainingAmount: "L'importo rimanente",
        PresaleStatus: 'Stato di prevendita',
        PresaleStartTime: 'Data di inizio prevendita',
        PresaleEndTime: 'Data di fine prevendita',
        OnPresale: 'In prevendita',
        DirectVisit: 'Visita diretta',
        BackendManagementSystem: 'Sistema di gestione backend',
        Login: 'Accesso',
        TipsFillInTheUsernameAndPasswordCasually: 'Suggerimento: inserisci casualmente il nome utente e la password.',
        PleaseEnterUserName: 'Inserisci il nome utente',
        PleaseEnterPassword: 'Inserisci la password',
        LoginSuccessful: 'Accesso riuscito',
        PleaseEnterYourAccountNumberAndPassword: 'Inserisci il tuo numero di conto e la password',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Lo stesso prodotto può essere solo restituito o scambiato',
        B2CBrandWebsite: 'Sito web B2C',
        CommentManagement: 'Gestione dei commenti',
        AIGOCoins: 'AIGO coins',
        PleaseEnterThePackageQuantity: 'Inserisci la quantità di confezione',
        PresalePriceCannotBeEmpty: 'Il prezzo di prevendita non può essere vuoto',
        Product: 'Prodotto',
        HomePageColumn: 'Colonna della homepage',
        ColumnDescription: 'Descrizione della colonna',
        FlashSaleArea: 'Area di vendita lampo',

        //1.2.8之后
        SoldOutOrNot: 'Esaurito o no',
        VirtualQuantity: 'Quantità virtuale',
        VirtualQuantityPackageQuantity: 'Quantità virtuale/quantità confezione',
        CombinationIcon: 'Icona di combinazione',
        UploadFailedFileSizeShouldBe3MB: 'Caricamento non riuscito, la dimensione del file deve essere <3 MB',
        PromotionQuantityPackageQuantity: 'Quantità promozionale/quantità confezione',
        RemainingTotal: 'Restante/Totale',
        RecommendedSize100: 'Dimensione consigliata: 100*100, ≤ 20 kb',
        TotalRemainingTotal: 'Totale: rimanente/totale',
        Verification: 'Verifica',
        VirtualSalesVolume: 'Volume delle vendite virtuali',
        VirtualSalesVolumePackageQuantity: 'Volume delle vendite virtuali/quantità confezione',
        DeleteOrNot: 'Vuoi eliminare?',
        EnabledOrNot: 'Abilitato o no',
        Cancel: 'Annulla',
        Code: 'Codice',
        Confirm: 'Conferma',
        RebateDimension: 'Dimensione dello sconto',
        InProgress: 'In corso',
        ActivityStatus: "Stato dell'attività",
        RebateRatioManagement: 'Gestione del rapporto di sconto',
        StartTime: 'Ora di inizio',
        EndTime: 'Ora di termine',
        RebateAmount: 'Importo del rimborso',
        NewUser: 'Nuovo utente',
        RebateObjectNumber: 'Numero oggetto sconto',
        NumberOfCompletedOrders: 'Numero di ordini completati',
        RebateObject: 'Oggetto dello sconto',
        Operation: 'Operazione',
        RecentOperator: 'Ultimo operatore',
        Country: 'Paese',
        Prompt: 'Suggerimento',
        RebateProducts: 'Prodotti in sconto',
        NewUserPromotion: 'Promozione nuovi utenti',
        RegularUserPromotion: 'Promozione utenti abituali',
        Upcoming: 'In arrivo',
        RebateRatio: 'Rapporto di sconto',
        RebateOrNot: 'Ci sono sconti?',
        Rebate: 'Sconto',
        PleaseSelectARebateDimension: 'Seleziona una dimensione di sconto',
        PleaseSelectARebateObject: 'Seleziona un oggetto di sconto',
        PleaseSelectACountry: 'Seleziona un Paese',
        PleaseSelectARebateRatio: 'Seleziona un rapporto di sconto',
        RecentOperationTime: "Ora dell'ultima operazione",
        PromotionObjectType: 'Tipo di oggetto della promozione',
        ProductPromotionType: 'Tipo di promozione del prodotto',
        UserType: 'Tipologia di utente',
        ApplicableUserType: 'Tipo di utente applicabile',
        RegularUser: 'Utente abituale',
        UseAigoCoinsOrNot: 'Vuoi utilizzare Aigo Coin?',
        EndDateOfMembership: 'Data di fine adesione',
        StartDateOfMembership: 'Data di inizio adesione',
        memberOrNot: 'Sei membro?',
        No: 'NO',
        Yes: 'SÌ',
        LowInventory: 'Stock basso',
        RechargeEmailbox: 'Ricarica e-mail',
        SubmittedSuccessfully: 'Inviato con successo',
        Importing: 'Importazione in corso',
        InformationOfTheRechargeFailed: 'Messaggio di errore',
        Remarks: 'Commenti',
        RechargeFailed: 'Ricarica fallita',
        RechargeInBatch: 'Ricarica in lotti',
        RechargeSuccessfully: 'Ricarica riuscita',
        RechargeAigoCoins: 'Ricarica Aigo coins',
        RequiredField: 'Campo obbligatorio',
        PDFFormat: 'Formato PDF',
        FileSize3MB: 'Dimensione del file <3MB',
        UploadFailedPDFFormatRequired: 'Caricamento non riuscito, richiesto il formato PDF',
        AuditFailedPleaseUploadInvoiceAttachment: 'Auditoría fallida, por favor, cargue la factura adjunta',
        PleaseUploadInvoiceAttachment: 'Cargue la factura adjunta',
        InvoiceAttachment: 'Allegato fattura',
        Upload: 'Caricare',
        UploadFailed: 'Caricamento non riuscito',

        HaveAnAccount: 'Non hai un account? Clicca qui per registrarti',
        SoldOut: 'Esaurito',
        HaveAnInvitationCode: 'Facoltativo, lascia vuoto se non disponi di un codice di invito.',
        RestockedItems: 'Articoli riforniti',

        NotMeetPleaseEnterAgain: 'Il nickname non rispetta i requisiti, ti preghiamo di inserirlo nuovamente.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Questo coupon è valido esclusivamente per i nuovi utenti.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Questo coupon è valido esclusivamente per gli utenti già registrati.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Sono ammessi solo resi o scambi per lo stesso prodotto.',
        With365DaysRemaining: '（Scade il 25/09/2024）con 365 giorni rimanenti',

        TheNicknameDoesNotMeet: 'Username non soddisfa i requisiti, inserire nuovamente',

        GoToView: 'Vai a vedere',
        DoNotRegisterRepeatedly: 'Non registrarti ripetutamente',
        ValidityPeriod: 'Periodo di validità',
        RegisterSuccessfully: 'Registrazione riuscita',
        MemberRegistration: 'Registrazione membro',
        RegistrationFailed: 'Registrazione fallita',
        OneYear: 'Un anno',
        AddToShoppingCart: 'Aggiungi al carrello',
        AddANewAddress: 'Aggiungi nuovo indirizzo',
        ZIPCode: 'Cap*',

        EditYourAddress: 'Modifica il tuo indirizzo',
        Remove: 'Rimuovi',
        SetAsDefault: 'Imposta come predefinito',
        FailedChangeDefaultAddress: "Modifica dell'indirizzo predefinito non riuscita, riprova più tardi",
        Fullname: 'Nome completo (Nome e Cognome)*',
        PhoneNumber: 'Numero di telefono',
        DefaultAddressHasBeenChanged: "L'indirizzo predefinito è cambiato",
        ViewComments: 'Visualizza i commenti',
        ClickToCheckLogisticsInfo: 'Fare clic per verificare le informazioni logistiche',
        RestockingOfOutOfStockItems: 'Rifornimento di articoli esauriti',
        ThisWeekRestockedItems: 'Articoli riforniti di questa settimana',
        ProductListInTheShoppingCart: 'Elenco dei prodotti nel carrello',
        WarmReminderVideoResources: 'Promemoria: le risorse video supportano solo un singolo download',
        PleaseLeaveYourMessage: 'Per favore lascia il tuo messaggio',
        InvoiceManagement: 'Gestione delle fatture',

        ReadmeFile: 'File leggimi',
        HelpCenter: 'Centro assistenza',
        FileUpload: 'Caricamento file',
        Globalization: 'Globalizzazione',
        SalespersonProfile: 'Profilo del venditore',
        BatchSyncPage: 'Pagina di sincronizzazione batch',
        CountryConfigurationPage: 'Pagina di configurazione del Paese',
        UserConsultation: 'Consultazione degli utenti',
        PreSaleItems: 'Articoli in prevendita',
        EventManagement: 'Gestione di eventi',
        BlankPage: 'Pagina vuota',
        ThereAreCurrentlyNoProductPromotions: 'Al momento non sono presenti promozioni sui prodotti',
        EditInventoryBottomLine: "Modifica i profitti dell'inventario",
        SetAFixedPrice: 'Stabilisci un prezzo fisso',
        EnterUpTo500Entries: 'Inserisci fino a 500 voci',
        CantRelateToYourself: 'Non puoi collegare a te stesso',
        PleaseSelectTheApplicableUserType: 'Seleziona il tipo di utente applicabile',
        TheActivityDescriptionLimitIs10000Words: "Il limite della descrizione dell'attività è di 10.000 parole",
        EnabledStatus: 'Stato abilitato',
        Enable: 'Abilitare',
        OperatingTime: 'Tempo di operatività',
        NewUserPromotionEnabled: 'Promozione per nuovi utenti abilitata',
        NewUserPromotionDisabled: 'Promozione per nuovi utenti disabilitata',
        OldUserPromotionEnabled: 'Promozione per vecchi utenti abilitata',
        OldUserPromotionDisabled: 'Promozione per vecchi utenti disabilitata',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Il prezzo promozionale non può essere inferiore al prezzo più basso',
        AutomaticallyCalculateAfterEnteringPrice: 'Calcola automaticamente dopo aver inserito il prezzo',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Il prezzo promozionale per i nuovi utenti è maggiore del prezzo promozionale per i vecchi utenti. Vuoi continuare?',
        PleaseSelectPromotionCountry: 'Seleziona il Paese della promozione',
        PromotionDiscount: 'Sconto promozionale',
        PleaseEnterThePromotionalQuantity: 'Inserisci la quantità promozionale',
        PleaseEnterVirtualQuantity: 'Inserisci la quantità virtuale',
        NumberOfUserPurchases: 'Numero di acquisti degli utenti',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Inserisci il limite di acquisto per lo stesso utente',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Inserisci il limite di acquisto per un singolo ordine',
        QuantityGreaterThan0: 'Quantità maggiore di 0',
        GrossProfitMarginCalculationResults: 'Risultati del calcolo del margine di profitto lordo',
        FixedPriceGrossMargin: 'Margine lordo a prezzo fisso',
        SellingPriceGrossProfitMargin: 'Margine di profitto lordo del prezzo di vendita',
        PromotionalPriceGrossProfitMargin: 'Margine di profitto lordo del prezzo promozionale',
        PromotionStartTime: 'Data di inizio promozione',
        PromotionEndTime: 'Data di fine della promozione',
        PromotionType: 'Tipo di promozione',
        PleaseSetTheSKUToPromotionalProducts: 'Imposta lo SKU su ${xxx} prodotti promozionali',
        DistributionOffers: 'Offerte di distribuzione',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Dopo aver inserito la quantità {Any} , verrà calcolata automaticamente in base alla quantità del prodotto.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: "Il numero di acquisti dell'utente deve essere maggiore di 0",
        PleaseSelectCountry: 'Seleziona il Paese ${typeName}',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Dopo aver inserito la quantità promozionale, questa verrà calcolata automaticamente in base alla quantità di merce.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: "Annullare prima l'associazione in [Gruppo prodotti in sconto].",
        EditBottomLineInventory: "Modifica l'inventario di fondo",
        PleaseConfigureFixedPrice: 'Si prega di configurare il prezzo fisso',
        BalancePayment: 'Pagamento del saldo',
        Deposit: 'Acconto',

        EndofValidity: 'Inserire la data di scadenza',
        Year: 'Anno',
        Month: 'Mese',
        Day: 'Giorno',
        UnitOfValidity: 'Unità di validità',
        AIGOCoinDetails: 'Dettagli della moneta AIGO',
        AIGOCoinsAreCloseToExpiring: 'Le monete AIGO sono prossime alla scadenza(Giorno)',
        CountryOfShipmentAddress: "Paese dell'indirizzo di spedizione",

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Data di spedizione stimata',
        DataCorrespondingToTheSource: 'Dati di origine corrispondenti',
        AccountStatus: "Stato dell'account",
        OrderID: 'ID ordine',
        RecipientName: 'Nome del destinatario',
        RecipientLastName: 'Cognome del destinatario',
        MailsOfUser: "Mail dell'utente",
        StateProvince: 'Regione',
        CityTown: 'Città/Paese',
        County: 'Provincia',
        ReceivingCompany: 'Azienda ricevente',
        BatchInput: 'Importazione di gruppo',
        InputManually: 'Importazione manuale',
        ProductCommentsInputTemplate: 'Modello di importazione delle recensioni sui prodotti',
        UserName: 'Nome utente',
        Comments: 'Recensioni',
        StarRatingOfTheComment: 'Valutazione in stelle della recensione',
        CommentTime: 'Data recensioni',
        UploadAttachment: 'Carica allegato',
        NumberOfAttachments: 'Numero di allegati',
        FileName: 'Nome del file',
        Appendix: 'Appendice',
        BatchInputOfProductComments: 'Importazione di gruppo di recensioni sui prodotti',
        SoldOut1: 'Esaurito',
        PreSalePromotionID: 'ID promozione di prevendita',
        PromotionAbnormal: 'Anomalia promozione',
        Updater: 'Aggiornamento effettuato da',
        AddPreSalePromotion: 'Aggiungi promozione di prevendita',
        EditPreSalePromotion: 'Modifica promozione di prevendita',
        Barcode: 'Barcode',
        PreSaleCountries: 'Paesi soggetti a prevendita',
        PreSaleDeposit: 'Anticipo di prevendita',
        PleaseEnterTheDocumentCode: 'Inserisci il codice del documento',
        BalancePaymentDeadline: 'Scadenza pagamento saldo',
        FixedShippingFee: 'Tariffa di spedizione fissa',
        PreSaleShippingFee: 'Spese di spedizione prevendita',
        FreeShippingSubjectToConditions: 'Spedizione gratuita soggetta a condizioni',
        OrderPrice: "Prezzo dell'ordine",
        PleaseEnterPriceConditions: 'Inserisci le condizioni di prezzo',
        TheEndTimeNeedsToBeLaterThanTheStartTime: "L'ora di fine deve essere successiva all'ora di inizio",
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Sono già presenti promozioni parzialmente sovrapposte in questo Paese, si prega di modificare',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'La quantità di prevendita può essere solo un numero intero positivo maggiore di 0',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: "La data di consegna stimata deve essere successiva all'ora di fine promo",
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'La scadenza per il pagamento del saldo deve essere anteriore alla data di consegna stimata',
        PromotionID: 'ID promozione',
        DistributionPromotionID: 'ID promozione di distribuzione',
        CouponID: 'Codice identificativo del coupon',
        PromotionManagementID: 'ID gestione promo',
        PortfolioManagementID: 'ID gestione combinazioni',
        ExportForFiltering: 'Esporta per filtrare',
        ListDisplay: 'Visualizza elenco',
        InventoryItems: 'Articoli di inventario',
        InStock: 'In stock',
        OutOfStock: 'Esaurito',
        FieldName: 'Nome del campo',
        PleaseSelectInventory: 'Seleziona lo stock',
        PreSaleEvent: 'Evento di pre-vendita',
        PerPage: 'Ogni pagina mostra',

        // V1.3.3 end

        // V1.3.4 start

        PleaseProvideTheNameOfTheLandingPageActivity: "Si prega di fornire il nome dell'attività della pagina di destinazione.",
        PleaseIndicateTheNumberOfActivities: 'Si prega di indicare il numero di attività.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'La quantità di attività deve essere maggiore della quantità regalata.',
        PleaseChooseATimeForTheEvent: "Si prega di selezionare un'ora per l'evento.",
        ActivityNameExceedsLength: "Il nome dell'attività supera la lunghezza massima.",
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Questo articolo è limitato a 10 per ordine.',
        PleaseChooseTheGiveawayAgain: 'Seleziona nuovamente il regalo omaggio.',
        AnalysisOfGiveawayActivities: 'Analisi delle attività di giveaway',
        StatisticsOfGiveawayActivities: 'Statistiche delle attività di giveaway',
        PleaseEnterTheNameOfTheEvent: "Si prega di inserire il nome dell'evento.",
        PleaseSelectTheActivityType: 'Scegliere il tipo di attività.',
        PleaseCompleteTheActivityRating: "Si prega di compilare la valutazione dell'attività.",
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Si prega di configurare la campagna di pre-vendita {Any} prima di poter configurare il paese in cui verranno esposti gli articoli in pre-vendita.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: "L'importo dell'ordine e il numero di omaggi non possono essere inferiori alla categoria precedente.",
        SelectImage: "Seleziona l'immagine",
        GiftInformation: 'Informazioni sul regalo',
        Gifts: 'Omaggi',
        GiftsOfEndOfEvent: 'Omaggi di fine evento',
        CheckOutTheGifts: 'Scopri gli omaggi',
        GiveawayEvent: 'Evento promo con omaggi',
        SelectAGift: 'Seleziona un omaggio',
        YouHaveNotSelectedAGift: 'Non hai selezionato un omaggio',
        NoNeedForGifts: "Non c'è bisogno di omaggi",
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Alcuni omaggi sono esauriti, seleziona nuovamente',
        ReplaceAGift: 'Sostituisci un omaggio',
        ThereAreCurrently3FreeGiftsAvailable: 'Al momento sono disponibili 3 omaggi per te',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Al momento sono disponibili 3 omaggi per te, e altri 4 omaggi se effettui un acquisto per altri 99 €',
        GiftList: 'Elenco omaggi',
        GiftID: 'Codice omaggio',
        CurrentInventory: 'Inventario attuale',
        NotEnabled: 'Non abilitato',
        GoodsForFree: 'Prodotti gratis',
        ConfirmToDeleteThisGiftInformation: "Sei sicuro di voler eliminare queste informazioni sull'omaggio?",
        GiftsSortingManagement: 'Gestione riordino omaggi',
        GiftsTopicManagement: 'Gestione tematica omaggi',
        GiveawayEventID: 'ID evento promo con omaggi',
        GiveawayEventName: 'Nome evento promo con omaggi',
        ProductCategory: 'Categoria di prodotto',
        ActivityMode: 'Modalità evento promo',
        TotalNumberOfActivities: 'Numero totale di eventi promo',
        GiftsGivenQuantity: 'Quantità di omaggi usufruiti',
        ThemeConfiguration: 'Configurazione tematica',
        GiftSorting: 'Riordino degli omaggi',
        GlobalConfiguration: 'Configurazione globale',
        ActiveCountry: "Paese dell'evento promo",
        PromotionalPpicture: 'Immagine promozionale',
        RecommendedSizeX: 'Misure consigliate: {XXX*XXX}',
        FileSize1MB: 'Dimensione del file: ≤1 MB',
        ActivitySorting: 'Riordino degli eventi promo',
        DragToSort: 'Trascina per riordinare',
        ActivityCode: 'Codice evento promo',
        GiftsForOrderAmount: "Omaggio per importo minimo d'ordine",
        ActivityRating: "Valutazione dell'evento promo",
        GiftsQuantity: 'Numero di omaggi',
        EventGiftsSorting: 'Riordino degli omaggi per gli eventi',
        DefaultSelectionOfUsers: "Selezione predefinita dell'utente",
        LandingPageDisplay: 'Visualizzazione della pagina di destinazione',
        LandingPageActivityName: "Nome dell'attività della pagina di destinazione",
        ConfirmDeleteThisGiftEvent: 'Sei sicuro di voler eliminare questo evento promo con omaggi?',
        IsThereAGiftEvent: "C'è un evento promo con omaggi?",
        OrderQuantity: "Quantità dell'ordine",
        NumberOfGiftsIssued: 'Numero di omaggi emessi',
        CustomerPrice: 'Prezzo del cliente',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'La funzione quantità eventi virtuali può essere impostata solo se il tempo di attività di prevendita è almeno superiore a 2 giorni e il numero di eventi è maggiore di 50',
        PreSaleSwitch: 'Cambio di prevendita',
        PreSaleActivity: 'Attività di prevendita',
        GiftManagement: "Gestione dei regali",

        // V1.3.4 end

        //V1.3.5 start
        OrderSource: "Origine dell'ordine",
        NumberOfNewUserRegistrations: "Numero di registrazioni di nuovi utenti",
        RegisteredUsersWhoVsitTheWebsite: "Utenti registrati che visitano il sito",
        SoldDistributionQuantity: "Quantità di distribuzione venduta",
        DistributionQuantityLock: "Blocco della quantità di distribuzione",
        InsufficientDstributionQuantityRemaining: "Quantità di distribuzione rimanente insufficiente",
        Previous: "Precedente",
        Next: "Prossimo",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Gestione ricette",
        RecipeDisplayImage: "Immagine di visualizzazione della ricetta",
        NewRecipeDisplayImage: "Modifica/crea una nuova immagine di visualizzazione della ricetta",
        CoverPicture: "Immagine di copertina",
        PicturesVideos: "È possibile caricare fino a 10 immagini + video, ciascuna immagine deve avere dimensioni ≤ 5 MB e il video supporta il formato .mp4",
        AddPicturesVideos: "Aggiungi foto/video",
        CommenOnPicturesVideos: "Commenta foto/video",
        FileSize: "Dimensione del file",
        BarcodeAssociated: "Barcode associato",
        FirstUploadTime: "Primo orario di caricamento",
        EditTime: "Modifica l'ora",
        TagType: "Tipo di etichetta",
        FileName1: "Nome del file",
        NameLengthISTooLong: "Lunghezza del nome troppo lunga",
        ProductDoesNoExist: "Prodotto non esistente",
        PleaseUploadImages: "Si prega di caricare immagini jpg/png/jpeg inferiori a 5 MB",
        PleaseUploadTheVideoMP4: "Carica il video in formato mp4",
        PictureOrVideo: "L'immagine o il video non possono essere vuoti",
        allowed: "Consenti tutto",
        cookie1: "Gestisci i cookie",
        collectsordinances: "Aigostar utilizza i cookie per migliorare l'esperienza di navigazione dei visitatori e per finalità di marketing. Visualizza e gestisci le impostazioni dei cookie di seguito. Per saperne di più su come utilizziamo i cookie, consulta la nostra \"Informativa sulla privacy\".",
        RequiredCookies: "Cookie necessari",
        cookie2: "Questi cookie sono necessari per funzionalità di base come l'accesso, l'utilizzo del carrello e l'effettuazione di pagamenti. I cookie necessari non possono essere disattivati. Ma non preoccuparti, questi cookie non memorizzano nessuna delle tue informazioni.",
        AnalyticsCookies: "Cookie di marketing e analitici",
        cookie3: "Questi cookie raccolgono informazioni come il numero di visite al sito Web o quali pagine sono popolari, per aiutarci a migliorare la nostra esperienza cliente. Puoi scegliere di disattivare questi cookie, ma in tal caso non saremo in grado di raccogliere le informazioni di cui abbiamo bisogno per migliorare la tua esperienza sul nostro sito web.",
        StoragePreferences: "Salva le preferenze",
        BuyItNow: "Acquista subito",
        PictureVideoSize: "Le dimensioni o il formato dell'immagine o del video non sono corretti",
        RecipeConfiguration: "Configurazione della ricetta",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Confermare la sincronizzazione dei sotto-codici a barre?",
        SynchronizationEbpEanSUB: "A causa della necessità di interfacciarsi con EBP, la sincronizzazione batch dovrebbe essere completata entro 3 minuti. Dopo la conferma, la sincronizzazione procederà in modo asincrono e i sotto-codici a barre verranno visualizzati immediatamente al ricevimento. Si prega di essere pazienti.",
        Keywords: "Parole chiave",
        SystemAutoUpdate: "Aggiornamento automatico del sistema",
        IPAddress: "Indirizzo IP",
        SearchKeywords: "Cerca parole chiave",
        LoginEmail: "Email di accesso",
        SearchTime: "Tempo di ricerca",
        NumbeOfTimes: "Numero di volte",
        MyBrowsing: "La mia navigazione",
        LoggingOuOfYouAccount: "Il logout dal tuo account sarà considerato come una tua rinuncia volontaria e non sarai più in grado di continuare a utilizzare questo account.",
        RequestLogout: "Richiedi il logout",
        ProductsYet: "Non hai ancora navigato alcun prodotto",
        clearall: "Cancella cronologia",
        CancellTip1: "Le liste del carrello della spesa, i record di acquisto degli ordini e i record dei preferiti verranno eliminati permanentemente e non potranno essere recuperati.",
        CancellTip2: "Tutte le informazioni dell'account, i record dei servizi, i dati di consumo, ecc., sulla piattaforma verranno eliminati permanentemente e non potranno essere recuperati.",
        CancellTip3: "Non sarà in grado di accedere o utilizzare questo account AigoSmart.",
        CancellTip4: "Le informazioni personali e i messaggi storici (incluse avatar, soprannomi, la mia valuta Aiguo, notifiche della piattaforma, i miei commenti, ecc.) associati al tuo account AigoSmart non saranno recuperabili.",
        CancellTip5: "Devi gestire correttamente i fondi dell'account (punti, livelli di adesione, qualifiche, ordini, buoni, accessori e altri servizi di valore) prima di disconnetterti, poiché la cancellazione dell'account sarà considerata come una rinuncia volontaria e non potrà essere annullata. Comprendi e accetti che AigoSmart non possa aiutarti a ripristinare i servizi sopra menzionati.",
        SynchronizeSubBarcodes: "Sincronizzare i sotto-codici a barre",
        PleaseEnterKeywords: "Si prega di inserire le parole chiave",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Statistiche orarie",
        NumbeOfUsers: "Numero di utenti che hanno effettuato ordini",
        NumberOfCanceled: "Numero di ordini annullati",
        RefundAmount: "Importo rimborsato",
        UsersWhoHavePaid: "Utenti che hanno pagato (ordini che possono essere spediti, spediti, completati)",
        TotalNumber: "Numero totale di ordini annullati dalla piattaforma",
        AmountOfCanceled: "Importo degli ordini annullati che sono stati pagati ma non spediti, importo degli ordini restituiti che sono stati pagati (in transito, consegnati)",
        RedemptionCode: "ID/Nome del coupon/Codice di riscatto",
        DeleteAll: "Elimina",
        AccountIsUnderReview: "L'account attuale è in fase di revisione per la disattivazione. Continuare ad accedere annullerà il processo di disattivazione. Vuoi continuare a usarlo?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Il tempo non può essere vuoto",
        ProductImporTemplate: "Modello di importazione dei prodotti",
        NormalOrdersExceed: "Gli ordini normali superano:",
        OrderSautomaticallyClosed: "Gli ordini non pagati verranno chiusi automaticamente",
        OrdeIsShipped: "Dopo che l'ordine è stato spedito:",
        AutomaticallyComplete: "Completa automaticamente l'ordine",
        Minutes: "Minuti",
        Days: "Giorni",
        OrderSettings: "Impostazioni degli ordini",
        ClearCache: "Cancella cache",
        PleaseEnterNavigationContent: "Si prega di inserire il contenuto della navigazione",
        WebsiteNavigationConfiguration: "Configurazione della navigazione del sito web",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Tempo di ritardo del popup",
        SubscriptionPopup: "Popup di sottoscrizione",
        MeansNoPopupDelay: "Inserisci il tempo di ritardo della finestra pop-up",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "Zona UGC",

        //V1.5.0 end

        //V1.6.0 start
        uploaFilesInJpg: "Carica solo file in formato jpg/png inferiori a 2M",
        SynchronizeEbpPrice: "Sincronizza prezzo EBP",
        ConfirmToSynchronizeEbp: "Conferma per sincronizzare prezzo EBP",
        AsynchronousEbp: "Dopo la conferma, la sincronizzazione verrà eseguita in modo asincrono e il prezzo di vendita verrà visualizzato immediatamente dopo essere stato ottenuto. Si prega di attendere.",
        CopywritingDisplay: "Visualizzazione del copywriting",
        HomepageCopywriting: "Copywriting della home page",
        EditHomepageCopywriting: "Modifica del copywriting della home page",
        SuperimposedEvents: "Vuoi cumulare le promo?",
        GiftsEvents: "(Gli omaggi di questa promo non sono cumulabili con altri eventi)",
        SmallGiftEvent: "Promo piccoli omaggi",
        SpecialProductEvent: "Promo prodotti speciali",
        SpecialGiftEvent: "(Non è possibile ricevere contemporaneamente piccoli omaggi e omaggi per prodotti speciali)",
        ProductsWithSKU: "Seleziona i prodotti con uno SKU",
        LimitWereSelected: "Il limite attuale è di {limitNum} file. Questa volta sono stati selezionati {selectNum} file.",
        FileUploadFailed: "Caricamento file non riuscito!",
        CompleteMultiLanguage: "Completa la configurazione multilingue",
        FirstSynchronizeSKU: "Si prega di sincronizzare prima l'SKU di questo prodotto.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Vuoi rendere pubblico il coupon?",
        RedemptionCode1: "Codice di riscatto",
        NowOnlyNeed: "Ora servono solo 10€",
        WebLogReporting: "Segnalazione del registro web",
        PageAddress: "Indirizzo della pagina",
        ErrorLogs: "Registro degli errori",
        CustomParameter: "Parametro Personalizzato",
        ParameterID: "ID Parametro",
        // ParameterName: "Nome Parametro",
        SearchParameter: "Parametro di Ricerca",
        EnterParameterName: "Inserisci il nome del parametro",
        ParameterType: "Tipo di Parametro",
        AssociatedProductCount: "Conteggio Prodotti Associati",
        MandatoryPortugueseContent: "Contenuto obbligatorio in portoghese",
        MandatoryEnglishContent: "Contenuto obbligatorio in inglese",
        MandatoryDutchContent: "Contenuto obbligatorio in olandese",
        MandatoryPolishContent: "Contenuto obbligatorio in polacco",
        MandatoryFrenchContent: "Contenuto obbligatorio in francese",
        MandatoryGermanContent: "Contenuto obbligatorio in tedesco",
        MandatoryItalianContent: "Contenuto obbligatorio in italiano",
        MandatorySpanishContent: "Contenuto obbligatorio in spagnolo",
        MandatoryChineseContent: "Contenuto obbligatorio in cinese",
        ImportTemplate: "Modello di importazione dei prodotti con parametri personalizzati",
        ConfirmImportFile: "Confermare il file di importazione, continuare?",
        SelectDataRemove: "Seleziona i dati da rimuovere",
        ImportTemplateDownload: "Scarica il modello di importazione",
        AssociatedProducts: "Prodotti correlati",

        //V1.7.0 end


    }
};

export default it;