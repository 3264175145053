import axios from 'axios'
const isNewVersion = () => {
    let url = `//${window.location.host}/static/version.json?t=${new Date().getTime()}`;
    axios.get(url).then(res => {
        if (res.status === 200) {
            let vueVersion = res.data.version,
                localVueVersion = localStorage.getItem('vueVersion');
            if (localVueVersion) {
                if (localVueVersion != vueVersion) {
                    localStorage.setItem('vueVersion', vueVersion);
                    window.location.reload();
                    return;
                } else {
                    return;
                }
            } else {
                localStorage.setItem('vueVersion', vueVersion);
                window.location.reload();
            }
        }
    });
}

export default {
    isNewVersion
}