import deLocal from 'element-ui/lib/locale/lang/de';
const de = {
    ...deLocal,
    common: {
        datastatistics: 'Statistik',
        Transactionstatistics: 'Transaktionsstatistiken',
        LabelOptions: 'Label-Optionen',
        Orderdatastatistics: 'Auftragsstatistik',
        Hourlystatistics: 'nach Stunden',
        Countbyday: 'nach Tagen',
        Spain: 'Spanien',
        Italy: 'Italien',
        Germany: 'Deutschland',
        France: 'Frankreich',
        poland: 'Polen',
        Netherlands: 'Niederlande',
        Britain: 'Großbritannien',
        UnitedStates: 'USA',
        Portugal: 'Portugal',
        SalesCountry: 'Land des Verkaufs',
        query: 'Anfrage',
        Placinganorder: 'Bestellmenge',
        Orderamount: 'Betrag der Bestellung',
        Numberofusersplacingorders: 'Anzahl der Nutzer, die Bestellungen aufgeben',
        Ordertime: 'Zeitpunkt der Bestellung',
        time: 'Zeit',
        usermanagement: 'Benutzerverwaltung',
        UserID_loginemail_registrationinviterID_phonenumber: 'Benutzer-ID / Login-E-Mail / registrierte Einladungs-ID / Mobiltelefonnummer',
        country: 'Land',
        Whethertosubscribe: 'Abonnieren oder nicht',
        Loginstatus: 'Login-Status',
        normal: 'Normal',
        Disabled: 'Deaktivieren',
        Createdon: 'Erstellungsdatum',
        Lastlogintime: 'Letzte Anmeldezeit',
        Reset: 'Zurücksetzen',
        export: 'Exportieren',
        UserID: 'Benutzer-ID',
        Registeredcountry: 'Land der Anmeldung',
        mailbox: 'E-Mail',
        Mobilephonenumber: 'Handynummer',
        Patrioticcoin: 'Aigo-Münzen',
        RegistrationInviterID: 'Registrierte Einladungs-ID',
        brand: 'Marke',
        Creationtime: 'Erstellungszeit',
        operation: 'Betrieb',
        check: 'Ansicht',
        orderform: 'Bestellungen',
        consultingservice: 'Anfrage',
        address: 'Adresse',
        LoginLog: 'Login Log',
        Rechargepatrioticcoins: 'Aigo-Münzen aufladen',
        Returnsandexchanges: 'Rückgabe und Umtausch',
        evaluate: 'Bewerten',
        Shippingaddress: 'Empfangsadresse',
        shoppingcart: 'Warenkorb',
        collection: 'Abholung',
        invoice: 'Rechnung',
        nickname: 'Nickname',
        nothing: 'Keine',
        close: 'Schließen',
        source: 'Quelle',
        Subscriptiontime: 'Abo-Zeit',
        Thirdpartylogin: 'Anmeldung eines Dritten',
        Bound: 'Gebunden',
        Unbound: 'Ungebunden',
        Invitationcode: 'Einladungscode',
        Inviteduser: 'Eingeladene Benutzer',
        Invitetoreceivepatrioticcoins: 'Einladen, um Aigo-Münzen zu erhalten',
        Isthefirstorderfreeofshipping: 'Kostenloser Versand oder nicht bei Ihrer ersten Bestellung',
        shopping: 'Einkaufen',
        Collectproducts: 'Bevorzugte Artikel',
        ShoppingCartItems: 'Warenkorb Artikel',
        PaymentOrder: 'Bezahlte Bestellungen',
        Ordercumulativeamount: 'Gesamtbetrag der Bestellung',
        Lastordertime: 'Letzte Bestellzeit',
        Returnandexchangeorders: 'Rückgabe und Umtausch von Bestellungen',
        Consultationfrequency: 'Häufigkeit der Anfragen',
        Numberofevaluations: 'Anzahl der Überprüfungen',
        Numberofapprovedevaluations: 'Anzahl der genehmigten Rezensionen',
        ShowCountry: 'Land anzeigen',
        name1: 'Name',
        TaxID: 'Steuernummer',
        province: 'Provinz',
        city: 'Stadt',
        Detailedaddress: 'genaue Adresse',
        Housenumber: 'Hausnummer',
        AddressAdditionalContent: 'Adresse zusätzlicher Inhalt',
        RecipientCompany: 'Empfänger Firma',
        Defaultshippingaddress: 'Standard-Lieferadresse',
        Defaultinvoiceaddress: 'Standard-Rechnungsadresse',
        ProductID1: 'Produkt-ID',
        Productname1: 'Produktname',
        CollectionPrice: 'Kollektionspreis',
        Currentprice: 'Aktueller Preis',
        Collectiontime1: 'Abholzeit',
        Additionalpurchaseprice: 'Preis der Artikel, die zur Wunschliste hinzugefügt werden',
        Additionalpurchasetime: 'Uhrzeit, an der der Artikel zur Wunschliste hinzugefügt wird',
        Listingstatus: 'Regalstatus',
        Listed: 'ins Regal gestellt',
        Notlisted: 'nicht im Regal',
        Numberofpatrioticcoins: 'Anzahl der Aigo-Münzen',
        Enteraquantitybetween0and1000: 'Geben Sie eine Menge zwischen 0 und 1000 ein',
        Remarks: 'Bemerkungen',
        cancel: 'Abbrechen',
        UserDetails: 'Benutzerdetails',
        Areyousuretoexportthefile: 'Wollen Sie den Export der Datei bestätigen, wollen Sie fortfahren?',
        prompt: 'Aufforderung',
        Exportsuccessful: 'Export erfolgreich',
        today: 'heute',
        Lastweek: 'Letzte Woche',
        Thismonth: 'Dieser Monat',
        Lastmonth1: 'Letzter Monat',
        thisyear: 'Dieses Jahr',
        Inputcannotbeempty: 'Die Eingabe kann nicht leer sein',
        Pleaseenterapositiveinteger: 'Bitte geben Sie eine positive ganze Zahl ein',
        Pleaseenterthequantityofpatrioticcoins: 'Bitte geben Sie die Anzahl der Aigo-Münzen ein',
        Pleaseenteracomment: 'Bitte geben Sie eine Bemerkung ein',
        Pleaseselectacountry: 'Bitte wählen Sie ein Land aus',
        Successfullysubmitted: 'Erfolgreich abgeschickt',
        Nopatrioticcoinrulesconfigured: 'Keine Aigo-Münzregeln konfiguriert',
        Rechargefailed: 'Aufladen fehlgeschlagen',
        Savefailed: 'Speichern fehlgeschlagen',
        ordermanagement: 'Bestellungsmanagement',
        OrderList: 'Bestellungsliste',
        Ordernumber: 'Bestellnummer',
        Paymentcode: 'Zahlungscode',
        barcode: 'Barcode',
        Pleaseselectasource: 'Bitte wählen Sie die Quelle',
        OrderStatus: 'Status der Bestellung',
        Unpaid: 'unbezahlt',
        Paid: 'bezahlt',
        Shippable: 'Verfügbar für die Lieferung',
        Shipped: 'Versendet',
        complete: 'Vollständig',
        Pleaseselectpaymentmethod: 'Bitte wählen Sie die Zahlungsart',
        Shipmentstatus: 'Versandstatus',
        Notshipped: 'Nicht verschickt',
        Partialshipment: 'Teilweise versandt',
        Returned: 'Zurückgeschickt',
        Remittanceconfirmation: 'Überweisung bestätigt',
        Notremitted: 'Keine Überweisung',
        Unconfirmed: 'unbestätigt',
        adopt: 'Bestanden',
        Notpassed: 'nicht bestanden',
        Whethertomanuallyship: 'Manuelle Zustellung oder nicht',
        yes: 'Ja',
        no: 'Nein',
        Originalordertype: 'Ursprüngliche Bestellart',
        Presaleorder: 'Vorbestellung',
        Officialorder: 'Offizielle Bestellung',
        Preorderstatus: 'Status der Vorbestellung',
        Deposittobepaid: 'Zu zahlende Kaution',
        Depositpaid: 'Kaution bezahlt',
        Finalpaymenttobemade: 'Noch zu zahlender Restbetrag',
        Finalpaymentpaid: 'Restbetrag gezahlt',
        Presalepaymentmethod: 'Zahlungsart für den Vorverkauf',
        Finaldeposit: 'Endgültige Zahlung der Kaution',
        Fullpayment: 'Vollständige Zahlung',
        Whetherthepresaleorderisconvertedtoformal: 'Vorbestellung übertragen zu offizieller Bestellung oder nicht',
        Combinedorders: 'Kombinierte Bestellung',
        to: 'An',
        Startdate: 'Anfangsdatum',
        Enddate: 'Enddatum',
        Paymenttime: 'Zahlungszeitpunkt',
        Pleaseenterthesource: 'Bitte geben Sie die Quelle ein',
        platform: 'Plattform',
        Positiveconversionsingle: 'Übertragung zu offizieller Bestellung',
        Numberofproductmodels: 'Anzahl der Artikel',
        activity: 'Tätigkeit',
        money: 'Betrag',
        Paymentmethod: 'Zahlungsart',
        Deliverytime: 'Versandzeit',
        Whethertotransfertoformal: 'Übertragung zu offizieller Bestellung oder nicht',
        Tobereplied_consulted: 'zu beantworten/ zu konsultieren',
        detailed: 'Details',
        Manualshipping: 'Manuelle Lieferung',
        Manuallogisticsshipment: 'Manuelle logistische Zustellung',
        logisticscompany: 'Logistikunternehmen',
        Pleaseselectalogisticscompany: 'Bitte wählen Sie ein Logistikunternehmen aus',
        Logisticstrackingnumber: 'Logistik-Kontrollnummer',
        SelectDateTime: 'Datum und Uhrzeit auswählen',
        Shippingremarks: 'Versand-Bemerkungen',
        Pleasenote: 'Bitte beachten',
        confirm1: 'Bestätigen',
        state: 'Status',
        Pleaseselect: 'Bitte auswählen',
        Pleaseselectastatus: 'Bitte Status auswählen',
        Homepagerecommendation: 'Startseite Empfehlung',
        Pleaseenterthelogisticstrackingnumber: 'Bitte geben Sie die Trackingnummer ein',
        Pleaseselecttheshippingtime: 'Bitte wählen Sie eine Versandzeit',
        edit: 'Bearbeiten',
        Successfullyshippedmanually: 'Manuelle Zustellung erfolgreich',
        Remittanceconfirmationsuccessful: 'Überweisungsbestätigung erfolgreich',
        confirmdeletion: 'Bestätigen zum Löschen',
        Successfullydeleted: 'Erfolgreich löschen',
        Pleaseselectapresaleorder: 'Bitte Vorverkaufsbestellung auswählen',
        Confirmtoconverttheorderintoaformalorder: 'Bestätigung der Umwandlung der Bestellung in eine offizielle Bestellung',
        success: 'Erfolg',
        OrderData: 'Bestelldaten',
        Areyousuretoexportthefileandcontinue: 'Bestätigen Sie, ob Sie die Datei exportieren wollen oder nicht, fortfahren oder nicht',
        Basicinformationoftheorder: 'Grundlegende Bestellinformationen',
        Useremail: 'Benutzer-E-Mail',
        Documentremarks: 'Anmerkungen zur Bestellung',
        combination: 'Kombination',
        PostalCode: 'Postleitzahl',
        RecipientCompanyAddressAdditionalContent: 'Empfängerfirma, Adresse zusätzlicher Inhalt',
        Invoiceaddress: 'Rechnungsadresse',
        InvoiceStatus: 'Rechnungsstatus',
        Paymentinformation: 'Zahlungsinformationen',
        Paymentstatus: 'Zahlungsstatus',
        coupon: 'Gutschein',
        freight: 'Fracht',
        CouponName: 'Gutscheinname',
        Couponcode: 'Gutscheincode',
        Paymentamount: 'Zahlungsbetrag',
        Trackingnumber: 'Tracking-Bestellnummer',
        Manualshippingoperator: 'Manueller Versand-Bediener',
        Manualshippingoperationtime: 'Manuelle Lieferung Betriebszeit',
        Bankremittance: 'Banküberweisung',
        Remittancestatus: 'Überweisungsstatus',
        Remittanceconfirmationoperator: 'Überweisungsbestätigung Bediener',
        Remittanceconfirmationoperationtime: 'Zeit der Überweisungsbestätigung',
        Preorderinformation: 'Informationen zum Vorverkaufsauftrag',
        Converttoformaldocument: 'Übertragung zu offizieller Bestellung oder nicht',
        Positiveconversionsinglemode: 'Art der Übertragung zu offizieller Bestellung',
        automatic: 'automatisch',
        Manual: 'manuell',
        Positiveconversionsingletime: 'Zeitpunkt der Übertragung zu offizieller Bestellung',
        category1: 'Kategorie',
        Subordernumber: 'Unterauftragsnummer',
        Paymentdeadline: 'Zahlungsfrist',
        Addremarks: 'Bemerkungen hinzufügen',
        ProductInformation: 'Produktinformation',
        Serialnumber: 'Seriennummer',
        name: 'Name',
        VariantInformation: 'Informationen zur Variante',
        Pricing_unitprice: 'Preisangabe (Stückpreis)',
        Sellingprice_unitprice: 'Verkaufspreis (Preis pro Einheit)',
        quantity: 'Menge',
        Totalprice: 'Gesamtpreis',
        MerchandiseSubtotal: 'Zwischensumme Waren',
        total: 'Gesamt',
        CancelOrder: 'Bestellung stornieren',
        Areyousuretocancelthisorder: 'Sind Sie sicher, dass Sie die Bestellung stornieren möchten?',
        reason: 'Grund',
        Pleaseselectabrand: 'Bitte wählen Sie eine Marke',
        Pleaseselectacategory: 'Bitte wählen Sie eine Kategorie',
        Successfullyadded1: 'Erfolgreich hinzufügen',
        Addfailed: 'Hinzufügen fehlgeschlagen',
        Whethertosubmitdata: 'Daten einreichen oder nicht',
        Canceledsuccessfully: 'Erfolgreich abbrechen',
        Onlypendingorderscanbeshipped: 'Nur ausstehende Bestellungen können versendet werden',
        Existinglogisticstrackingnumber: 'Die Bestellung hat eine Logistik-Trackingnummer und kann nicht übermittelt werden',
        Thereareonlytworemittancestatuses: 'Der Übermittlungsstatus kann nur genehmigt oder nicht genehmigt sein',
        Onlyordersthathavebeenremittedcanbeconfirmed: 'Nur übermittelte Bestellungen können bestätigt werden',
        Orderdoesnotexist: 'Die Bestellung existiert nicht',
        Thisordercannotbecancelled: 'Die Bestellung kann nicht storniert werden',
        Norulesconfigured: 'Der aktuelle Mieter hat keine Aigo-Münzregeln konfiguriert',
        Parametererror: 'Parameterfehler',
        Countrycodecannotbeempty1: 'Der Ländercode kann nicht leer sein',
        Thecurrentcountrypricingisnotconfigured: 'Der aktuelle Länderpreis ist nicht konfiguriert',
        Thepriceconfigurationdoesnotexist: 'Die Preiskonfiguration ist nicht vorhanden, bitte konfigurieren Sie den Preis',
        Reasonforcancellationoforderisrequired: 'Grund für Auftragsstornierung erforderlich',
        Exportorderinformation: 'Bestellinformationen exportieren',
        OrderIDmustbepassed: 'Bestell-ID muss übergeben werden',
        Youcanonlybatchprocessupto100itemsatatime: 'Stapelverarbeitung von bis zu 100 Bestellungen auf einmal',
        Notapresaleorder: 'Bestellnummer 101 ist eine Nicht-Vorbestellung, bitte wählen Sien Sie sie zuerst ab',
        Transferredtoofficial: 'Auftragsnummer 101 wurde in einen offiziellen Auftrag übertragen, bitte zuerst deselektieren',
        Ordernotpaid: 'Bestellnummer 101 ist eine nicht bezahlte Bestellung, bitte zuerst stornieren',
        Nonpresaleorderscannotbeoperated: 'Nicht-vorverkaufte Bestellungen sind nicht verfügbar',
        Transferredtoformalformandcannotbeoperated: 'Übertragene Bestellungen sind nicht verfügbar',
        Nonpaidorderscannotbeoperated: 'Nicht verfügbar für nicht bezahlte Bestellungen',
        Orderremarksarerequired: 'Bestellnotizen sind erforderlich',
        Upto500wordscanbeentered: 'Maximal 500 Zeichen',
        common: 'in insgesamt',
        strip: 'Datensätze',
        goto: 'Gehe zu',
        page: 'Seite',
        Returnandexchangeapplication: 'Anfrage zurücksenden',
        type: 'Typ',
        Return: 'Rückgabe',
        Exchange: 'Umtausch',
        Auditstatus: 'Überprüfungsstatus',
        Approved: 'Genehmigt',
        Auditfailed: 'Gescheitert',
        Inprogress: 'in Arbeit sein',
        Auditended: 'Ende der Prüfung',
        Processingstatus: 'Bearbeitungsstatus',
        Completed: 'Abgeschlossen',
        Applicationtime: 'Anwendungszeit',
        Aftersalestype: 'Kundendienstart',
        ApplicationDescription: 'Beschreibung der Anwendung',
        Numberofexplanatoryimages: 'Beschreibung der Anzahl der Bilder',
        Illustrativeimage: 'Beschreibung der Bilder',
        Returnlogisticstrackingcode: 'Rücksende-Logistik-Tracking-Code',
        CanIcontinuetoapply: 'Soll die Anwendung fortgesetzt werden',
        Auditing: 'Prüfung',
        details: 'Details',
        handle: 'Bearbeiten',
        Add1: 'Hinzufügen',
        ApplicationDetails: 'Details anfordern',
        Exchangeitem: 'Waren umtauschen',
        Returnedgoods: 'Waren zurücksenden',
        Audittime: 'Überprüfungszeit',
        Reviewedby: 'Überprüft von',
        result: 'Ergebnis',
        Failed: 'Nicht genehmigt',
        Thereplycontentwillbedisplayedtotheuser: 'Die Antwort wird den Nutzern angezeigt',
        Submit: 'Senden',
        Replycontent: 'Inhalt der Antwort',
        Operator: 'Betreiber',
        Pleaseenterthereason: 'Bitte geben Sie einen Grund ein',
        Pleasechoosewhethertocontinueapplying: 'Bitte wählen Sien Sie aus, ob Sie sich weiter bewerben können',
        Pleaseselectaresult: 'Bitte wählen Sie das Ergebnis aus',
        Successfullymodified: 'Änderung erfolgreich',
        Verificationfailed: 'Überprüfung fehlgeschlagen',
        Datadoesnotexist: 'Daten sind nicht vorhanden',
        OrderNumber_UserID: 'Bestellnummer/Benutzer-ID',
        ReplyStatus: 'Antwort-Status',
        Replied: 'Beantwortet',
        Noresponse: 'Keine Antwort',
        MyOrder: 'Meine Bestellung',
        refund: 'Rückerstattung',
        Canwedeliverontheagreeddate: 'Können Sie zum vereinbarten Termin liefern?',
        Modifyshippingaddress: 'Lieferadresse ändern',
        Otherissuesrelatedtotransportation: 'Andere Fragen zum Versand',
        Receivedincorrectproduct: 'Ich habe das falsche Produkt erhalten',
        Receiveddamagedproductsandproductswithqualityissues: 'Beschädigte Produkte und Produkte mit Qualitätsproblemen erhalten',
        RegulationsonReturnsandRefunds: 'Regelungen zu Rücksendungen und Erstattungen',
        Applyforinvoice: 'Anfordern einer Rechnung',
        productdetails1: 'Details zum Produkt',
        Thankyouletter: 'Dankesschreiben',
        Otherissues: 'Andere Fragen',
        Respondent: 'Antwort bis',
        Consultationdate: 'Datum der Anfrage',
        content: 'Inhalt',
        explain: 'Beschreibung',
        Consultationtime: 'Uhrzeit der Anfrage',
        Replytime: 'Uhrzeit der Antwort',
        reply: 'Antwort',
        OrderInformation: 'Informationen zur Bestellung',
        Consultationcontent: 'Inhalt der Anfrage',
        Consultationinstructions: 'Anweisungen zur Anfrage',
        EnterReply: 'Eine Antwort eingeben',
        Consultationrecords: 'Verlauf der Anfrage',
        Replycontentisrequired: 'Inhalt der Antwort ist erforderlich',
        Replysuccessful: 'Antwort erfolgreich',
        Successfullyedited: 'Erfolgreich bearbeiten',
        Ordernumber_UserID_Taxnumber: 'Bestellnummer/Benutzer-ID/Steuercode',
        Unaudited: 'Ungeprüft',
        Passed: 'Genehmigt',
        Notuploaded: 'Nicht hochgeladen',
        Uploaded: 'Hochgeladen',
        AuditDescription: 'Prüfanweisungen',
        download: 'Herunterladen',
        Applying: 'Antrag in Bearbeitung',
        Enterthereason: 'Geben Sie den Grund ein. Sie müssen den Grund ausfüllen, wenn der Antrag nicht genehmigt wird.',
        Thereasonfornotpassingwillbedisplayedtotheuser: 'Die Gründe für das Nichtbestehen werden dem Benutzer angezeigt',
        Pleaseenterareply: 'Bitte geben Sie eine Antwort ein',
        Auditsuccessful: 'Erfolgreiche Prüfung',
        ProductManagement: 'Produktmanagement',
        Categorymanagement: 'Kategoriemanagement',
        Categoryname1: 'Kategoriename',
        Isthehomepagedisplayed: 'Startseite anzeigen oder nicht',
        Enableornot: 'Aktivieren oder nicht',
        search: 'Suche',
        Directoryhierarchy: 'Katalog-Ebene',
        Category: 'Kategorie',
        sort: 'Sortieren nach',
        picture: 'Bild',
        Updatetime: 'Aktualisierungszeit',
        Multilingualconfiguration: 'Mehrsprachige Konfiguration',
        Relatedimportantparameters: 'Zugehörige wichtige Parameter',
        Associatesearchparameters: 'Zugehörige Suchparameter',
        delete: 'Löschen',
        SelectAll: 'Alles auswählen',
        Imagesize: 'Bildgröße',
        icon: 'Icons',
        intotal25: '25 Artikel insgesamt',
        Gotopage1: 'Gehe zu Seite 1',
        Chinese: 'Chinesisch',
        English: 'Englisch',
        German: 'Deutsch',
        Polishlanguage: 'Polnisch',
        Italian: 'Italienisch',
        French: 'Französisch',
        Dutchlanguage: 'Niederländisch',
        Spanish: 'Spanisch',
        Pleaseenteraname: 'Bitte geben Sie einen Namen ein',
        Areyousureyouwanttodeleteit: 'Wollen Sie löschen?',
        Categoryassociation: 'Kategorie-Zuordnung',
        PrimaryKeyID: 'Primärschlüssel-ID',
        CatalogID: 'Katalog-ID',
        Addtime: 'Zeit hinzufügen',
        Relatedcategories: 'Zugehörige Kategorie',
        Selectmaincategory: 'Hauptkategorie auswählen',
        addassociations: 'Zugehörigkeit hinzufügen',
        Maincategory: 'Hauptkategorie',
        ProductCatalogID: 'Produktkatalog-ID',
        RelatedCategoryName: 'Name der zugehörigen Kategorie',
        AddAssociatedCategory: 'Zugehörige Kategorie hinzufügen',
        Pleaseselectacategoryfirst1: 'Bitte wählen Sien Sie zuerst eine Kategorie aus',
        Productvariants: 'Produkt-Variante',
        ID: 'ID',
        Add: 'Hinzufügen',
        Productquantity: 'Anzahl der Produkte',
        Selectvariant: 'Wählen Sie eine Variante',
        Selectparameters: 'Parameter auswählen',
        commodity: 'Produkt',
        Selectproduct: 'Produkt wählen',
        Colortemperature: 'Farbtemperatur',
        frequency: 'Frequenz',
        power: 'Leistung',
        style: 'Stil',
        MoveDown: 'Nach unten schieben',
        MoveUp: 'Nach oben schieben',
        Selectvariantparameters: 'Variantenparameter auswählen',
        SKU_EAN_Encoding: 'SKU/EAN/Code',
        Pleaseselectparameters: 'Bitte wählen Sie Parameter aus',
        Countrieslisted: 'Länder, in denen die Produkte ins Regal gestellt werden',
        ProductID: 'Produkt-ID',
        Productimage1: 'Produktbild',
        ProductName: 'Produktname',
        VariantManagement: 'Variantenmanagement',
        Class: 'Kategorie',
        Selected: 'Ausgewählt',
        Thereiscurrentlynodataavailable: 'Keine Daten verfügbar',
        Variantparameters: 'Parameter der Variante',
        VariantName: 'Name der Variante',
        CustomName: 'Benutzerdefinierter Name',
        Batchlisting: 'Batch ins Regal stellen',
        Pleaseselectaproduct: 'Bitte Produkte auswählen',
        Batchdelisting: 'Bulk Auslistung',
        Batchsearch: 'Batch-Suche',
        Enterproductbarcodeandsubmitforaddition: 'Produkt-Barcode eingeben, um Ergänzungen einzureichen, mehrere Barcodes sollten in einer neuen Zeile eingegeben werden',
        Enterproductbarcode: 'Produkt-Barcodes eingeben, mehrere Barcodes sollten in einer neuen Zeile eingegeben werden, bis zu 500',
        Pleaseenterbarcode: 'Bitte Barcode eingeben',
        Editlistingcountry: 'Länder bearbeiten, in denen die Produkte ins Regal gestellt werden',
        Pleasecompletetheproductinformationbeforelistingit: 'Bitte füllen Sie die Produktinformationen aus, bevor Sie sie ins Regal stellen',
        ProductTitle: 'Produkttitel',
        ProductLargePicture: 'Produktbild',
        commodityprice: 'Produktpreis',
        Confirmtheexecutionofbatchdelisting: 'Bestätigen Sie, dass Sie die Massenauslistung durchführen wollen',
        EditCategory: 'Kategorie bearbeiten',
        SynchronizeSKU: 'SKUs synchronisieren',
        ConfirmsynchronizationofSKU: 'SKU-Synchronisation bestätigen',
        SynchronizationrequiresdockingwithEBP: 'Da die Synchronisierung mit EBP durchgeführt werden muss, um Fehler zu vermeiden, kann die Batch-Synchronisierung einmal alle 30 Minuten durchgeführt werden. Bitte haben Sie etwas Geduld, da die Synchronisierung nach der Bestätigung asynchron durchgeführt wird und die SKUs angezeigt werden, sobald sie verfügbar sind.',
        Synccompleted: 'Synchronisierung abgeschlossen',
        Setlabel: 'Tags einrichten',
        label: 'Tags',
        SynchronizeEBPproductinformation: 'EBP-Produktinformationen synchronisieren',
        Afterturningonsynchronization: 'Wenn die Synchronisierung aktiviert ist, werden die folgenden Informationen für EBP-Produkte nach Produkt-SKUs synchronisiert',
        Synchronizeproducttitlesbylanguage: 'Produkttitel: synchronisieren Sie den Titel der entsprechenden Sprache nach Sprache',
        Synchronizeproductimagesbylanguage: 'Produktbild: synchronisieren Sie das Bild in der entsprechenden Sprache nach Sprache, lesen Sie die EBP-Bildsortierung, das erste EBP-Bild wird automatisch als Hauptbild festgelegt',
        Synchronizeproductimagesandtextbylanguage: 'Bilddetails: synchronisieren Sie das Bild der entsprechenden Sprache nach Sprache',
        Productkeywords: 'Produktschlüsselwörter',
        Automaticallysynchronizeornot: 'Soll automatisch synchronisiert werden',
        Doyouwanttoforcesynchronization: 'Erzwungene Synchronisation oder nicht',
        tenant: 'Mieter',
        Noinventorydisplay: 'Keine Bestandsanzeige',
        Synchronizetoothertenants: 'Mit anderen Mietern synchronisieren',
        UpdateRecords: 'Datensätze aktualisieren',
        Importtime: 'Importzeit',
        number: 'Nummer',
        file: 'Datei',
        UpdateResults: 'Ergebnis aktualisieren',
        Downloadfiles: 'Datei herunterladen',
        Isthereafixedprice: 'Gibt es einen festen Preis',
        Whethertopromoteornot: 'Im Verkauf oder nicht',
        Inpromotion: 'Im Verkauf',
        whole: 'Alle',
        Fixedprice1: 'Fester Preis',
        Lowestprice: 'Niedrigster Preis',
        fixedprice: 'Fester Preis',
        Promotionprice_unitprice: 'Aktionspreis (Stückpreis)',
        price1: 'Verkaufspreis',
        inventory: 'Bestand',
        Inventorybottomline: 'Lagerbestand unterm Strich',
        AutomaticallysynchronizeEBP: 'Soll EBP automatisch synchronisiert werden',
        ForcesynchronizationofEBP: 'Soll die EBP-Synchronisierung erzwungen werden',
        Presaleornot: 'Vorverkauf oder nicht',
        Evaluation_Shown_total: 'Bewertungen (angezeigt/insgesamt)',
        coding: 'Code',
        ProductDetails: 'Produkt-Details',
        EditProduct: 'Produkt bearbeiten',
        Basicinformation: 'Grundlegende Informationen',
        price: 'Preis',
        Pleaseentertheproducttitle: 'Bitte Produkttitel eingeben',
        Sizeinformation: 'Informationen zur Größe',
        weight: 'Gewicht',
        volume: 'Volumen',
        Salesmultiples: 'Verkaufsmultiplikator',
        Numberofitems: 'Anzahl der Artikel',
        EditFixedPrice: 'Festpreis bearbeiten',
        Largeimage: 'Großes Bild',
        Uploadimages: 'Ein Bild hochladen',
        UploadVideo: 'Ein Video hochladen',
        Batchdisplay: 'Batch-Anzeige',
        BatchCloseDisplay: 'Batch-Anzeige schließen',
        Batchdeletion: 'Stapelweise löschen',
        Synchronizetootherlanguages: 'Synchronisierung mit anderen Sprachen',
        Selectfrompicturelibrary: 'Aus der Bildergalerie auswählen',
        Pleaseenterthemaintext: 'Bitte Text eingeben',
        Hideemptyparameters: 'Leere Parameter ausblenden',
        Detailedcontentofgraphicsandtext: 'Bitte geben Sie die Grafikdetails zuerst auf Englisch ein',
        parameter: 'Parameter',
        Parametervalue: 'Parameterwert',
        ParameterProperties: 'Eigenschaften des Parameters',
        Pleaseenteraparametername: 'Bitte geben Sie den Parameternamen ein',
        Pagekeywords: 'Schlüsselwort der Seite',
        PageDescription: 'Seite Beschreibung',
        Pleaseentercontent: 'Bitte Inhalt eingeben',
        PictureLanguage: 'Bildsprache',
        Pictures_Videos: 'Bild/Video',
        ImageID: 'Bild-ID',
        language: 'Sprache',
        size: 'Größe',
        Selectapictureorvideo: 'Bitte wählen Sien Sie zuerst das Bild oder Video in der Galerie aus!',
        Selectionsuccessful: 'Erfolgreich auswählen',
        Isitthemainimage: 'Hauptbild oder nicht',
        Displayornot: 'Anzeigen oder nicht',
        Whethertodisplayall: 'Alles anzeigen oder nicht',
        Areallclosed: 'Sollen alle geschlossen werden',
        Confirmdeletionoftheselectedpicture_video: 'Bestätigen, dass das ausgewählte Bild/Video gelöscht werden soll',
        ProductReview: 'Produkt-Kommentare',
        PromotionName_ProductID_Barcode: 'Aktionsname/Produkt-ID/Barcode',
        PromotionCountry: 'Land der Aktion',
        Activitystatus: 'Status der Aktion',
        begininaminute: 'Beginnt bald',
        Ended: 'Beendet',
        Isitaflashkill: 'Ist es ein Aktionsangebot',
        PromotionName: 'Name der Aktion',
        CommentID: 'Kommentar ID',
        RatingStarRating: 'Bewertungsstern',
        star0: 'Stern',
        star1: '1 Stern',
        stars2: '2 Sterne',
        stars3: '3 Sterne',
        stars4: '4 Sterne',
        stars5: '5 Sterne',
        Commentstartdate: 'Anfangsdatum der Kommentare',
        CommentEndDate: 'Enddatum der Kommentare',
        Batchreview: 'Batch-Bewertung',
        Productimage: 'Produktbilder',
        Evaluationcontent: 'Kommentarinhalt',
        video: 'Video',
        ReviewerId: 'Prüfer-ID',
        user: 'Benutzer',
        Pendingreview: 'Zu überprüfen',
        Evaluationtime: 'Kommentarzeit',
        ReviewerName: 'Name des Rezensenten',
        Numberofselectedcomments: 'Anzahl der ausgewählten Rezensionen',
        Picturepreview: 'Bild-Vorschau',
        VideoPreview: 'Video-Vorschau',
        Lastmonth: 'Letzter Monat',
        LastThreeMonths: 'Letzte drei Monate',
        Areyousuretodelete: 'Löschen bestätigen oder nicht',
        Pleaseselectacomment: 'Bitte wählen Sie eine Rezension aus',
        Amazon: 'Amazon',
        Aigostarbrandnetwork: 'aigostar brand network',
        marketingmanagement: 'Marketing-Management',
        Productrecommendations: 'Produktempfehlung',
        Recommendedlocation: 'Standort empfehlen',
        Categoryrecommendation: 'Kategorie-Empfehlung',
        Recommendationmodule: 'Modul empfehlen',
        NewProductZone: 'Neuankömmlinge',
        HotSellingZone: 'Heiße Produkte',
        Recommendedforyou1: 'Für Sie empfohlen',
        Numberofproducts: 'Anzahl der Produkte',
        Addproduct: 'Produkt hinzufügen',
        DirectoryName: 'Katalogname',
        title: 'Titel',
        ContentTitle: 'Inhalttitel',
        EnablePlatform: 'Plattform einschalten',
        DisplayPlatform: 'Plattform anzeigen',
        catalogue: 'Katalog',
        JumpAddress: 'Zur Adresse springen',
        PageTitle: 'Titel der Seite',
        CategoryName: 'Name der Kategorie',
        Cancelleddeletion: 'Abgebrochene Löschung',
        Pleaseselectacategoryfirst: 'Bitte wählen Sie zuerst eine Kategorie aus',
        TheEnglishcategorynamecannotbeempty: 'Englischer Kategoriename kann nicht leer sein',
        Successfullysaved: 'Erfolgreich gespeichert',
        DeleteError: 'Fehler bei der Löschung',
        Apieceofcontentalreadyexists: 'Ein Inhalt existiert bereits',
        Pleaseselecttherecommendedlocation: 'Bitte wählen Sie einen empfohlenen Ort',
        Pleaseselecttherecommendedmodule: 'Bitte wählen Sie ein Empfehlungsmodul',
        remove: 'Entfernen',
        Successfullyadded2: 'Erfolgreich hinzugefügt',
        Areyousuretoremovethisproduct: 'Sind Sie sicher, dass Sie dieses Produkt entfernen möchten?',
        Successfullyremoved: 'Erfolgreich entfernt',
        Dataalreadyexists: 'Daten sind bereits vorhanden',
        PortfolioManagement: 'Portfolio-Verwaltung',
        Combinationname: 'Kombinationsname',
        Remainingquantity_combinedquantity: 'verbleibende Menge /verbleibende kombinierte Menge',
        starttime: 'Anfangszeit',
        Endtime: 'Endzeitpunkt',
        Creator: 'Erstellt von',
        NationalSDPDictionary: 'nationales sdp-Wörterbuch',
        Combinationtime: 'Kombinationszeit',
        Combinationquantity: 'Kombinationsmenge',
        CanIusecoupons: 'Gutscheine können verwendet werden oder nicht',
        Mainimage: 'Hauptbild',
        Setcombinationdiscount: 'Kombinationsrabatt einstellen',
        Combinationprice: 'Kombinationspreis',
        Combinedtotalprice: 'Gesamtpreis der Kombination',
        Isitontheshelf: 'Ist es im Regal',
        Selectupto5: 'Wählen Sie bis zu 5',
        Promotionprice1: 'Aktionspreis',
        Pleaseenterthecombinationquantity: 'Bitte geben Sie die Anzahl der Kombinationen ein',
        Pleaseenteracombinationname: 'Bitte geben Sie einen Kombinationsnamen ein',
        Upto100wordscanbeentered: 'Geben Sie bis zu 100 Zeichen ein',
        Pleaseselectadaterange: 'Bitte wählen Sie einen Datumsbereich',
        Pleaseselectastartdate: 'Bitte wählen Sie ein Startdatum',
        Pleaseselecttheenddate: 'Bitte wählen Sie ein Enddatum',
        Pleasechoosewhethertousecoupons: 'Bitte wählen Sie aus, ob der Coupon verwendet werden soll',
        Pleaseselect25items: 'Bitte wählen Sie 2-5 Artikel aus',
        Pleaseselectaproductthathasbeenlisted: 'Bitte wählen Sie einen Artikel, der in die Regale gestellt wurde',
        Inventoryofselecteditems: 'Der Lagerbestand/Verkaufsmultiplikator des ausgewählten Artikels darf nicht kleiner sein als die Aktionsmenge',
        Combinationpricecannotbeempty: 'Der Kombinationspreis darf nicht leer sein',
        Caninput0andpositiveintegers: '0 und positive ganze Zahlen können eingegeben werden, Symbole können nur als % und . eingegeben werden, bis zu zwei Dezimalstellen',
        Presaleproductscannotbeadded: 'Dies ist ein Vorverkaufsartikel und kann nicht als Kombination hinzugefügt werden.',
        Pleaseselectaproductfirst: 'Bitte wählen Sie zuerst einen Artikel aus',
        Freeshippingonfirstorder: 'Kostenloser Versand bei Ihrer ersten Bestellung',
        Firstfreeshippingitem: 'Versandkostenfreie Ware bei Ihrer ersten Bestellung',
        SelectedProduct: 'Ausgewähltes Produkt',
        Between1and50charactersinlength: 'Länge zwischen 1 und 50 Zeichen',
        Thisoperationwillremovethefile: 'Mit diesem Vorgang wird die Datei gelöscht. Möchten Sie fortfahren?',
        Brandandcountrycannotbeempty: 'Marke und Land dürfen nicht leer sein',
        Pleaseselectatime: 'Bitte wählen Sie eine Zeit',
        Editedsuccessfully: 'Erfolgreich bearbeiten!',
        Shoppingrebate: 'Einkauf-Rabatte',
        Rebateratio: 'Rabatt-Prozentsatz',
        Rebatestarttime: 'Startzeitpunkt des Rabatts',
        Rebateendtime: 'Endzeitpunkt des Rabatts',
        Rebatetime: 'Rabatt-Zeit',
        doyouwanttodelete2: 'Zu löschen oder nicht zu löschen',
        ActivityTopic: 'Ereignis Thema',
        ActivityName: 'Name des Ereignisses',
        Whethertodisplayonthehomepage: 'Soll es auf der Titelseite angezeigt werden',
        QuerySearch: 'Suche',
        TopicName: 'Name des Themas',
        Activitytime: 'Zeit des Ereignisses',
        Richtextornot: 'Rich Text oder nicht',
        ActivityDescription: 'Beschreibung der Veranstaltung',
        SpecialSEO: 'Thematische SEO',
        LogisticsPricing: 'Logistik Preisgestaltung',
        Basicfreight: 'Grundtarife für den Versand',
        Freeshippingprice: 'Preise für freien Versand',
        Deliverytimeprompt: 'Versandzeit Tipps',
        Receivingtimeprompt: 'Empfangszeit Hinweis',
        PricingSettings: 'Preisgestaltung Einstellungen',
        Freightstandard: 'Versandstandard',
        Freightallocation: 'Versandkonfiguration',
        FreightType: 'Versandart',
        Freeshipping: 'Kostenloser Versand',
        Configureshippingcosts: 'Versandkosten konfigurieren',
        Fullreductionoffreight: 'Cashback der Fracht',
        Orderamountfull: 'Bestellmenge erreicht',
        Receivingandshippingreminder: 'Empfangs- und Versanderinnerung',
        link: 'Link',
        confirm: 'Bestätigen',
        Cannotbe0: 'Kann nicht 0 sein',
        AtmosttwodigitscanbeenteredaftertheDecimalseparator: 'Es können nur bis zu zwei Dezimalstellen eingegeben werden',
        Pleaseinputthenumbercorrectly: 'Bitte geben Sie die richtige Zahl ein',
        Pleaseenterthecountry: 'Bitte geben Sie das Land ein',
        Pleaseselectshippingcost: 'Bitte wählen Sie die Versandkosten',
        Pleasecompletetheshippingcostconfiguration: 'Bitte vervollständigen Sie die Versandkonfiguration',
        Pleaseenteradeliveryprompt: 'Bitte geben Sie eine Quittungserinnerung ein',
        Pleaseenteralink: 'Bitte geben Sie einen Link ein',
        Addlogisticsconfiguration: 'Logistikkonfiguration hinzufügen',
        Editlogisticsconfiguration: 'Logistikkonfiguration bearbeiten',
        Pleaseenterthelogisticscompany: 'Bitte geben Sie ein Logistikunternehmen ein',
        Editingfailed: 'Bearbeiten fehlgeschlagen',
        Addlogisticscompany: 'Logistikunternehmen hinzufügen',
        Pleaseuploadtheicon: 'Bitte laden Sie ein Symbol hoch',
        Pleaseenterthelinkcorrectly: 'Bitte geben Sie den Link korrekt ein',
        Successfullyadded: 'Erfolgreich hinzugefügt',
        Editlogisticscompany: 'Logistikunternehmen bearbeiten',
        Thisisalreadythefirstitemmoveupfailed: 'Bereits erste Position, Verschiebung nach oben fehlgeschlagen',
        Thisisalreadythelastitemmovedownfailed: 'Bereits letzte Position, Verschiebung nach unten fehlgeschlagen',
        Costprice: 'Einstandspreis',
        taxrate: 'Steuersatz',
        commission: 'Provisionen',
        Grossmargin: 'Bruttomarge',
        doyouwanttodelete: 'Die eingegebene Zahl ist zu groß',
        Pleaseselectapricetype: 'Bitte wählen Sie einen Preistyp',
        Pleasecompletethetaxrateconfiguration: 'Bitte vervollständigen Sie die Konfiguration des Steuersatzes',
        Pleasecompletethecommissionconfiguration: 'Bitte vervollständigen Sie die Konfiguration der Provisionen',
        PleasecompleteGrossmarginconfiguration: 'Bitte vervollständigen Sie die Konfiguration des Bruttosatzes',
        Weightrange_start_kg: 'Gewichtsbereich (Anfang) kg',
        Weightrange_end_kg: 'Gewichtsbereich (Ende) kg',
        YoucanonlyenteruptofourdigitsaftertheDecimalseparator: 'Es können maximal vier Dezimalstellen eingegeben werden',
        Theendvaluemustbegreaterthanorequaltothestartvalue: 'Der Endwert muss größer oder gleich dem Startwert sein',
        Pleasecompletetheweightrangeconfiguration: 'Bitte vervollständigen Sie die Konfiguration des Gewichtsbereichs',
        Pleaseentertheshippingcost: 'Bitte geben Sie die Versandkosten ein',
        Addshippingstandard: 'Versandstandard hinzufügen',
        EditFreightStandard: 'Versandstandard bearbeiten',
        Allocationofpatrioticcoins: 'Aigo Coins Konfiguration',
        RuleConfiguration: 'Konfiguration der Regeln',
        ObtainingPatrioticCoinObject: 'Gegenstände des Erhalts von Aigo-Münzen',
        Rewardconditionsobtained: 'Bedingungen für den Erhalt von Belohnungen',
        Obtainpatrioticcoinallocation: 'Erhalten Sie die Aigo-Münzen-Konfiguration',
        Inputquantity: 'Menge eingeben',
        EnterName: 'Name eingeben',
        Registrationsuccessfulnot: 'Erfolgreiche Registrierung (kein Einladungscode verwenden)',
        Inviteduserplacesanorder: 'Eingeladener Benutzer gibt Bestellung auf',
        Registrationsuccessful: 'Registrierung erfolgreich (Einladungscode verwenden)',
        Registeredaccount: 'Registriertes Konto (registriertes Land)',
        Registrationsuccessfulnot1: 'Erfolgreiche Kontoregistrierung ohne Einladungscode',
        Numberoflocalcurrencies: 'Die unten konfigurierten Mengen sind in der nationalen Währung des entsprechenden Landes',
        Inviteraccount: 'Einladungskonto ( Konto mit Einladungscode, Land mit Einladungscode angeben)',
        Provideinvitationcode: 'Den Einladungscode an andere Nutzer weitergeben, andere Nutzer nutzen den Einladungscode, um sich zu registrieren und dann erfolgreich eine Bestellung abzuschließen (jeder eingeladene Nutzer kann nur 1 Bonus erhalten)',
        Usinganinvitationcode: 'Konto erfolgreich mit dem Einladungscode registrieren',
        ProductPromotion: 'Produktwerbung',
        discount: 'Rabatte',
        Freeshippingornot: 'Kostenloser Versand oder nicht',
        Secondkillsorting: 'Aktionsprogrammsortierung',
        surplus: 'Verbleibende',
        Totalnumber: 'Gesamt',
        ImportFile: 'Dateien importieren',
        Selectupto50: 'Bis zu 50 auswählen',
        Setuppromotions: 'Werbung einstellen',
        Nocountrieslisted: 'Keine Länder, in denen die Produkte ins Regal gestellt werden',
        Promotiontime: 'Aktionszeit',
        Displaypromotionalprices: 'Soll der Aktionspreis angezeigt werden',
        PromotionPrice: 'Aktionspreis',
        Promotionquantity: 'Aktionsmenge',
        Doyousupporttheuseofcoupons: 'Soll die Verwendung von Coupons unterstützt werden',
        Purchaserestrictionsforthesameuser: 'Kaufbeschränkungen für denselben Nutzer',
        Singleorderlimit: 'Begrenzung auf eine einzige Bestellung',
        CurrentactivitypricesinSpain: 'Die Bruttomarge auf den aktuellen Aktionspreis in {countryCode}',
        SubmitConfirmation: 'Bestätigung einreichen',
        Productdistribution: 'Produktverteilung',
        DistributionName: 'Name des Vertriebs',
        DistributionCountry: 'Land des Vertriebs',
        Distributionprice1: 'Vertriebspreis',
        Distributiontime: 'Vertriebszeit',
        DistributionPrice: 'Vertriebspreis',
        Distributionquantity: 'Menge der Verteilung',
        CopyConnection: 'Link kopieren',
        Successfullycopied: 'Erfolgreich kopiert',
        Countryofuse: 'Land der Nutzung',
        Discounttype: 'Art des Angebots',
        Fullreduction: 'Cashback',
        Isthereafixedcode: 'Gibt es einen festen Code',
        Effective: 'Gültig',
        Comingsoon1: 'Bald gültig',
        Expired: 'Abgelaufen',
        Overlayornot: 'stapeln oder nicht',
        Istheexchangecodefixed: 'Soll der Einlösecode fixiert werden',
        Received: 'erhalten',
        Used: 'Verwendet',
        Availableamount: 'Verfügbarer Betrag',
        Maximumdeductionamount: 'Maximaler Abzugsbetrag',
        Canitbestacked: 'Stapelbar oder nicht',
        Redemptioncode: 'Einlösungscode',
        Fullreductionamount: 'Cashback-Betrag',
        Enterredemptioncode: 'Einlösecode eingeben',
        Numberofredemptioncodes: 'Anzahl der Codes',
        Numberofusesbythesameuser: 'Anzahl der Nutzungen für denselben Nutzer',
        Effectivetime: 'Gültige Zeit',
        Isitpossibletooverlaydistribution: 'Kann mit oder ohne Verteilung gestapelt werden',
        Canpromotionsbestacked: 'Kann mit oder ohne Promotion gestapelt werden',
        Entercoupondescription: 'Gutscheinanweisungen eingeben',
        Pleaseenteradescription: 'Bitte geben Sie eine Beschreibung ein',
        Pleaseselectadate: 'Bitte wählen Sie ein Datum',
        Pleaseenterthenumberoftimesthesameuserhasusedit: 'Bitte geben Sie die Anzahl der Nutzungen für denselben Nutzer ein',
        Pleaseenteranavailableamountof0toindicateunlimited: 'Bitte geben Sie den verfügbaren Betrag von 0 ein, um eine unbegrenzte Anzahl anzugeben',
        Pleaseentertheavailableamount: 'Bitte geben Sie den verfügbaren Betrag ein',
        Thenumberofexchangecodescanonlybegreaterthantheoriginal: 'Die Anzahl der Einlösecodes kann nur größer sein als die des Originals',
        Pleaseenterthenumberofredemptioncodes: 'Bitte geben Sie die Anzahl der Einlösecodes ein',
        Applicableclassification: 'Anwendbare Kategorien',
        Applicableproducts: 'Anwendbare Produkte',
        Useordernumber: 'Bestellnummer verwenden',
        Claimstatus: 'Abholstatus',
        Notclaimed: 'Nicht abgeholt',
        notused: 'Nicht verwendet',
        Usagestatus: 'Verwendungsstatus',
        Collectiontime: 'Abholzeit',
        Usagetime: 'Nutzungszeit',
        UsingUsers: 'Benutzer verwenden',
        BannerManagement: 'Banner Verwaltung',
        position: 'Standort',
        JumpType: 'Art des Sprungs',
        Nojump: 'Kein Sprung',
        Homepagerecommendationposition: 'Startseite vorgestellt',
        Homebanner: 'Home-Banner',
        Displaytime: 'Zeit anzeigen',
        JumpLink: 'Link springen',
        Suggestedsize: 'Vorgeschlagene Größe',
        Enterjumplink: 'Sprunglink eingeben',
        Uploadingimagesonlysupports: 'Nur Bilder in jpg, png, gif, jpeg hochladen',
        Topwithlargenumbers: 'Standardwert ist 0, je größer die Zahl ist, desto höher ist der Rang',
        Pleaseselectatleastonecountry: 'Bitte wählen Sie mindestens ein Land aus',
        Boutiquerecommendationspace: 'Boutique-Empfehlungsbereich',
        Fullproductrecommendation: 'Vollständige Produktempfehlung',
        popupnotification: 'Pop-up-Fenster',
        Addbanner: 'Banner hinzufügen',
        subscriptionmanagement: 'Abonnement-Verwaltung',
        Emailaddress: 'E-Mail Adresse',
        Subscribing: 'Abonnement in Bearbeitung',
        Canceled: 'Abgebrochen',
        SubscriptionSource: 'Quelle für das Abonnement',
        Unsubscribe: 'Abonnement kündigen',
        Resubscribe: 'Abbestellen',
        Pleaseselectatimerange: 'Bitte wählen Sie eine Zeitspanne',
        WebsiteManagement: 'Website-Verwaltung',
        informationservice: 'Informationsdienste',
        New: 'erstellen',
        Isitdisplayedatthebottomofthewebsite: 'Am unteren Rand der Website anzeigen oder nicht',
        Pleaseselectaninformationservice: 'Bitte wählen Sie den Informationsdienst',
        deleteconfirmation: 'Soll die Löschung des Knotens und aller Informationen unter dem Knoten bestätigt werden',
        Pleaseenteracolumnname: 'Bitte geben Sie einen Spaltennamen ein',
        Thelengthcannotexceed50characters: 'Die Länge darf 50 Zeichen nicht überschreiten',
        ThelengthcannotexceedXcharacters: 'Die Länge darf {num} Zeichen nicht überschreiten',
        Pleaseentersorting: 'Bitte geben Sie die Reihenfolge ein',
        Selectedcountrycontentisrequired: 'Der ausgewählte Länderinhalt ist erforderlich',
        TitleName: 'Name des Titels',
        Chinesetitleisrequired: 'Der chinesische Titel ist erforderlich',
        Selectedcountrytitleisrequired: 'Der Titel des ausgewählten Landes ist erforderlich',
        EditingInformationServices: 'Informationsdienst bearbeiten',
        ColumnName: 'Spaltenname',
        Pleaseenterthecreationtime: 'Bitte geben Sie die Erstellungszeit ein',
        Pleaseenterwhethertodisplayatthebottomofthewebsite: 'Bitte geben Sie ein, ob sie unten auf der Website angezeigt wird',
        ViewInformationServices: 'Informationsdienst anzeigen',
        Modificationtime: 'Zeit der Änderung',
        Countrycannotbeempty: 'Land darf nicht leer sein',
        Titlecannotbeempty: 'Der Titel darf nicht leer sein',
        Richtextcontentonthepagecannotbeempty: 'Der Rich-Text-Inhalt der Seite darf nicht leer sein',
        Nationalconfiguration: 'Länderkonfiguration',
        currency: 'Währung',
        allocation: 'Konfiguration',
        Statuseditedsuccessfully: 'Status erfolgreich bearbeitet',
        Areyousuretocontinuedeletingdata: 'Sind Sie sicher, dass Sie mit dem Löschen der Daten fortfahren wollen?',
        WebsiteAddress: 'Adresse der Website',
        Contactinformation: 'Kontaktinformationen',
        Customerserviceemail: 'Kundenservice E-Mail',
        Socialmedia: 'Soziale Medien',
        Socialmediainformation: 'Informationen zu den sozialen Medien',
        Pleasechooseapaymentmethod: 'Bitte wählen Sie eine Zahlungsmethode',
        Lengthgreaterthan20characters: 'Länge größer als 20 Zeichen',
        Lengthgreaterthan100characters: 'Länge größer als 100 Zeichen',
        Successfullydeletedinformation: 'Nachricht erfolgreich löschen',
        Iconmustbeuploaded: 'Icon muss hochgeladen werden',
        Successfullyaddedinformation: 'Nachricht erfolgreich hinzufügen',
        Successfullyeditedinformation: 'Nachricht erfolgreich bearbeiten',
        Pleaseentertheemailcorrectly: 'Bitte geben Sie Ihre E-Mail korrekt ein',
        Thesizeoftheuploadedimagecannotexceed: 'Die Größe des hochgeladenen Bildes darf nicht größer sein als',
        Countryname: 'Name des Landes',
        Pleaseuploadpictures: 'Bitte laden Sie ein Bild hoch',
        NewCountryInformation: 'Neue Länderinformationen erstellen',
        EditCountryInformation: 'Länderinformationen bearbeiten',
        Picturesmustbetransmitted: 'Bilder müssen hochgeladen werden',
        NewCountryInformationTableImageAddress: 'Neu erstelltes Länderinformationsblatt， Bild und Adresse',
        Operationsuccessful: 'Erfolgreicher Vorgang',
        Euro: 'Euro',
        pound: 'Pfund',
        Zloti: 'Zloty',
        Brandcannotbeempty: 'Marke darf nicht leer sein',
        Thewebsiteaddresscannotbeempty: 'Die Website-Adresse darf nicht leer sein',
        Contactinformationcannotbeempty: 'Kontaktinformationen dürfen nicht leer sein',
        Thecustomerserviceemailaddresscannotbeempty: 'Die E-Mail-Adresse des Kundendienstes darf nicht leer sein',
        Medianamecannotbeempty: 'Medienname darf nicht leer sein',
        Themediaiconcannotbeempty: 'Das Mediensymbol darf nicht leer sein',
        Medialinkcannotbeempty: 'Medienlink darf nicht leer sein',
        Thecountryiconcannotbeempty: 'Das Ländersymbol darf nicht leer sein',
        Languagecannotbeempty: 'Sprache darf nicht leer sein',
        Currencycannotbeempty: 'Die Währung darf nicht leer sein',
        Pagedescriptioncannotbeempty: 'Seitenbeschreibung darf nicht leer sein',
        Statusenable_disablecannotbeempty: 'Status Aktivieren/Deaktivieren darf nicht leer sein',
        SEOManagement: 'SEO-Verwaltung',
        Import: 'Importieren',
        DownloadImportTemplate: 'Importvorlage herunterladen',
        PageURL: 'Seiten-URL',
        SEOdata: 'SEO-Daten',
        SEOConfigurationTemplate: 'SEO-Konfigurationsvorlage',
        Downloadsuccessful: 'Erfolgreich herunterladen',
        SEOManagementImport: 'SEO Management Import',
        DragtheExcelfilehere_orclickonupload: 'Ziehen Sie die Excel-Datei hierher oder klicken Sie auf Hochladen',
        Fileimportedsuccessfully: 'Datei erfolgreich importiert',
        Fileimportfailed_pleasecheckthefilecontent: 'Datei-Import fehlgeschlagen, bitte überprüfen Sie den Inhalt der Datei',
        Pleaseenterthelanguage: 'Bitte geben Sie die Sprache ein',
        Thelengthcannotexceed2characters: 'Die Länge darf 2 Zeichen nicht überschreiten',
        PleaseenterthepageURL: 'Bitte geben Sie die URL der Seite ein',
        Thelengthcannotexceed100characters: 'Die Länge darf 100 Zeichen nicht überschreiten',
        Thelengthcannotexceed20characters: 'Die Länge darf 20 Zeichen nicht überschreiten',
        No_Illegalrowdata: 'Die ? Zeile der Daten ist illegal',
        Failedtouploadfile_Pleasecheckthefilecontent: 'Dateiupload fehlgeschlagen, bitte überprüfen Sie den Inhalt der Datei',
        Namecannotbeempty: 'Der Name darf nicht leer sein',
        PageURLcannotbeempty: 'Die URL der Seite darf nicht leer sein',
        Thepagetitlecannotbeempty: 'Der Seitentitel darf nicht leer sein',
        SEOManagementImportTemplate: 'SEO-Management-Importvorlage',
        Donotmodify: 'Bitte ändern Sie die ersten drei Zeilen des Vorlagentitels nicht',
        Required: 'Mit „*“ markierte Felder sind Pflichtfelder',
        Brandandlanguagemustbefullycapitalized: 'Marke und Sprache müssen in Großbuchstaben angegeben werden',
        SEOManagementExport: 'SEO Management Export',
        Pagekeywordscannotbeempty: 'Seitenschlüsselwörter dürfen nicht leer sein',
        Prohibitedsalesarea: 'Verbotene Verkaufsgebiete',
        Pleaseenterthepostalcodecorrectly: 'Bitte geben Sie Ihre Postleitzahl korrekt ein',
        Pleasefillinthepostalcode: 'Bitte geben Sie Ihre Postleitzahl ein',
        Countrycodecannotbeempty: 'Der Ländercode darf nicht leer sein',
        Postalcodecannotbeempty: 'Die Postleitzahl darf nicht leer sein',
        Postalcodealreadyexists: 'Postleitzahl existiert bereits',
        Homepagearticle: 'Homepage-Artikel',
        BrandStory: 'Markengeschichten',
        SciencePopularizationZone: 'Zone zur Popularisierung der Wissenschaft',
        Lightstripzone: 'Lichtleisten-Bereich',
        Pictureandtext: 'Bild und Text',
        Comingsoon: 'Freigabe bald',
        Publishinginprogress: 'Freigabe in Arbeit',
        column: 'Säule',
        Releasetime: 'Datum der Veröffentlichung',
        Pleaseenteratitle: 'Bitte geben Sie einen Titel ein',
        Pleaseselectacolumn: 'Bitte wählen Sie eine Spalte',
        Pleaseselectatype: 'Bitte wählen Sie einen Typ',
        Patrioticcoinusagelog: 'Aigo Coins Verwendungsprotokoll',
        income: 'Einnahmen',
        expenditure: 'Ausgaben',
        mode: 'Methode',
        Orderexpenses: 'Bestellung Ausgaben',
        Orderrewards: 'Belohnungen für Bestellungen',
        OrderCloseReturn: 'Bestellungsabschluss und Rückgabe',
        other: 'Andere',
        Backendrecharge: 'Hintergrundaufladung',
        Orderrebate: 'Bestellungs-Rabatt',
        Numberofchangesinpatrioticcoins: 'Anzahl der geänderten Aigo-Münzen',
        Numberofpatrioticcoinsafterchanges: 'Anzahl der Aigo-Münzen nach der Änderung',
        CountryCode: 'Ländercode',
        Changetime: 'Zeitpunkt des Wechsels',
        Pleaseselecttheuser_spatrioticcoinconsumptionrecord: 'Bitte wählen Sie den Aigo-Münzverbrauch des Benutzers aus',
        WebsiteNavigation: 'Website-Navigation',
        HomepageConfiguration: 'Konfiguration der Startseite',
        Navigationcontent: 'Inhalt der Navigation',
        Recommendedforyou: 'Für Sie empfohlen',
        SelectNavigation: 'Navigation auswählen',
        Pleasecheckfirst: 'Bitte zuerst ankreuzen',
        Logmanagement: 'Log-Verwaltung',
        UserID_IP_LoginDevice: 'Benutzer-ID/IP/Login-Gerät',
        logintime: 'Login-Zeit',
        Loginmethod: 'Login-Methode',
        IPaddress: 'IP-Adresse',
        Logindevice: 'Login-Gerät',

        //11-22
        AssociatedOrderNumber: 'Zugehörige Bestellnummer',
        recordId: 'Datensatz-ID',

        UserAccess: 'Benutzerzugang',
        PleaseSelectTheStatisticalType: 'Bitte wählen Sie die Art der Statistik',
        StatisticalByDate: 'Statistik nach Datum',
        StatisticalBySource: 'Statistik nach Quelle',
        Date: 'Datum',
        Visits: 'Besuche',
        NewVisitors: 'Neue Besucher',
        OrderStatistics: 'Bestellungsstatistik',
        Sales: 'Verkäufe',
        TotalOrders: 'Bestellungen insgesamt',
        AverageOrderAmount: 'Durchschnittlicher Bestellwert',
        SynchronizeEBP: 'EBP synchronisieren',
        ParameterName: 'Name des Parameters',
        OnTheShelf: 'Auf dem Regal',
        OfflineShoppingCart: 'Offline-Warenkorb',
        AddToCart: 'Zum Warenkorb hinzufügen',
        EnterCheckout: 'Zur Kasse gehen',
        UserLogin: 'Benutzeranmeldung',
        UserRegistration: 'Benutzer-Registrierung',
        DifferentIPNumbersForAddingItemsToTheShoppingCart: 'Unterschiedliche IP-Nummern für das Hinzufügen von Artikeln zum Einkaufswagen',
        DifferentIPNumbersForEnteringTheOrderConfirmationPage: 'Unterschiedliche IP-Nummern für das Aufrufen der Bestellbestätigungsseite',
        DifferentIPNumbersForWhoCompletedTheLoginProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheLoginPage: 'Unterschiedliche IP-Nummern für diejenigen, die den Anmeldevorgang abgeschlossen haben, nachdem sie vom Offline-Warenkorb zur Anmeldeseite weitergeleitet wurden.',
        DifferentIPNumbersForWhoCompletedTheRegistrationProcessAfterBeingRedirectedFromTheOfflineShoppingCartToTheRegisterPage: 'Unterschiedliche IP-Nummern für diejenigen, die den Registrierungsprozess abgeschlossen haben, nachdem sie vom Offline-Warenkorb auf die Registrierungsseite weitergeleitet wurden.',
        UserBehavior: 'Benutzerverhalten',
        Display: 'Anzeigen',
        DoNotDisplay: 'Nicht anzeigen',
        AddToPresale: 'Zum Vorverkauf hinzufügen',
        CancelPresale: 'Vorverkauf abbrechen',
        PresaleConfiguration: 'Konfiguration des Vorverkaufs',
        OffTheShelf: 'Aus dem Regal',
        PleaseCompleteTheProductInformationBeforeSettingUpThePresale: 'Bitte füllen Sie die Produktinformationen aus, bevor Sie den Vorverkauf einrichten',
        SynchronizedEBPPrompts: 'Aufgrund der notwendigen Synchronisation mit EBP kann die Batch-Synchronisation nur einmal innerhalb von X Minuten durchgeführt werden, um Fehler zu vermeiden. Nach der Bestätigung wird die Synchronisierung asynchron durchgeführt, und die SKU wird sofort nach Erhalt angezeigt. Bitte haben Sie etwas Geduld.',
        PleaseWait: 'Bitte warten',
        PleaseSelectATag: 'Bitte wählen Sie einen Tag',
        Updating: 'Aktualisierung ',
        Abortive: 'Fehlgeschlagen',
        DefaultRule: 'Standard-Regel',
        BottomInventory: 'Unteres Inventar',
        SynchronisedAlertsForGraphicDetails: 'Grafische Details: Synchronisieren Sie die [Produktschlüsselwörter] der entsprechenden Sprache entsprechend der Sprache',
        SynchronisedAlertsForLargeProductImages: 'Produktbild: Das Bild der entsprechenden Sprache wird mit der Sprache synchronisiert, die EBP-Bildsortierung wird gelesen und das erste EBP-Bild wird automatisch als Hauptbild festgelegt.',
        Account: 'Konto',
        Account1: 'Konto',
        EnterUpToNumItems: 'Eingabe von bis zu {num} Artikeln',
        SynchronizedSuccessfully: 'Erfolgreich synchronisiert',
        SuccessfullyListed: 'Erfolgreich aufgelistet',
        SearchSuccessful: 'Suche erfolgreich',
        EnterNewPassword: 'Neues Passwort eingeben',
        OnlyNumbersAndLettersAreAllowed: 'Nur Zahlen und Buchstaben sind erlaubt',
        PleaseCompleteThePresaleConfigurationBeforeListingThePresaleItem: 'Bitte vervollständigen Sie die Vorverkaufskonfiguration, bevor Sie den Vorverkaufsartikel einstellen',
        ProductWeight: 'Produktgewicht',
        ConfirmExecution: 'Ausführung bestätigen?',
        ConfirmBatchListing: 'Chargenauflistung bestätigen?',
        Template: 'Vorlage',
        PleaseSelectTheProductsToBeSynchronized: 'Bitte wählen Sie die zu synchronisierenden Produkte aus',
        ForcedSynchronisationDescription: 'Die erzwungene Synchronisierung löscht die geänderten Daten und ersetzt sie durch synchronisierte Daten. Bei einer gewöhnlichen Synchronisierung werden die geänderten Produktdaten nicht synchronisiert.',
        ConfirmSynchronizationExecution: 'Bestätigen Sie die Ausführung der Synchronisation',
        SynchronisationHasBeenSentPleaseWait: 'Die Nachricht über die Synchronisierungsaufgabe wurde gesendet. Bitte warten Sie geduldig, bis die Datensynchronisierung abgeschlossen ist.',
        ProhibitLogin: 'Anmeldung verbieten',
        IsTheUserLoggedIn: 'Ist der Benutzer eingeloggt?',
        Prohibit: 'Verbieten',
        ProhibitedSuccessfully: 'Erfolgreich verboten',
        EnableLogin: 'Anmeldung freischalten',
        EnabledSuccessfully: 'Erfolgreich freigeschaltet',
        OperationCanceled: 'Vorgang abgebrochen',
        UserData: 'Benutzerdaten.xlsx',
        GraphicDetails: 'Grafische Details',
        CombinationItemsAreNotAvailableForPresalePleaseRemoveTheCombinationFirst: 'Dieses Produkt wurde als Kombinationsprodukt eingestellt und kann nicht für den Vorverkauf eingestellt werden. Bitte entfernen Sie das Produkt zuerst aus der Kombination?',
        PromotionalItemsAreNotAvailableForPresalePleaseCancelThePromotionFirst: 'Dieses Produkt ist ein Werbeartikel und kann nicht für den Vorverkauf eingestellt werden. Bitte stornieren Sie zuerst die Aktion.',
        DistributedProductsAreNotAvailableForPresalePleaseCancelTheDistributionFirst: 'Dieses Produkt ist ein Distributionsprodukt und kann nicht für den Vorverkauf eingestellt werden. Bitte stornieren Sie zuerst den Vertrieb.',
        ThisProductAlreadyHasAFullReductionActivityAndCannotBeAdded: 'Dieses Produkt hat bereits eine volle Rabattaktion und kann nicht hinzugefügt werden.',
        ESNoPriceNotAvailableForPresale: 'Dieses Produkt hat keinen Preis in {Any} und kann nicht in den Vorverkauf aufgenommen werden. Bitte entfernen Sie zuerst das entsprechende Land oder synchronisieren Sie den Preis des entsprechenden Landes, bevor Sie Vorverkaufsprodukte hinzufügen!',
        ConfirmSettingThisProductAsAPresaleItem: 'Bestätigen Sie die Einstellung dieses Produkts als Vorverkaufsartikel?',
        PresaleAddedSuccessfully: 'Vorverkaufsartikel erfolgreich hinzugefügt',
        ConfirmSettingThisProductAsANonpresaleItem: 'Bestätigen Sie die Einstellung dieses Produkts als Nicht-Vorverkaufsartikel?',
        CancelPresaleSuccessful: 'Vorverkauf erfolgreich abbrechen',
        Preview: 'Vorschau',
        TheInventoryOfCurrentProducts0Continue: 'Der Bestand des aktuellen Produkts {stock} ist 0, weiter?',
        TheInputContentCannotExceedCharacters: 'Der eingegebene Inhalt darf {x} Zeichen nicht überschreiten',
        PleaseSelectTheActivity: 'Bitte wählen Sie die Aktivität',
        OrderDetails: 'Details zur Bestellung',
        LogisticsInformation: 'Logistische Informationen',
        CancelRemark: 'Bemerkung stornieren',
        DepositAmount: 'Betrag der Anzahlung',
        FinalPaymentAmount: 'Betrag der Restzahlung',
        FullReductionDiscount: 'Voller Preisnachlass',
        YourBrowserDoesNotSupportVideoPlayback: 'Ihr Browser unterstützt keine Videowiedergabe',
        EditBanner: 'Banner bearbeiten',
        AddBanner: 'Banner hinzufügen',
        UpdateTime: 'Zeit aktualisieren',
        HomepageRecommendationPosition: 'Position der Homepage-Empfehlung',
        ActivityID: 'Aktivitäts-ID',
        PleaseSelectThePosition: 'Bitte wählen Sie die Position',
        PleaseSelectTheJumpType: 'Bitte wählen Sie den Sprungtyp',
        PleaseEnterTheAssociatedID: 'Bitte geben Sie die zugehörige ID ein',
        TheUploadedImageSizeCannotExceed5MB: 'Die Größe des hochgeladenen Bildes darf {num} MB nicht überschreiten!',
        OnlyMp4FormatIsSupportedForVideoUpload: 'Für den Video-Upload wird nur das mp4-Format unterstützt',
        TheUploadedVideoSizeCannotExceed500MB: 'Die Größe des hochgeladenen Videos darf {num} MB nicht überschreiten!',
        PleaseUploadTheVideo: 'Bitte laden Sie das Video hoch',
        PleaseEnterTheJumpLink: 'Bitte geben Sie den Sprunglink ein',
        PleaseEnterTheCorrectLink: 'Bitte geben Sie den richtigen Link ein',
        VerificationFailedPleaseEnterTheRequiredFieldsFirst: 'Verifizierung fehlgeschlagen, bitte geben Sie zuerst die erforderlichen Felder ein',
        AddFailed: 'Hinzufügen fehlgeschlagen',
        ItIsAlreadyTheFirstOneAndTheUpwardMovementFailed: 'Es ist bereits der erste, und die Aufwärtsbewegung ist fehlgeschlagen',
        PleaseEnterTheRebateRatio: 'Bitte geben Sie die Rabattquote ein',
        EditCoupon: 'Coupon bearbeiten',
        AddCoupon: 'Coupon hinzufügen',
        IDCouponName: 'ID/Gutscheinname/Einlösungscode',
        Random: 'Zufällig',
        Fixed: 'Festgelegt',
        AboutToTakeeffect: 'Wird in Kürze wirksam',
        NotStackable: 'Nicht stapelbar',
        Stackable: 'Stapelbar',
        Frozen: 'Eingefroren',
        PleaseEnterTheMaximumDeductionAmount0MeansUnlimited: 'Bitte geben Sie den maximalen Abzugsbetrag ein, 0 bedeutet unbegrenzt',
        UseUserID: 'Benutzer-ID verwenden',
        UseOrderNumberExchangeCodeUserID: 'Bestellnummer/Tauschcode/Benutzerkennung verwenden',
        PleaseEnterTheDiscount: 'Bitte geben Sie den Rabatt ein',
        OnlyNumbersFrom0To100CanBeEnteredUpTo1DecimalPlace: 'Es können nur Zahlen von 0 bis 100 eingegeben werden, mit bis zu 1 Dezimalstelle',
        PleaseEnterTheFullReductionAmount: 'Bitte geben Sie den vollen Abzugsbetrag ein',
        PleaseEnterTheExchangeCode: 'Bitte geben Sie den Umtauschcode ein',
        OnlyNumbersLettersCanBeEnteredAtLeast6DigitsUpTo20Digits: 'Es können nur Zahlen/Buchstaben eingegeben werden, mindestens 6-stellig, bis zu 20-stellig',
        PleaseSelectAFixedExchangeCode: 'Bitte wählen Sie einen festen Umtauschcode',
        PleaseEnterTheMaximumDeductionAmount: 'Bitte geben Sie den maximalen Abzugsbetrag ein',
        PleaseSelectWhetherItCanbeStacked: 'Bitte wählen Sie aus, ob er gestapelt werden kann',
        WarehousingTime: 'Einlagerungszeit',
        EnterActivityDescription: 'Beschreibung der Tätigkeit eingeben',
        ActivityTitleCannotBeEmpty: 'Der Titel des Vorgangs darf nicht leer sein',
        TheTitleCannotExceed200Characters: 'Der Titel darf nicht länger als {num} Zeichen sein',
        PleaseEnterTheActivityDescription: 'Bitte geben Sie die Beschreibung der Aktivität ein',
        ActivityTimeCannotBeEmpty: 'Die Aktivitätszeit darf nicht leer sein',
        ActivityDescriptionLimitOf10000Words: 'Die Beschreibung der Aktivität darf nicht mehr als {num} Wörter umfassen',
        ActivityRecommendation: 'Empfehlung der Aktivität',
        Promotion: 'Förderung',
        EveryFull: 'Alle {num} voll',
        EnterAmount: 'Betrag eingeben',
        OnlyPositiveNumbersCanBeEnteredUpTo2DecimalPlacesCannotStartWith0: 'Es können nur positive Zahlen mit bis zu 2 Dezimalstellen eingegeben werden, die nicht mit 0 beginnen dürfen.',
        PleaseEnterTheCorrectAmount: 'Bitte geben Sie den korrekten Betrag ein',
        PleaseSelectApplicableCategoriesOrApplicableProducts: 'Bitte wählen Sie zutreffende Kategorien oder zutreffende Produkte aus',
        Rules: 'Regeln',
        CopyLink: 'Link kopieren',
        DistributionNameProductIDBarcode: 'Vertriebsname/Produkt-ID/Barcode',
        ThisSubFunctionIsNotAvailableInThisCountryForTheTimeBeingAndWillBeAvailableLater: 'Diese Unterfunktion ist in diesem Land zur Zeit nicht verfügbar, wird aber später verfügbar sein.',
        PleaseEnterThePrice: 'Bitte geben Sie den Preis ein',
        AutomaticallyCalculateAfterEnteringTheDiscount: 'Wird nach Eingabe des Rabatts automatisch berechnet',
        PleaseEnterTheQuantity: 'Bitte geben Sie die Menge ein',
        OrderRemarkLabel: 'Etikett der Bestellbemerkung',
        OrdersWorkWithInfluencers: 'Bestellungen arbeiten mit Influencern',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs: 'Die aktuelle Bruttogewinnspanne des aktuellen Tätigkeitspreises ist',
        ThePriceCannotBeLowerThanTheLowestPrice: 'Der Preis kann nicht niedriger sein als der niedrigste Preis:',
        Distribution: 'Vertrieb',
        IDName: 'ID/Name',
        ProductSelected: 'Produkt ausgewählt',
        ProductHasNoPricing: 'Das Produkt hat keinen Preis',
        TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIsvariableAreYouSureToSubmit: 'Die aktuelle Bruttogewinnspanne des aktuellen Aktivitätspreises beträgt {rate} %, sind Sie sicher, dass Sie das Produkt einreichen möchten?',
        SubscriptionStatus: 'Status des Abonnements',
        URLClickToCopyAddress: 'URL (zum Kopieren der Adresse anklicken)',
        URLSourceManagement: 'URL Quellenverwaltung',
        PleaseEnterTheURL: 'Bitte geben Sie die URL ein',
        PleaseEnterEnglishOrNumbers: 'Bitte geben Sie Englisch oder Zahlen ein',
        LengthCannotExceed1000Characters: 'Länge darf 1000 Zeichen nicht überschreiten',
        URLFixedAddressIsEmpty: 'URL feste Adresse ist leer',
        cover: 'Titelseite',
        PleaseEnterOrPasteTheVideoScript: 'Bitte geben Sie das Videoskript ein oder fügen Sie es ein',
        VideoScript: 'Video-Skript',
        PleaseUploadAVideoFileOrFillInTheVideoScript: 'Bitte laden Sie eine Videodatei hoch oder füllen Sie das Videoskript aus',
        PleaseEnterTheBrand: 'Bitte geben Sie die Marke ein',
        Portuguese: 'Portugiesisch',
        Register: 'Registrieren',
        InviteUsers: 'Benutzer einladen',
        OrderPayment: 'Bezahlung anfordern',
        SupportsMP4Forma500MB: 'Unterstützt mp4-Format, ≤500MB',
        NoProductIsCurrentlySelectedWhetherToCloseIt: 'Derzeit ist kein Produkt ausgewählt, soll ich es schließen?',
        ExitFullScreen: 'Vollbild beenden',
        FullScreen: 'Vollbild',
        Logout: 'Abmelden',
        OnlySupportsFilesInFormat: 'Unterstützt nur Dateien im Format {xxx}',
        PleaseSetAllPromotionalProducts: 'Bitte alle Werbeartikel einstellen',
        PleaseEnterTheWeight: 'Bitte geben Sie das Gewicht ein',
        FixedPriceCannotBeLowerThanTheLowestPrice: 'Der Festpreis kann nicht niedriger sein als der niedrigste Preis',
        CloseOthers: 'Andere schließen',
        CloseAll: 'Alle schließen',
        ImportSuccessful: 'Import erfolgreich',
        BatchRecharge: 'Batch Aufladung',
        RechargeEmail: 'E-Mail aufladen',
        RechargeCountry: 'Land aufladen',
        RechargeSuccessful: 'Aufladen erfolgreich',
        RechargeInformation: 'Informationen zum Aufladen',
        PleaseDownloadTheRechargeInformationFirst: 'Bitte laden Sie zuerst die Aufladeinformationen herunter',
        TheBatchRechargeDataOfTheAIGOCoinCannotBeEmpty: 'Die Aufladungsdaten der AIGO-Münze dürfen nicht leer sein',
        TheBatchRechargeDataCannotExceed1000Records: 'Die Aufladedaten können nicht mehr als 1000 Datensätze umfassen.',
        ConfirmSubmission: 'Übermittlung bestätigen',
        ExportFailed: 'Export fehlgeschlagen',
        PackingQuantity: 'Packungsmenge',
        Activity: 'Aktivität',
        PleaseSelectWhetherToEnable: 'Bitte wählen Sie aus, ob Sie aktivieren möchten',
        OnlyOneProductCanBeSynchronized: 'Es kann nur ein Produkt synchronisiert werden',
        SKUWithAQuantityOfNumDoesNotExist: 'SKU mit einer Menge von {num} ist nicht vorhanden',
        SKUWithAQuantityOf3DoesNotMeetTheSalesMultiple: 'SKU mit einer Menge von {num} entspricht nicht dem Verkaufsmultiplikator',
        SalesMultipleDoesNotMatchCannotBeListed: 'Verkaufsmultiplikator stimmt nicht überein, kann nicht aufgelistet werden',
        SalesMultipleIsEmptyInSpain: 'Der Verkaufsmultiplikator ist in Spanien leer',
        SynchronizationOfPackageQuantityIsAbnormal: 'Die Synchronisierung der Paketmenge ist anormal',
        CannotContinueToMoveUp: 'Kann nicht weiter aufwärts bewegt werden',
        CannotContinueToMoveDown: 'Kann nicht weiter abwärts bewegt werden',
        SynchronizeThePackageQuantity: 'Synchronisieren Sie die Paketmenge',
        ProductAssociationIDCannotBeEmpty: 'Produktassoziations-ID kann nicht leer sein',
        PackageQuantityCannotBeEmpty: 'Paketmenge kann nicht leer sein',
        PleaseEnterTheCorrectQuantityFormat: 'Bitte geben Sie das richtige Mengenformat ein',
        PleaseEnterSeparatedBy: 'Bitte eingeben (getrennt durch #)',
        PackageQuantity: 'Anzahl der Pakete',
        LengthBetween1And140Characters: 'Länge zwischen 1 und 140 Zeichen',
        PleaseSelectTheVariantParametersFirst: 'Bitte wählen Sie zuerst die Variantenparameter aus',
        TheParameterIsAssociatedWithTheVariantPleaseBeCareful: 'Der Parameter ist mit der Variante verknüpft, bitte achten Sie darauf',
        PleaseSelectDataOfTheSameCategoryPleaseReselect: 'Bitte wählen Sie Daten der gleichen Kategorie, bitte wählen Sie erneut',
        UpTo5VariantsCanBeSelected: 'Es können bis zu 5 Varianten ausgewählt werden',
        NoVariant: 'Keine Variante',
        VerificationFailedPleaseSelectTheVariant: 'Verifizierung fehlgeschlagen, bitte wählen Sie die Variante',
        VerificationFailedPleaseSelectTheProduct: 'Verifizierung fehlgeschlagen, bitte wählen Sie das Produkt aus',
        VerificationFailedTheProductParameterValueIsEmpty: 'Verifizierung fehlgeschlagen, der Produktparameterwert ist leer',
        SetSuccessfully: 'Erfolgreich eingestellt',
        UploadingVideo: 'Video hochladen',
        SearchParameters: 'Parameter für die Suche',
        ImportantParameters: 'Wichtige Parameter',
        Select: 'Wählen Sie',
        ConfirmSettingThisImageAsTheMainImage: 'Bestätigen Sie die Einstellung dieses Bildes als Hauptbild',
        ConfirmDisplayDoNotDisplay: 'Anzeige bestätigen/nicht anzeigen',
        TheCurrentLimitIsToSelectNum1FilesThisTimeYouHaveSelectedNum2FilesATotalOfNum3FilesHaveBeenSelected: 'Die derzeitige Grenze liegt bei der Auswahl von {num1} Dateien, dieses Mal haben Sie {num2} Dateien ausgewählt, insgesamt wurden {num3} Dateien ausgewählt',
        ConfirmToRemoveAny: 'Bestätigen Sie, dass Sie {Any} entfernen möchten?',
        TheTestEnvironmentCannotUploadFiles: 'Die Testumgebung kann keine Dateien hochladen',
        PleaseEnterTheEnglishTitle: 'Bitte geben Sie den englischen Titel ein',
        CancelSettingAsTheMainImage: 'Einstellung als Hauptbild abbrechen?',
        SetAsTheMainImage: 'Als Hauptbild einstellen?',
        CannotSubmitImagesOrVideosThatAlreadyExist: 'Bilder oder Videos, die bereits existieren, können nicht übermittelt werden',
        SynchronizeAnyImagesToOtherLanguages: '{Any} Bilder mit anderen Sprachen synchronisieren?',
        PleaseSelectTheProductToBeSynchronizedFirst: 'Bitte wählen Sie zuerst das zu synchronisierende Produkt aus',
        PleaseSelectTheProductFirst: 'Bitte wählen Sie zuerst das Produkt aus',
        ConfirmWhetherToDeleteTheSelectedImageVideo: 'Bestätigen Sie, ob das ausgewählte Bild/Video gelöscht werden soll?',
        TheMainPictureCannotBeTurnedOff: 'Das Hauptbild kann nicht ausgeschaltet werden',
        CancelDisplay: 'Anzeige abbrechen?',
        ConfirmWhetherToDelete: 'Bestätigen Sie, ob Sie löschen möchten?',
        PleaseUploadTheEnglishImage: 'Bitte laden Sie das englische Bild hoch',
        FailedPleaseEnterTheProductTitleForAllSalesCountries: 'Fehlgeschlagen, bitte geben Sie den Produkttitel für alle Verkaufsländer ein',
        PresaleTime: 'Vorverkaufszeit',
        PresalePrice: 'Vorverkaufspreis',
        PleaseEnter: 'Bitte eingeben',
        PresaleQuantity: 'Vorverkaufsmenge',
        DepositRatio: 'Anzahlungsquote',
        AssociatedAGBSOnthewayOrders: 'Zugehörige AGBS-Unterwegs-Bestellungen',
        EstimatedDeliveryTime: 'Geschätzte Lieferfrist',
        FinalPaymentDeadline: 'Letzter Zahlungstermin',
        SelectTime: 'Zeit wählen',
        PreSalePrice: 'Vorverkaufspreis',
        FixedPrice: 'Festpreis',
        ByProportion: 'Nach Anteil',
        ByFullPayment: 'Bei vollständiger Bezahlung',
        OnlyNumbersGreaterThan0CanBeEnteredUpToTwoDecimalPlaces: 'Es können nur Zahlen größer als 0 eingegeben werden, mit bis zu zwei Dezimalstellen',
        PresaleTimeCannotBeEmpty: 'Die Vorverkaufszeit darf nicht leer sein',
        PresalePriceTypeCannotBeEmpty: 'Art des Vorverkaufspreises darf nicht leer sein',
        PresaleQuantityCannotBeEmpty: 'Die Vorverkaufsmenge darf nicht leer sein',
        OnlyPositiveIntegersCanBeEnteredCannotStartWith0: 'Es können nur positive ganze Zahlen eingegeben werden, die nicht mit 0 beginnen dürfen.',
        DepositRatioTypeCannotBeEmpty: 'Der Typ des Einzahlungsverhältnisses darf nicht leer sein',
        DepositRatioCannotBeEmpty: 'Einzahlungsquote darf nicht leer sein',
        OnlyIntegersBetween0And100CanBeEnteredExcluding0And100: 'Es können nur ganze Zahlen zwischen 0 und 100 eingegeben werden, außer 0 und 100',
        EstimatedDeliveryTimeCannotBeEmpty: 'Geschätzte Lieferzeit darf nicht leer sein',
        FinalPaymentDeadlineCannotBeEmpty: 'Die Frist für die Abschlusszahlung darf nicht leer sein ',
        FinalPaymentShouldBeDoneBefore5HoursBeforeTheEndOfPresalePromotion: 'Die Abschlusszahlung sollte bis 5 Stunden vor dem Ende der Vorverkaufsaktion erfolgen.',
        TheRemainingAmount: 'Der verbleibende Betrag',
        PresaleStatus: 'Status des Vorverkaufs',
        PresaleStartTime: 'Startzeit des Vorverkaufs',
        PresaleEndTime: 'Endzeit des Vorverkaufs',
        OnPresale: 'Im Vorverkauf',
        DirectVisit: 'Direkter Besuch',
        BackendManagementSystem: 'Backend-Verwaltungssystem',
        Login: 'Anmeldung',
        TipsFillInTheUsernameAndPasswordCasually: 'Tipps: Geben Sie den Benutzernamen und das Passwort zufällig ein.',
        PleaseEnterUserName: 'Bitte Benutzernamen eingeben',
        PleaseEnterPassword: 'Bitte Passwort eingeben',
        LoginSuccessful: 'Anmeldung erfolgreich',
        PleaseEnterYourAccountNumberAndPassword: 'Bitte geben Sie Ihre Kontonummer und Ihr Passwort ein',
        TheSameProductCanOnlyBeReturnedOrExchanged: 'Das gleiche Produkt kann nur zurückgegeben oder umgetauscht werden',
        B2CBrandWebsite: 'B2C-Marken-Website',
        CommentManagement: 'Verwaltung der Kommentare',
        AIGOCoins: 'AIGO-Münzen',
        PleaseEnterThePackageQuantity: 'Bitte geben Sie die Paketmenge ein',
        PresalePriceCannotBeEmpty: 'Vorverkaufspreis kann nicht leer sein',
        Product: 'Produkt',
        HomePageColumn: 'Spalte auf der Startseite',
        ColumnDescription: 'Beschreibung der Säule',
        FlashSaleArea: 'Flash-Sale-Bereich',

        //1.2.8之后
        SoldOutOrNot: 'Ausverkauft oder nicht',
        VirtualQuantity: 'Virtuelle Menge',
        VirtualQuantityPackageQuantity: 'Virtuelle Menge/Verpackungsmenge',
        CombinationIcon: 'Kombinationssymbol',
        UploadFailedFileSizeShouldBe3MB: 'Upload fehlgeschlagen, Dateigröße sollte <3MB sein',
        PromotionQuantityPackageQuantity: 'Aktionsmenge/Verpackungsmenge',
        RemainingTotal: 'Restmenge/Gesamtmenge',
        RecommendedSize100: 'Empfohlene Größe: 100*100, ≤ 20 KB',
        TotalRemainingTotal: 'Gesamt - Restmenge/Gesamtmenge',
        Verification: 'Überprüfung',
        VirtualSalesVolume: 'Virtuelle Verkaufsmenge',
        VirtualSalesVolumePackageQuantity: 'Virtueller Umsatz/Verpackungsmenge',
        DeleteOrNot: 'Löschen oder nicht?',
        EnabledOrNot: 'Aktiviert oder nicht',
        Cancel: 'Abbrechen',
        Code: 'Code',
        Confirm: 'Bestätigen',
        RebateDimension: 'Rabatt-Umfang',
        InProgress: 'In Bearbeitung',
        ActivityStatus: 'Status der Aktivität',
        RebateRatioManagement: 'Verwaltung der Rabattquote',
        StartTime: 'Startzeitpunkt',
        EndTime: 'Endzeitpunkt',
        RebateAmount: 'Rabattbetrag',
        NewUser: 'Neuer Nutzer',
        RebateObjectNumber: 'Nummer des Rabattobjekts',
        NumberOfCompletedOrders: 'Anzahl der abgeschlossenen Bestellungen',
        RebateObject: 'Rabatt-Objekt',
        Operation: 'Betrieb',
        RecentOperator: 'Letzter Betreiber',
        Country: 'Land',
        Prompt: 'Aufforderung',
        RebateProducts: 'Rabattierte Produkte',
        NewUserPromotion: 'Aktion für neue Benutzer',
        RegularUserPromotion: 'Aktion für Stammkunden',
        Upcoming: 'Bevorstehend',
        RebateRatio: 'Rabattquote',
        RebateOrNot: 'Rabattieren oder nicht',
        Rebate: 'Rabatt',
        PleaseSelectARebateDimension: 'Bitte wählen Sie einen Rabattumfang',
        PleaseSelectARebateObject: 'Bitte wählen Sie ein Rabattobjekt',
        PleaseSelectACountry: 'Bitte wählen Sie ein Land',
        PleaseSelectARebateRatio: 'Bitte wählen Sie ein Falzverhältnis',
        RecentOperationTime: 'Letzte Betriebszeit',
        PromotionObjectType: 'Objekttyp der Werbeaktion',
        ProductPromotionType: 'Produkt-Aktionsart',
        UserType: 'Benutzer-Typ',
        ApplicableUserType: 'Anwendbarer Benutzertyp',
        RegularUser: 'Regelmäßiger Benutzer',
        UseAigoCoinsOrNot: 'Aigo-Münzen verwenden oder nicht',
        EndDateOfMembership: 'Datum der Beendigung der Mitgliedschaft',
        StartDateOfMembership: 'Datum des Beginns der Mitgliedschaft',
        memberOrNot: 'Mitglied oder nicht',
        No: 'Nein',
        Yes: 'Ja',
        LowInventory: 'Niedriger Bestand',
        RechargeEmailbox: 'E-Mailbox aufladen',
        SubmittedSuccessfully: 'Erfolgreich abgeschickt',
        Importing: 'importieren',
        InformationOfTheRechargeFailed: 'Informationen zur Aufladung fehlgeschlagen',
        RechargeFailed: 'Aufladen fehlgeschlagen',
        RechargeInBatch: 'Aufladen im Batch',
        RechargeSuccessfully: 'Aufladen erfolgreich',
        RechargeAigoCoins: 'Aigo-Münzen aufladen',
        RequiredField: 'Erforderliches Feld',
        PDFFormat: 'PDF-Format',
        FileSize3MB: 'Dateigröße <3MB',
        UploadFailedPDFFormatRequired: 'Upload fehlgeschlagen, PDF-Format erforderlich',
        AuditFailedPleaseUploadInvoiceAttachment: 'Prüfung fehlgeschlagen, bitte Rechnungsanhang hochladen',
        PleaseUploadInvoiceAttachment: 'Bitte Rechnungsanhang hochladen',
        InvoiceAttachment: 'Rechnungsbeilage',
        Upload: 'Upload',
        UploadFailed: 'Upload fehlgeschlagen',

        HaveAnAccount: 'Sie haben noch kein Konto? Klicken Sie hier, um sich zu registrieren',
        SoldOut: 'Ausverkauft',
        HaveAnInvitationCode: 'Optional, leer lassen, wenn Sie keinen Einladungscode haben.',
        RestockedItems: 'Wiederaufgefüllte Artikel',

        NotMeetPleaseEnterAgain: 'Der Nickname entspricht nicht den Anforderungen, bitte geben Sie ihn erneut ein.',
        ThisCouponIsOnlyAvailableForNewUsers: 'Dieser Coupon ist nur für neue Benutzer verfügbar.',
        ThiscouponIsOnlyAvailableForExistingUsers: 'Dieser Gutschein ist nur für bestehende Kunden gültig.',
        OnlyReturnsOrExchangesOfTheSameProductAreAllowed: 'Nur Rücksendungen oder Umtausch des gleichen Produkts sind erlaubt.',
        With365DaysRemaining: '（Expires on 9/ 25/2024） with 365 days remaining',

        TheNicknameDoesNotMeet: 'The nickname does not meet the requirements, please enter a new one.',

        GoToView: 'Zur Ansicht gehen',
        DoNotRegisterRepeatedly: 'Nicht wiederholt anmelden',
        ValidityPeriod: 'Gültigkeitsdauer',
        RegisterSuccessfully: 'Anmeldung erfolgreich',
        MemberRegistration: 'Mitgliederanmeldung',
        RegistrationFailed: 'Anmeldung fehlgeschlagen',
        OneYear: 'Ein Jahr',
        AddToShoppingCart: 'Zum Einkaufswagen hinzufügen',
        AddANewAddress: 'Eine neue Adresse hinzufügen',
        ZIPCode: 'Postleitzahl*',

        EditYourAddress: 'Ihre Adresse bearbeiten',
        Remove: 'Entfernen',
        SetAsDefault: 'Als Standard festlegen',
        FailedChangeDefaultAddress: 'Die Standardadresse konnte nicht geändert werden, bitte versuchen Sie später noch einmal',
        Fullname: 'Vollständiger Name (Vor- und Nachname)*',
        PhoneNumber: 'Rufnummer',
        DefaultAddressHasBeenChanged: 'Die Standardadresse wurde geändert',
        ViewComments: 'Kommentare anzeigen',
        ClickToCheckLogisticsInfo: 'Klicken Sie hier, um die Logistikinformationen zu überprüfen.',
        RestockingOfOutOfStockItems: 'Wiederauffüllung der nicht vorrätigen Artikel',
        ThisWeekRestockedItems: 'Die wiederaufgefüllten Artikel dieser Woche',
        ProductListInTheShoppingCart: 'Produktliste im Einkaufswagen',
        WarmReminderVideoResources: 'freundliche Erinnerung: Video-Ressourcen sind nur zum einmaligen Download verfügbar.',
        PleaseLeaveYourMessage: 'Bitte hinterlassen Sie Ihre Nachricht',
        InvoiceManagement: 'Verwaltung von Rechnungen',

        ReadmeFile: 'Readme-Datei',
        HelpCenter: 'Hilfe-Center',
        FileUpload: 'Datei hochladen',
        Globalization: 'Globalisierung',
        SalespersonProfile: 'Vertriebsmitarbeiter-Profil',
        BatchSyncPage: 'Batch-Sync-Seite',
        CountryConfigurationPage: 'Länderkonfigurationsseite',
        UserConsultation: 'Benutzerabfrage',
        PreSaleItems: 'Vorverkaufsartikel',
        EventManagement: 'Verwaltung von Ereignissen',
        BlankPage: 'Leere Seite',
        ThereAreCurrentlyNoProductPromotions: 'Derzeit gibt es keine Produktangebote',
        EditInventoryBottomLine: 'Inventargrundzeile bearbeiten',
        SetAFixedPrice: 'Einen Festpreis festlegen',
        EnterUpTo500Entries: 'Bis zu 500 Einträge eingeben',
        CantRelateToYourself: 'Kann sich nicht auf sich selbst beziehen',
        PleaseSelectTheApplicableUserType: 'Bitte wählen Sie den entsprechenden Benutzertyp',
        TheActivityDescriptionLimitIs10000Words: 'Das Limit für die Aktivitätsbeschreibung beträgt 10 000 Wörter',
        EnabledStatus: 'Aktivierter Status',
        Enable: 'Aktivieren',
        OperatingTime: 'Betriebszeit',
        NewUserPromotionEnabled: 'Werbung für neue Benutzer aktiviert',
        NewUserPromotionDisabled: 'Werbung für neue Benutzer deaktiviert',
        OldUserPromotionEnabled: 'Werbung für alte Benutzer aktiviert',
        OldUserPromotionDisabled: 'Werbung für alte Benutzer deaktiviert',
        ThePromotionalPriceCannotBeLowerThanTheLowestPrice: 'Der Aktionspreis kann nicht niedriger sein als der niedrigste Preis',
        AutomaticallyCalculateAfterEnteringPrice: 'Automatisch berechnen nach Eingabe des Preises',
        ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue: 'Der Aktionspreis für neue Benutzer ist höher als der Aktionspreis für alte Benutzer. Möchten Sie fortfahren?',
        PleaseSelectPromotionCountry: 'Bitte wählen Sie das Aktionsland',
        PromotionDiscount: 'Aktionsrabatt',
        PleaseEnterThePromotionalQuantity: 'Bitte geben Sie die Aktionsmenge ein',
        PleaseEnterVirtualQuantity: 'Bitte geben Sie die virtuelle Menge ein',
        NumberOfUserPurchases: 'Anzahl der Einkäufe eines Benutzers',
        PleaseEnterThePurchaseLimitForTheSameUser: 'Bitte geben Sie das Kauflimit für denselben Benutzer ein',
        PleaseEnterThePurchaseLimitForASingleOrder: 'Bitte geben Sie das Kauflimit für eine einzelne Bestellung ein',
        QuantityGreaterThan0: 'Menge größer als 0',
        GrossProfitMarginCalculationResults: 'Ergebnisse der Bruttogewinnspannenberechnung',
        FixedPriceGrossMargin: 'Festpreis-Bruttomarge',
        SellingPriceGrossProfitMargin: 'Verkaufspreis-Bruttogewinnspanne',
        PromotionalPriceGrossProfitMargin: 'Aktionspreis-Bruttogewinnspanne',
        PromotionStartTime: 'Startzeit der Aktion',
        PromotionEndTime: 'Endzeit der Aktion',
        PromotionType: 'Art der Werbeaktion',
        PleaseSetTheSKUToPromotionalProducts: 'Bitte setzen Sie die SKU auf ${xxx} Werbeartikel',
        DistributionOffers: 'Verteilung der Angebote',
        AfterEnteringTheQuantitytWillBeAutomaticallyCalculatedBasedOnTheQuantityOfTheProduct: 'Nach der Eingabe der Menge{Any} wird sie automatisch auf der Grundlage der Menge des Produkts berechnet.',
        TheNumberOfUserPurchasesMustBeGreaterThan0: 'Die Anzahl der Benutzerkäufe muss größer als 0 sein.',
        PleaseSelectCountry: 'Bitte wählen Sie ${typeName} Land',

        AfterEnteringThePromotionalQuantityItWillBeAutomaticallyCalculatedBasedOnTheQuantityOfGoods: 'Nach Eingabe der Aktionsmenge wird diese automatisch auf der Grundlage der Warenmenge berechnet.',

        PleaseCancelTheAssociationInRebateProductPoolFirst: 'Bitte heben Sie zuerst die Zuordnung im [Rabatt-Produktpool] auf',
        EditBottomLineInventory: 'Bodenbestand bearbeiten',
        PleaseConfigureFixedPrice: 'Bitte Festpreis konfigurieren',
        BalancePayment: 'Restzahlung',
        Deposit: 'Pfand',

        EndofValidity: 'Bitte geben Sie das Verfallsdatum ein',
        Year: 'Jahr',
        Month: 'Monat',
        Day: 'Tag',
        UnitOfValidity: 'Einheit der Gültigkeit',
        AIGOCoinDetails: 'AIGO-Münze Details',
        AIGOCoinsAreCloseToExpiring: 'AIGO-Münzen stehen kurz vor dem Verfall (Tag)',
        CountryOfShipmentAddress: 'Land der Versandadresse',

        // V1.3.3 start
        EstimatedTimeOfDelivery: 'Geschätzter Zeitpunkt der Lieferung',
        DataCorrespondingToTheSource: 'Daten, die der Quelle entsprechen',
        AccountStatus: 'Status des Kontos',
        OrderID: 'Auftrags-ID',
        RecipientName: 'Name des Empfängers',
        RecipientLastName: 'Nachname des Empfängers',
        MailsOfUser: 'Mails des Benutzers',
        StateProvince: 'Bundesland/Provinz',
        CityTown: 'Stadt/Ort',
        County: 'Landkreis',
        ReceivingCompany: 'Empfangender Betrieb',
        BatchInput: 'Batch-Eingabe',
        InputManually: 'Manuelle Eingabe',
        ProductCommentsInputTemplate: 'Eingabevorlage für Produktkommentare',
        UserName: 'Name des Benutzers',
        Comments: 'Kommentare',
        StarRatingOfTheComment: 'Sternebewertung des Kommentars',
        CommentTime: 'Zeit des Kommentars',
        UploadAttachment: 'Anhang hochladen',
        NumberOfAttachments: 'Anzahl der Anhänge',
        FileName: 'Name der Datei',
        Appendix: 'Anhang',
        BatchInputOfProductComments: 'Batch-Input von Produktkommentaren',
        SoldOut1: 'Ausverkauft',
        PreSalePromotionID: 'Vorverkaufsaktion ID',
        PromotionAbnormal: 'Aktion abnormal',
        Updater: 'Aktualisierer',
        AddPreSalePromotion: 'Vorverkaufsaktion hinzufügen',
        EditPreSalePromotion: 'Vorverkaufsaktion bearbeiten',
        Barcode: 'Strichcode',
        PreSaleCountries: 'Vorverkaufsländer',
        PreSalePrice1: 'Vorverkaufspreis',
        PreSaleDeposit: 'Kaution für den Vorverkauf',
        PleaseEnterTheDocumentCode: 'Bitte geben Sie den Dokumentencode ein',
        BalancePaymentDeadline: 'Frist für die Restzahlung',
        FixedShippingFee: 'Feste Versandgebühr',
        PreSaleShippingFee: 'Vorverkaufsgebühr für den Versand',
        FreeShippingSubjectToConditions: 'Kostenloser Versand unter bestimmten Bedingungen',
        OrderPrice: 'Preis der Bestellung',
        PleaseEnterPriceConditions: 'Bitte Preiskonditionen eingeben',
        TheEndTimeNeedsToBeLaterThanTheStartTime: 'Die Endzeit muss später sein als die Startzeit',
        ThereAreAlreadyPartiallyOverlappingPromotionInThisCountryPleaseModify: 'Es gibt bereits teilweise überlappende Aktionen in diesem Land, bitte ändern Sie diese',
        ThePreSaleQuantityCanOnlyBeAPositiveIntegerGreaterThan0: 'Die Vorverkaufsmenge kann nur eine positive ganze Zahl größer als 0 sein',
        TheEstimatedDeliveryTimeNeedsToBeLaterThanTheEventEndTime: 'Die geschätzte Lieferzeit muss nach der Endzeit der Aktion liegen',
        TheBalancePaymentDeadlineNeedsToBeEarlierThanTheEstimatedDeliveryTime: 'Die Frist für die Restzahlung muss vor der voraussichtlichen Lieferzeit liegen',
        PromotionID: 'Aktions-ID',
        DistributionPromotionID: 'ID der Vertriebsaktion',
        CouponID: 'Coupon-ID',
        PromotionManagementID: 'ID für die Aktionsverwaltung',
        PortfolioManagementID: 'ID der Portfolio-Verwaltung',
        ExportForFiltering: 'Export für Filterung',
        ListDisplay: 'Anzeige der Liste',
        InventoryItems: 'Inventar Artikel',
        InStock: 'Auf Lager',
        OutOfStock: 'Nicht auf Lager',
        FieldName: 'Feldname',
        PleaseSelectInventory: 'Bitte Lagerbestand auswählen',
        PreSaleEvent: 'Vorverkaufsveranstaltung',
        PerPage: 'Jede Seite zeigt',

        // V1.3.3 end

        // V1.3.4 start

        PleaseProvideTheNameOfTheLandingPageActivity: 'Bitte geben Sie den Namen der Landingpage-Aktivität ein.',
        PleaseIndicateTheNumberOfActivities: 'Bitte geben Sie die Anzahl der Aktivitäten ein.',
        TheQuantityOfActivityShouldExceedTheAmountGivenAway: 'Die Menge der Aktivität sollte höher sein als die Menge, die verschenkt wird.',
        PleaseChooseATimeForTheEvent: 'Bitte wählen Sie eine Zeit für die Veranstaltung.',
        ActivityNameExceedsLength: 'Aktivitätsname überschreitet die maximale Länge.',
        ThisItemIsRestrictedToAMaximumOf10PerOrder: 'Dieser Artikel ist auf 10 pro Bestellung begrenzt.',
        PleaseChooseTheGiveawayAgain: 'Bitte wählen Sie das Giveaway erneut aus.',
        AnalysisOfGiveawayActivities: 'Analyse von Werbegeschenk-Aktivitäten',
        StatisticsOfGiveawayActivities: 'Statistiken von Giveaway-Aktionen',
        PleaseEnterTheNameOfTheEvent: 'Bitte tragen Sie den Namen der Veranstaltung ein.',
        PleaseSelectTheActivityType: 'Bitte wählen Sie die Art der Aktivität aus.',
        PleaseCompleteTheActivityRating: 'Bitte füllen Sie die Aktivitätsbewertung aus.',
        PleaseSetUpThePreSaleCampaignBeforeSettingUpTheCountryWhereThePreSaleItemsWillBeDisplayed: 'Bitte konfigurieren Sie die {Any} Pre-Sale-Kampagne, bevor Sie das Land konfigurieren, in dem die Pre-Sale-Artikel verfügbar sein werden.',
        TheOrderAmountAndNumberOfFreebiesCannotBeLowerThanThePreviousTier: 'Der Bestellbetrag und die Anzahl der Gratisartikel dürfen nicht niedriger sein als die vorherige Stufe.',
        SelectImage: 'Wählen Sie ein Bild aus',
        GiftInformation: 'Geschenkinformationen',
        Gifts: 'Geschenke',
        GiftsOfEndOfEvent: 'Geschenke zum Abschluss der Aktion',
        CheckOutTheGifts: 'Sehen Sie sich die Geschenke an',
        GiveawayEvent: 'Geschenkaktion',
        SelectAGift: 'Wählen Sie ein Geschenk',
        YouHaveNotSelectedAGift: 'Sie haben kein Geschenk ausgewählt',
        NoNeedForGifts: 'Kein Bedarf an Geschenken',
        SomeGiftsAreOutOfStockPleaseReselectTheGifts: 'Einige Geschenke sind nicht mehr vorrätig, bitte wählen Sie die Geschenke erneut aus',
        ReplaceAGift: 'Ersetzen Sie ein Geschenk',
        ThereAreCurrently3FreeGiftsAvailable: 'Es sind derzeit 3 Geschenke verfügbar',
        Currently3FreeGiftsAreAvailableAnd4MoreGiftsAreAvailableForBuyingOther99: 'Derzeit sind 3 Geschenke verfügbar, und 4 weitere Geschenke sind für den Kauf von weiteren 99 € erhältlich',
        GiftList: 'Geschenkeliste',
        GiftID: 'Geschenk-ID',
        CurrentInventory: 'Aktueller Bestand',
        NotEnabled: 'Nicht aktiviert',
        GoodsForFree: 'Kostenlose Waren',
        ConfirmToDeleteThisGiftInformation: 'Bestätigen Sie, dass Sie diese Geschenkinformationen löschen möchten?',
        GiftsSortingManagement: 'Verwaltung der Geschenksortierung',
        GiftsTopicManagement: 'Verwaltung der Geschenkthemen',
        GiveawayEventID: 'ID der Geschenkaktion',
        GiveawayEventName: 'Name der Geschenkaktion',
        ProductCategory: 'Produkt-Kategorie',
        ActivityMode: 'Modus der Aktivität',
        TotalNumberOfActivities: 'Gesamtzahl der Aktivitäten',
        GiftsGivenQuantity: 'Anzahl der verschenkten Geschenke ',
        ThemeConfiguration: 'Konfiguration des Themas',
        GiftSorting: 'Geschenk-Sortierung',
        GlobalConfiguration: 'Globale Konfiguration',
        ActiveCountry: 'Aktives Land',
        PromotionalPpicture: 'Werbefoto',
        RecommendedSizeX: 'Empfohlene Größe: {XXX*XXX}',
        FileSize1MB: 'Dateigröße: ≤1MB',
        ActivitySorting: 'Aktivität sortieren',
        DragToSort: 'Ziehen zum Sortieren',
        ActivityCode: 'Code der Aktivität',
        GiftsForOrderAmount: 'Geschenke für Bestellmenge',
        ActivityRating: 'Bewertung der Aktivität',
        GiftsQuantity: 'Anzahl der Geschenke',
        EventGiftsSorting: 'Sortierung der Geschenke für Aktionen',
        DefaultSelectionOfUsers: 'Standardauswahl der Benutzer',
        LandingPageDisplay: 'Anzeige der Landing-Page',
        LandingPageActivityName: 'Name der Aktivität auf der Landing-Page',
        ConfirmDeleteThisGiftEvent: 'Bestätigen Sie das Löschen dieser Geschenkaktion?',
        IsThereAGiftEvent: 'Gibt es eine Geschenkaktion?',
        OrderQuantity: 'Menge der Bestellung',
        NumberOfGiftsIssued: 'Anzahl der ausgestellten Geschenke',
        CustomerPrice: 'Kundenpreis',
        TheVirtualEventQuantityFunctionCanOnlyBeSetIfThePreSaleActivityTimeIsAtLeastMoreThan2DaysAndTheNumberOfEventsIsGreaterThan50: 'Die Funktion für die Anzahl der virtuellen Ationen kann nur eingestellt werden, wenn die Vorverkaufszeit mindestens 2 Tage beträgt und die Anzahl der Aktionen größer als 50 ist.',
        PreSaleSwitch: 'Vorverkaufsschalter',
        PreSaleActivity: 'Vorverkaufsaktivitäten',
        GiftManagement: "Geschenkmanagement",

        // V1.3.4end

        //V1.3.5 start
        OrderSource: "Źródło zamówienia",
        NumberOfNewUserRegistrations: "Liczba rejestracji nowych użytkowników",
        RegisteredUsersWhoVsitTheWebsite: "Zarejestrowani użytkownicy odwiedzający witrynę",
        SoldDistributionQuantity: "Ilość sprzedana w dystrybucji",
        DistributionQuantityLock: "Blokada ilości w dystrybucji",
        InsufficientDstributionQuantityRemaining: "Pozostała niewystarczająca ilość w dystrybucji",
        Previous: "Poprzedni",
        Next: "Następny",

        //V1.3.5 end

        //V1.3.6 start 
        RecipeManagement: "Rezeptur-Verwaltung",
        RecipeDisplayImage: "Rezeptbild",
        NewRecipeDisplayImage: "Bearbeiten/Erstellen eines neuen Rezeptbildes",
        CoverPicture: "Titelbild",
        PicturesVideos: "Es können bis zu 10 Bilder + Videos hochgeladen werden, jedes Bild sollte ≤5MB sein, und das Video im .mp4-Format wird unterstützt",
        AddPicturesVideos: "Bilder/Videos hinzufügen",
        CommenOnPicturesVideos: "Kommentar zu Bildern/Videos",
        FileSize: "Dateigröße",
        BarcodeAssociated: "Barcode zugeordnet",
        FirstUploadTime: "Zeit des ersten Hochladens",
        EditTime: "Zeit der Bearbeitung",
        TagType: "Tag-Typ",
        FileName1: "Name der Datei",
        NameLengthISTooLong: "Der Name ist zu lang",
        ProductDoesNoExist: "Produkt existiert nicht",
        PleaseUploadImages: "Bitte laden Sie jpg/png/jpeg Bilder unter 5MB hoch",
        PleaseUploadTheVideoMP4: "Bitte laden Sie das Video im mp4-Format hoch",
        PictureOrVideo: "Bild oder Video darf nicht leer sein",
        allowed: "Alle zulassen",
        cookie1: "Verwaltung von Cookies",
        collectsordinances: "Aigostar verwendet Cookies, um das Surferlebnis der Besucher zu verbessern und für Marketingzwecke. Hier können Sie Ihre Cookie-Einstellungen einsehen und verwalten. Um mehr darüber zu erfahren, wie wir Cookies verwenden, lesen Sie bitte unsere Datenschutzrichtlinie.",
        RequiredCookies: "Erforderliche Cookies",
        cookie2: "Diese Cookies sind für Kernfunktionen wie das Einloggen, die Verwendung des Warenkorbs und Zahlungen erforderlich. Notwendige Cookies können nicht abgeschaltet werden. Aber keine Sorge, diese Cookies speichern keine Ihrer Daten.",
        AnalyticsCookies: "Marketing- und Analyse-Cookies",
        cookie3: "Diese Cookies sammeln Informationen, wie z. B. die Anzahl der Besuche auf der Website oder welche Seiten beliebt sind, um uns zu helfen, unser Kundenerlebnis zu verbessern. Sie können diese Cookies deaktivieren, aber dann können wir nicht die Informationen sammeln, die wir brauchen, um Ihre Erfahrung auf unserer Website zu verbessern.",
        StoragePreferences: "Präferenzen speichern",
        BuyItNow: "Jetzt kaufen",
        PictureVideoSize: "Bild- oder Videogröße oder -format ist nicht korrekt",
        RecipeConfiguration: "Rezeptur-Konfiguration",

        //V1.3.6 end

        //V1.3.7 start 
        ConfirmSynchronizationSubBarcodes: "Bestätigen Sie die Synchronisierung von Unterbarcodes?",
        SynchronizationEbpEanSUB: "Aufgrund der Notwendigkeit einer Schnittstelle mit EBP wird erwartet, dass die Batch-Synchronisierung innerhalb von 3 Minuten abgeschlossen ist. Nach Bestätigung wird die Synchronisierung asynchron fortgesetzt, und die Unter-Barcodes werden sofort nach Erhalt angezeigt. Bitte haben Sie Geduld.\n",
        Keywords: "Schlüsselwörter",
        SystemAutoUpdate: "System-Auto-Update",
        IPAddress: "IP-Adresse",
        SearchKeywords: "Suchbegriffe",
        LoginEmail: "Anmelde-E-Mail",
        SearchTime: "Suchzeit",
        NumbeOfTimes: "Anzahl der Male",
        MyBrowsing: "Mein Browsen",
        LoggingOuOfYouAccount: "Das Abmelden von Ihrem Konto wird als Ihre freiwillige Aufgabe betrachtet und Sie werden dieses Konto nicht mehr weiter verwenden können.",
        RequestLogout: "Abmeldung beantragen",
        ProductsYet: "Sie haben noch keine Produkte durchsucht",
        clearall: "Verlauf löschen",
        CancellTip1: "Einkaufswagenlisten, Bestellkaufaufzeichnungen und Favoritenaufzeichnungen werden dauerhaft gelöscht und können nicht wiederhergestellt werden.",
        CancellTip2: "Alle Kontoinformationen, Serviceaufzeichnungen, Verbrauchsdaten usw. auf der Plattform werden dauerhaft gelöscht und können nicht wiederhergestellt werden.",
        CancellTip3: "Sie können sich nicht mehr bei diesem AigoSmart-Konto anmelden oder es nutzen.",
        CancellTip4: "Persönliche Informationen und historische Nachrichten (einschließlich Avatar, Spitzname, meine Aiguo-Währung, Plattformbenachrichtigungen, meine Kommentare usw.), die mit Ihrem AigoSmart-Konto verbunden sind, können nicht wiederhergestellt werden.",
        CancellTip5: "Sie müssen vor dem Abmelden die Kontofonds (Punkte, Mitgliedschaftsstufen, Qualifikationen, Bestellungen, Gutscheine, Requisiten und andere wertvolle Dienstleistungen) ordnungsgemäß verwalten, da die Kontolöschung als freiwilliger Verzicht angesehen wird und nicht rückgängig gemacht werden kann. Sie verstehen und akzeptieren, dass AigoSmart Ihnen nicht dabei helfen kann, die oben genannten Dienste wiederherzustellen.",
        SynchronizeSubBarcodes: "Unter-Barcodes synchronisieren",
        PleaseEnterKeywords: "Bitte geben Sie Stichwörter ein",

        //V1.3.7 end

        //V1.3.8 start 
        HourlyStatistics: "Stündliche Statistik",
        NumbeOfUsers: "Anzahl der Benutzer, die Bestellungen aufgegeben haben",
        NumberOfCanceled: "Anzahl der stornierten Bestellungen",
        RefundAmount: "Rückerstattungsbetrag",
        UsersWhoHavePaid: "Benutzer, die bezahlt haben (Bestellungen, die versendet werden können, versendet, abgeschlossen)",
        TotalNumber: "Gesamtzahl der vom Plattform stornierten Bestellungen",
        AmountOfCanceled: "Betrag der stornierten Bestellungen, die bezahlt, aber nicht versendet wurden, Betrag der zurückgesendeten Bestellungen, die bezahlt wurden (unterwegs, geliefert)",
        RedemptionCode: "ID/Coupon-Name/Einlösungscode",
        DeleteAll: "Löschen",
        AccountIsUnderReview: "Das aktuelle Konto befindet sich in der Deaktivierungsprüfung. Wenn Sie sich weiterhin anmelden, wird der Deaktivierungsprozess abgebrochen. Möchten Sie es weiterhin verwenden?",

        //V1.3.8 end



        //V1.3.9 start 

        TimeCannotBeEmpty: "Die Zeit darf nicht leer sein",
        ProductImporTemplate: "Produktimportvorlage",
        NormalOrdersExceed: "Normale Bestellungen überschreiten:",
        OrderSautomaticallyClosed: "Unbezahlte Bestellungen werden automatisch geschlossen",
        OrdeIsShipped: "Nach dem Versand der Bestellung:",
        AutomaticallyComplete: "Bestellung automatisch abschließen",
        Minutes: "Minuten",
        Days: "Tage",
        OrderSettings: "Bestell-Einstellungen",
        ClearCache: "Cache leeren",
        PleaseEnterNavigationContent: "Bitte Navigationsinhalt eingeben",
        WebsiteNavigationConfiguration: "Website-Navigationskonfiguration",

        //V1.3.9 end

        //V1.4.0 start

        PopupDelayTime: "Popup-Verzögerungszeit",
        SubscriptionPopup: "Abonnement-Popup",
        MeansNoPopupDelay: "Bitte geben Sie die Verzögerungszeit des Popup-Fensters ein",

        //V1.4.0 end

        //V1.5.0 start
        UGCZone: "UGC-Bereich",

        //V1.5.0 end

        //V1.6.0 start
        uploaFilesInJpg: "Laden Sie nur Dateien im jpg/png-Format mit weniger als 2 MB hoch",
        SynchronizeEbpPrice: "EBP-Preis synchronisieren",
        ConfirmToSynchronizeEbp: "EBP-Preis synchronisieren",
        AsynchronousEbp: "Nach der Bestätigung erfolgt die Synchronisierung asynchron. Der Preis wird sofort nach Erhalt angezeigt. Bitte haben Sie etwas Geduld.",
        CopywritingDisplay: "Anzeige der Werbetexte",
        HomepageCopywriting: "Homepage",
        EditHomepageCopywriting: "Kostenlose Geschenke bei kleinen Geschenken und besonderen Anlässen können nicht zusammen abgeholt werden",
        SuperimposedEvents: "Bitte wählen Sie Produkte mit einer SKU aus",
        GiftsEvents: "(Die Geschenke für diese Veranstaltung können nicht zusammen mit anderen Veranstaltungen abgeholt werden)",
        SmallGiftEvent: "Veranstaltung für kleine Geschenke",
        SpecialProductEvent: "Veranstaltung für besondere Produkte",
        SpecialGiftEvent: "Kostenlose Geschenke von Veranstaltungen für kleine Geschenke und besondere Veranstaltungen können nicht zusammen abgeholt werden",
        ProductsWithSKU: "Bitte wählen Sie Produkte mit einer SKU aus",
        LimitWereSelected: "Das aktuelle Limit liegt bei {limitNum} Dateien. Diesmal wurden {selectNum} Dateien ausgewählt.",
        FileUploadFailed: "Datei-Upload fehlgeschlagen!",
        CompleteMultiLanguage: "Bitte schließen Sie die mehrsprachige Konfiguration ab",
        FirstSynchronizeSKU: "Bitte synchronisieren Sie zuerst das SKU für dieses Produkt.",

        //V1.6.0 end




        //V1.7.0 start
        MakeCouponPublic: "Ob der Gutschein veröffentlicht werden soll",
        RedemptionCode1: "Einlösungscode",
        NowOnlyNeed: "Jetzt nur noch 10 € nötig",
        WebLogReporting: "Weblog-Berichte",
        PageAddress: "Seitenadresse",
        ErrorLogs: "Fehlerprotokolle",
        CustomParameter: "Benutzerdefiniertes Parameter",
        ParameterID: "Parameter-ID",
        // ParameterName: "Parametername",
        SearchParameter: "Suchparameter",
        EnterParameterName: "Bitte geben Sie den Parameternamen ein",
        ParameterType: "Parametertyp",
        AssociatedProductCount: "Verknüpfte Produktanzahl",
        MandatoryPortugueseContent: "Erforderlicher Inhalt auf Portugiesisch",
        MandatoryEnglishContent: "Erforderlicher Inhalt auf Englisch",
        MandatoryDutchContent: "Erforderlicher Inhalt auf Niederländisch",
        MandatoryPolishContent: "Erforderlicher Inhalt auf Polnisch",
        MandatoryFrenchContent: "Erforderlicher Inhalt auf Französisch",
        MandatoryGermanContent: "Erforderlicher Inhalt auf Deutsch",
        MandatoryItalianContent: "Erforderlicher Inhalt auf Italienisch",
        MandatorySpanishContent: "Erforderlicher Inhalt auf Spanisch",
        MandatoryChineseContent: "Erforderlicher Inhalt auf Chinesisch",
        ImportTemplate: "Vorlage für den Produktimport mit benutzerdefinierten Parametern",
        ConfirmImportFile: "Importdatei bestätigen, fortfahren?",
        SelectDataRemove: "Zu entfernende Daten auswählen",
        ImportTemplateDownload: "Importvorlage herunterladen",
        AssociatedProducts: "Zugehörige Produkte",

        //V1.7.0 end


    }
};

export default de;